import * as React from 'react'
import * as projectSelectors from '../../project/projectSelector'
import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { Project } from '../../../models/Project'

interface Props {
  languages: string[] | undefined
  selectedProject: Project | undefined
}

const RowHeader = (props: Props) => {
  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  {
    /* Hack to sort the columns of the translations - starting with key */
  }

  const sorted =
    props.languages &&
    props.languages.sort().sort((x, y) => {
      return x === 'key' ? -1 : y === 'key' ? 1 : 0
    })
  return (
    <div className="row px-5">
      {sorted &&
        sorted.map((item, i) => (
          <div key={i} className="col medium-text text-left pb-2">
            {capitalize(item)}
          </div>
        ))}
      <div className="col-2 medium-text text-left pb-2">Info</div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedProject: projectSelectors.selectedProject(state)
  }
}

export default connect(mapStateToProps)(RowHeader)
