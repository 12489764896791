import * as React from 'react';
// Redux
import { connect } from 'react-redux';
// Models
import { Translation } from '../../../../models/Translation';
import { Project } from '../../../../models/Project';
// UI Components
import ExpandedPluralInputRow from './ExpandedPluralInputRow';
import NormalPluralInputRow from './NormalPluralInputRow';

interface Props {
  selectedProject: Project;
  translation: Translation;
  expanded: boolean;
  toggleExpansion: any;
  listRef: any;
}

const PluralInputRow = (props: Props) => {
  const translation = props.translation;
  return (
    <React.Fragment>
      {props.expanded ? (
        <React.Fragment>
          <ExpandedPluralInputRow
            toggleExpansion={props.toggleExpansion}
            translation={translation}
            selectedProjectId={props.selectedProject.id}
            listRef={props.listRef}
          />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <NormalPluralInputRow
              toggleExpansion={props.toggleExpansion}
              translation={translation}
              selectedProjectId={props.selectedProject.id}
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
};

export default connect()(PluralInputRow);
