import { Action } from 'redux';
export const SIGN_UP_USER_SUCCESS = 'SIGN_UP_USER_SUCCESS';

export interface SignUpUserSuccessAction extends Action {
  type: typeof SIGN_UP_USER_SUCCESS;
}

export const signUpUserSuccessAction = (
): SignUpUserSuccessAction => ({
  type: SIGN_UP_USER_SUCCESS,
});