import { RootState } from '../../store';

export const projects = (state: RootState) => {
  if (!state.projects) {
    return undefined;
  }

  return state.projects.projects;
};

export const isCreatingProject = (state: RootState) => {
  if (!state.organizations.isCreatingProject) {
    return false;
  }

  return !!state.organizations.isCreatingProject;
};

export const projectTranslations = (state: RootState) => {
  if (!state.projects) {
    return undefined;
  }
  if (!state.projects.selectedProject) {
    return undefined;
  }
  return state.projects.selectedProject.translations;
};

export const isLoadingProjects = (state: RootState) => {
  if (!state.projects.isLoadingProjects) {
    return false;
  }

  return !!state.projects.isLoadingProjects;
};

export const selectedProject = (state: RootState) => {
  if (!state.projects.selectedProject) {
    return undefined;
  }

  return state.projects.selectedProject;
};

export const isSelectingProject = (state: RootState) => {
  if (!state.projects) {
    return false;
  }

  return !!state.projects.isSelectingProject;
};

export const isDownloadingTranslations = (state: RootState) => {
  if (!state.projects) {
    return false;
  }

  return !!state.projects.isDownloadingTranslations;
};

export const keyPrefix = (state: RootState) => {
  if (!state.projects.selectedProject) {
    return undefined;
  }

  if (!state.projects.selectedProject.keyprefix) {
    return undefined;
  }

  return state.projects.selectedProject.keyprefix;
};

export const isGettingRowImage = (state: RootState) => {
  if (!state.projects) {
    return false;
  }

  return !!state.projects.isGettingRowImage;
};

export const isAddingPluralRow = (state: RootState) => {
  if (!state.projects) {
    return false;
  }

  return !!state.projects.isAddingPluralRow;
};

export const isAddingLanguage = (state: RootState) => {
  if (!state.projects) {
    return false;
  }

  return !!state.projects.isAddingLanguage;
};

export const languages = (state: RootState) => {
  if (!state.projects.selectedProject) {
    return undefined;
  }

  if (!state.projects.selectedProject.languages) {
    return [];
  }

  return state.projects.selectedProject.languages;
};

export const userRole = (state: RootState, email: string) => {
  if (!state.projects.selectedProject) {
    return undefined;
  }
  if (!state.projects.selectedProject.users) {
    return [];
  }
  if (!state.projects.selectedProject.users[email]) {
    return undefined;
  }
  return state.projects.selectedProject.users[email];
};

export const isImportingTranslations = (state: RootState) => {
  if (!state.projects.isImportingTranslations) {
    return false;
  }
  return !!state.projects.isImportingTranslations;
};

export const isAutoTranslating = (state: RootState, field: string) => {
  if (!state.projects.isAutoTranslating[field]) {
    return false;
  }
  return !!state.projects.isAutoTranslating[field];
};