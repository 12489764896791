import { Action } from 'redux';

export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS';

export interface GetOrganizationUsersAction extends Action {
  type: typeof GET_ORGANIZATION_USERS;
  users: { [email: string]: string }[];
}

export const getOrganizationUsersAction = (
  users: { [email: string]: string }[]
): GetOrganizationUsersAction => ({
  type: GET_ORGANIZATION_USERS,
  users
});