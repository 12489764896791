import { Action } from 'redux';
import { UserRole } from '../../../models/UserRole';
export const ADD_USER_TO_PROJECT_SUCCESS = 'ADD_USER_TO_PROJECT_SUCCESS';

export interface AddUserToProjectSuccessAction extends Action {
  type: typeof ADD_USER_TO_PROJECT_SUCCESS;
  user: UserRole;
}

export const addUserToProjectSuccessAction = (
  user: UserRole
): AddUserToProjectSuccessAction => ({
  type: ADD_USER_TO_PROJECT_SUCCESS,
  user
});