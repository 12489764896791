import { Action } from 'redux';

export const DOWNLOAD_TRANSLATIONS = 'DOWNLOAD_TRANSLATIONS';

export interface DownloadTranslationsAction extends Action {
  type: typeof DOWNLOAD_TRANSLATIONS;
}

export const downloadTranslationsAction = (
): DownloadTranslationsAction => ({
  type: DOWNLOAD_TRANSLATIONS,
});