import { Action } from 'redux';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';

export interface SignOutUserAction extends Action {
  type: typeof SIGN_OUT_USER;
}

export const signOutUserAction = (
): SignOutUserAction => ({
  type: SIGN_OUT_USER,
});