import { Action } from 'redux';
import { Translation } from '../../../../models/Translation';

export const ADD_PLURAL_ROW_SUCCESS = 'ADD_PLURAL_ROW_SUCCESS';

export interface AddPluralRowSuccessAction extends Action {
  type: typeof ADD_PLURAL_ROW_SUCCESS;
  translation: Translation;
}

export const addPluralRowSuccessAction = (
  translation: Translation,
): AddPluralRowSuccessAction => ({
  type: ADD_PLURAL_ROW_SUCCESS,
  translation,
});