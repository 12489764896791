import { Action } from 'redux';
import { Translation } from '../../../../models/Translation';

export const ADD_ROW_SUCCESS = 'ADD_ROW_SUCCESS';

export interface AddRowSuccessAction extends Action {
  type: typeof ADD_ROW_SUCCESS;
  translation: Translation;
}

export const addRowSuccessAction = (
  translation: Translation,
): AddRowSuccessAction => ({
  type: ADD_ROW_SUCCESS,
  translation,
});