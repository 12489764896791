export enum Role {
  owner,
  admin,
  developer,
  translator,
  member
}

export enum ReadAndWriteAll {
  admin,
  owner
}

export enum CreateUpdateOrganizations {
  owner,
  admin,
}

export enum CreateUpdateProjects {
  owner,
  admin,
}

export enum CreateDeleteUpdateTranslations {
  owner,
  admin,
  developer,
}

export enum UpdateTranslations {
  owner,
  admin,
  developer,
  translator,
}

export enum ReadAll {
  owner,
  admin,
  developer,
  translator,
  member
}