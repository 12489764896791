import * as React from 'react';
import * as authSelectors from '../../../auth/authSelector';
import RowTools from '../RowTools';
import { Chip } from '@material-ui/core';
import { Translation } from '../../../../models/Translation';
import TextField from '../../../ui-components/TextField';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as translationsApi from '../../../../services/translationsApi';
import { RootState } from '../../../../store';

interface Props {
  selectedProjectId: string;
  translation: Translation | undefined;
  toggleExpansion: () => void;
  updateInput: (
    projectId: string,
    translation: Translation,
    key: string,
    value: string,
    plural: boolean,
    isComment: boolean,
    editor: string
  ) => void;
  listRef: any;
  userEmail: string;
}

const ExpandedPluralInputRow = (props: Props) => {
  const translation = props.translation;
  if (!translation) return null;

  const hasComment = translation.pluralcomment !== undefined && translation.pluralcomment.length > 0 ? true : false;

  return (
    <React.Fragment>
      <div className="container-fluid px-5 mt-2">
        <div id={`input-row`} className="row" >
          <div className="col text-left float-left mr-2">
            <i className="material-icons">subdirectory_arrow_right</i>
            <Chip label="Plural" />
          </div>
          <div className="col-8" />
          < div className="col-2 text-left">
            <RowTools
              translationId={translation.id}
              hasComment={hasComment}
              expand={props.toggleExpansion}
              expanded={true}
              plural={true}
              selectedProjectId={props.selectedProjectId}
              hasPlural={translation.pluralTranslations.length === 0 ? false : true}
            />
          </div>
          {translation.pluralTranslations!.filter(el => el.getKey() !== 'key').map((row, i) => (
            <div className="row" key={i} style={{ width: '100%' }}>
              <React.Fragment>
                <React.Fragment>
                  <div className="col-2 small-text text-right mt-3 mr-2" style={{ float: 'right' }}>
                    {row.getKey().capitalize()}
                  </div>
                </React.Fragment>
                <div className="col-8 text-left mt-2 mr-2">
                  <TextField
                    keyId={i}
                    translation={props.translation!}
                    projectId={props.selectedProjectId}
                    value={row.getValue()}
                    name={row.getKey()}
                    updateInput={(e) =>
                      props.updateInput(
                        props.selectedProjectId!,
                        translation,
                        e.currentTarget.name,
                        e.currentTarget.value,
                        true,
                        false,
                        props.userEmail
                      )
                    }
                  />
                </div>
              </React.Fragment>
            </div>
          ))}
          {translation.pluralcomment !== undefined &&
            <div className="row" style={{ width: '100%' }}>
              <div className="col-1" />
              <div className="col-1 small-text text-right mt-3 mr-2" style={{ float: 'right' }}>
                Comment
              </div>
              <div className="col-8 text-left mt-2 mr-2">
                <TextField
                  projectId={props.selectedProjectId}
                  translation={props.translation!}
                  value={translation.pluralcomment}
                  name="pluralcomment"
                  updateInput={(e) =>
                    props.updateInput(
                      props.selectedProjectId,
                      translation,
                      e.currentTarget.name,
                      e.currentTarget.value,
                      true,
                      true,
                      props.userEmail
                    )
                  }
                />
              </div>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    updateInput: (
      projectId: string,
      translation: Translation,
      field: string,
      value: string,
      plural: boolean,
      isComment: boolean,
      editor: string) =>
      translationsApi.updateInput(projectId, translation, field, value, plural, isComment, editor),
  }, dispatch);
};

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: authSelectors.userEmail(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandedPluralInputRow);