import { Action } from 'redux';

export const GET_TRANSLATIONS_FAIL = 'GET_TRANSLATIONS_FAIL';

export interface GetTranslationsFailAction extends Action {
  type: typeof GET_TRANSLATIONS_FAIL;
}

export const getTranslationsFailAction = (
): GetTranslationsFailAction => ({
  type: GET_TRANSLATIONS_FAIL,
});