import * as React from 'react'
import Logo from '../../assets/logo/icecream@2x.png'
import { Link, RouteComponentProps, Redirect } from 'react-router-dom'
import { authRef } from '../../store'
import { toastr } from 'react-redux-toastr'
import './signup-styles.css'
import { LocaliceLogo } from '../common/navigation/NavbarStyles'

interface Props extends RouteComponentProps {
  userId: string | undefined
}

const SignIn = (props: Props) => {
  const [values, setValues] = React.useState({
    email: '',
    password: ''
  })

  const login = (e: React.FormEvent) => {
    e.preventDefault()
    if (values.email.length !== 0 && values.password.length !== 0) {
      authRef
        .signInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          props.history.push('/')
        })
        .catch((error) => {
          toastr.error(
            'Error',
            'There was a problem signing in. Please try again'
          )
        })
    } else {
      alert('You have not entered either email or password!')
    }
  }

  if (props.userId) {
    return <Redirect to="/" />
  }

  return (
    <div id="login-page">
      <div style={{ padding: '0.5rem 1rem' }}>
        <div className="container-fluid px-0">
          <div className="navbar-brand localize-logo ml-5">
            <Link to={'/'} className="align-self-center">
              <LocaliceLogo
                src={Logo}
                alt="logo"
                className="mr-3"
                style={{ width: '39px', height: '72px' }}
              />
              Localice.io
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="row text-center">
          <div className="col-lg-4 mx-auto">
            <form onSubmit={login}>
              <h1 className="mt-5 display-1">Login</h1>
              <div className="form-group mt-3">
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control mb-3 input-field"
                  onChange={(e) =>
                    setValues({ ...values, email: e.currentTarget.value })
                  }
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control mb-3 input-field"
                  onChange={(e) =>
                    setValues({ ...values, password: e.currentTarget.value })
                  }
                />
              </div>
              <button
                name="login-btn"
                type="submit"
                className="general-button mt-4"
              >
                Sign in!
              </button>
              <div className="mt-4">
                <p style={{ fontSize: '20px', color: 'blue' }}>
                  <Link to="/forgot-password">Forgot password?</Link>
                </p>
                <p className="mt-4">
                  No account?{' '}
                  <Link style={{ color: 'blue' }} to="/signup">
                    Sign up
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
