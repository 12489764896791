import { Dispatch } from 'redux'
import { authRef, dbRef, history } from '../store'
import * as orgActions from '../modules/organization/orgActions'
import { Organization } from '../models/Organization'
import * as FSROUTES from '../constants/firestoreRoutes'
import { toastr } from 'react-redux-toastr'
import * as emailApi from './emailApi'
import { User } from '../models/User'
import firebase from 'firebase/app'

function getUserOrganizations(userId: string) {
  if (!userId) return
  return new Promise<Array<string>>((resolve, reject) => {
    dbRef
      .collection(FSROUTES.USERS)
      .doc(userId)
      .get()
      .then((snapshot) => {
        resolve(Object.keys(snapshot.get('organization_roles')))
      })
      .catch((error) => {
        reject(new Error(error))
      })
  })
}

export function getOrgs(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: orgActions.GET_ORGANIZATIONS })
    if (!userId) {
      dispatch({ type: orgActions.GET_ORGANIZATIONS_FAIL })
    }
    const orgRef = dbRef.collection(FSROUTES.ORGANIZATIONS)
    let organizations: Organization[] = []
    const userOrganizations = await getUserOrganizations(userId)

    if (!userOrganizations) {
      dispatch({ type: orgActions.GET_ORGANIZATIONS_FAIL })
    }
    if (userOrganizations) {
      for (const org of userOrganizations) {
        await orgRef
          .doc(org)
          .get()
          .then((doc) => {
            organizations.push({
              id: doc.id,
              name: doc.get('name'),
              users: doc.get('users'),
              projects: doc.get('projects'),
              apikeys: doc.get('api_keys')
            } as Organization)
          })
      }
      dispatch({ type: orgActions.GET_ORGANIZATIONS_SUCCESS, organizations })
    } else {
      dispatch({ type: orgActions.GET_ORGANIZATIONS_FAIL })
    }
  }
}

export function createOrganization(name: string, user: User, users: string[]) {
  let usersToRole = {}
  users.forEach((u) => (usersToRole[u] = 'developer'))
  usersToRole[user.email] = 'owner'
  return async (dispatch: Dispatch) => {
    dispatch({ type: orgActions.CREATE_ORGANIZATIONS })
    // Firestore references
    const newOrgRef = dbRef.collection(FSROUTES.ORGANIZATIONS).doc()
    const userRef = dbRef.collection(FSROUTES.USERS).doc(user.id)
    const invitationsRef = dbRef.collection(FSROUTES.INVITATIONS)
    // New state object to replace current state
    let userOrganizations = {}
    let organization = {}
    // Run transaction to ensure atomicity of the database operations
    await dbRef
      .runTransaction(function (transaction: firebase.firestore.Transaction) {
        return transaction.get(userRef).then((doc) => {
          userOrganizations = doc.get('organization_roles')
          userOrganizations[newOrgRef.id] = 'owner'
          // For each invited user, add an invitation to firestore with default role
          users.forEach((u) => {
            transaction.set(invitationsRef.doc(), {
              user: u,
              organization_id: newOrgRef.id,
              role: 'developer',
              status: 'pending'
            })
            if (u !== user.email) emailApi.sendInvitation(u, name, newOrgRef.id)
          })
          // Create the new organization in firestore.
          transaction.set(newOrgRef, {
            name: name,
            users: { [user.email]: 'owner' },
            projects: [],
            created: firebase.firestore.Timestamp.fromDate(new Date())
          })
          // Add the 'owner' role to the user who created the org.
          transaction.set(
            userRef,
            {
              organization_roles: userOrganizations
            },
            { merge: true }
          )
          // Remember to add the new users of this organization to the list of users in firestore
          transaction.set(newOrgRef, { users: usersToRole }, { merge: true })
          // Create organization model for the global state
          organization = {
            id: newOrgRef.id,
            name,
            users: Object.assign({}, usersToRole),
            projects: []
          }
        })
      })
      .then(() => {
        dispatch({
          type: orgActions.CREATE_ORGANIZATIONS_SUCCESS,
          organization
        })
        toastr.success('Success!', `Organization created, ${name}`)
        history.push(`/${name}/${newOrgRef.id}`)
      })
      .catch((error: any) => {
        dispatch({ type: orgActions.CREATE_ORGANIZATIONS_FAIL })
        toastr.error(
          'Failure!',
          `Error creating organization. Please try again.`
        )
      })
  }
}

export function getOrgUsers(users: { [email: string]: string }[]) {
  return (dispatch: Dispatch) => {
    let tempUsers: User[] = []
    Promise.all(
      users.map((user) => {
        return dbRef
          .collection(FSROUTES.USERS)
          .where('email', '==', user)
          .get()
          .then((doc) => {
            let orgUser: User = {
              id: doc.docs[0].id,
              email: user.email,
              role: user.role,
              name: doc.docs[0].get('displayName')
            }
            tempUsers.push(orgUser)
          })
          .catch((err) => console.log(err))
      })
    ).then(() =>
      dispatch({ type: orgActions.GET_ORGANIZATION_USERS, users: tempUsers })
    )
  }
}
