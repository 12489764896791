import { Action } from 'redux';

export const GET_USER_FAIL = 'GET_USER_FAIL';

export interface GetUserFailAction extends Action {
  type: typeof GET_USER_FAIL;
}

export const getUserFailAction = (
): GetUserFailAction => ({
  type: GET_USER_FAIL,
});