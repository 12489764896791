import styled from 'styled-components';

export const OrgNavbarContainer = styled.div`
  height: 100px;
  marginBottom: 0.125rem;
  backgroundColor: white;
`;

export const LocaliceLogo = styled.img`
  width: 39px;
  height: 72px;
`;