import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const TransformRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface SpinnerProps {
  containerWidth?: string;
  containerHeight?: string;
  width?: string;
  height?: string;
  borderSize?: string;
}

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props: SpinnerProps) => props.containerWidth ? props.containerWidth : '64px'};
  height: ${(props: SpinnerProps) => props.containerHeight ? props.containerHeight : '64px'};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props: SpinnerProps) => props.width ? props.width : '51px'};
    height: ${(props: SpinnerProps) => props.height ? props.height : '51px'};
    margin: 6px;
    border: ${(props: SpinnerProps) => props.borderSize ? props.borderSize : '6px'} solid #000;
    border-radius: 50%;
    animation: ${TransformRotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const CenteredSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "SF UI Display Light";
`;

export const Spinner = (props: SpinnerProps) => (
  <SpinnerContainer {...props} className="lds-ring"><div /><div /><div /><div /></SpinnerContainer>
);

export const SpinnerBlock = () => (
  <div className="container">
    <div className="row">
      <div className="col text-center mt-8 mb-8">
        <Spinner />
      </div>
    </div>
  </div>
);

export const CenteredSpinner = (props: { title: string }) => (
  <CenteredSpinnerContainer >
    <h3>{props.title}</h3>
    <Spinner />
  </CenteredSpinnerContainer>
);

export default Spinner;