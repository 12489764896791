import { Action } from 'redux';
export const ADD_USER_TO_PROJECT = 'ADD_USER_TO_PROJECT';

export interface AddUserToProjectAction extends Action {
  type: typeof ADD_USER_TO_PROJECT;
}

export const addUserToProjectAction = (
): AddUserToProjectAction => ({
  type: ADD_USER_TO_PROJECT,
});