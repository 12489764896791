import SortableTranslation from '../models/SortableTranslation'
import Placeholder from '../models/Placeholder'
import { Project } from '../models/Project'
import { Translation } from '../models/Translation'

export const placeholdersToReplace = [
  new Placeholder(/\'/gm, "'", "\\'"),
  new Placeholder(/\% /gm, '%% ', '% ')
]

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Helper to add proper format to downloads
export function handleKeyFormat(
  platform: string,
  key: string,
  input: string,
  keyprefix: string,
  lastEl: boolean
) {
  // Check for placeholder formatting
  if (checkPlaceholders(input)) {
    input = formatPlaceholders(input, platform)
  }
  if (platform === 'ios') return iOSFormat(key, keyprefix, input, lastEl)
  else if (platform === 'android')
    return androidFormat(key, input, keyprefix, lastEl)
  else if (platform === 'flutter')
    return flutterFormat(key, input, keyprefix, lastEl)
  return
}

export function handlePluralKeyFormat(
  platform: string,
  key: string,
  value: string,
  valuePlural: string,
  keyprefix: string,
  lastEl: boolean
) {
  // Check for placeholder formatting for both singular and plural
  if (checkPlaceholders(value)) value = formatPlaceholders(value, platform)
  if (checkPlaceholders(valuePlural))
    valuePlural = formatPlaceholders(valuePlural, platform)
  // Format according to platform
  if (platform === 'ios')
    return (valuePlural = iOSPluralFormat(
      key,
      value,
      valuePlural,
      keyprefix,
      lastEl
    ))
  else if (platform === 'android')
    return (valuePlural = androidPluralFormat(
      key,
      value,
      valuePlural,
      keyprefix,
      lastEl
    ))
  else if (platform === 'flutter')
    return (valuePlural = flutterPluralFormat(
      key,
      value,
      valuePlural,
      keyprefix,
      lastEl
    ))
  return
}

// Helper function to find first translation, which is neither null nor empty
// And furthermore, we do not want to take the key, since this makes no sense as a translation
export function firstNotNulTrans(translations: SortableTranslation[]) {
  const trans = translations.find(
    (el) =>
      el.getValue() !== undefined &&
      el.getValue() !== null &&
      el.getValue() !== '' &&
      el.getKey() !== 'key'
  )
  if (trans) {
    return trans.getValue()
  } else {
    return ''
  }
}

/**
 * --------------------------------------
 *      START: Plural formatters
 * --------------------------------------
 */

export const webPluralFormat = (key: string, keyprefix: string) => {
  return `${keyprefix}${key}.plural`
}

export const getPluralValue = (translation: Translation, language: string) => {
  let trans = translation.pluralTranslations.find(
    (el) => el.getKey() === `plural${language}`
  )
  let valuePlural =
    !trans || trans.getValue() === ''
      ? firstNotNulTrans(translation.translations)
      : trans.getValue()
  return valuePlural
}

function iOSPluralFormat(
  key: string,
  value: string,
  valuePlural: string,
  keyprefix: string,
  lastEl: boolean
) {
  return (
    `"${keyprefix}${key}.single" = "${value}";\n` +
    `"${keyprefix}${key}.plural" = "${valuePlural}";${!lastEl ? '\n' : ''}`
  )
}

function androidPluralFormat(
  key: string,
  value: string,
  valuePlural: string,
  keyprefix: string,
  lastEl: boolean
) {
  // Replace dot with underscore and remove prefix
  var newKey = key.replace(/\./gm, '_')
  return `  <plurals name="${keyprefix + newKey}">
    <item quantity="one">
      ${value}
    </item>
    <item quantity="other">
      ${valuePlural}
    </item>
  </plurals>
`
}

function flutterPluralFormat(
  key: string,
  value: string,
  valuePlural: string,
  keyprefix: string,
  lastEl: boolean
) {
  return (
    `    '${keyprefix}${key}.singular': '${value}',\n` +
    `    '${keyprefix}${key}.plural': '${valuePlural}'${!lastEl ? ',\n' : ''}`
  )
}

/**
 * --------------------------------------
 *      END: Plural formatters
 * --------------------------------------
 */

/**
 * --------------------------------------
 *      START: Singular formatters
 * --------------------------------------
 */
function iOSFormat(
  key: string,
  keyprefix: string,
  input: string,
  lastEl: boolean
) {
  return `"${keyprefix}${key}" = "${input}";${!lastEl ? '\n' : ''}`
}

function androidFormat(
  key: string,
  input: string,
  keyprefix: string,
  lastEl: boolean
) {
  // Replace dot with underscore and remove prefix
  const newKey = key.replace(/\./gm, '_')
  // Add android specific format
  return `  <string name="${newKey}">${input}</string>${!lastEl ? '\n' : ''}`
}

function flutterFormat(
  key: string,
  value: string,
  keyprefix: string,
  lastEl: boolean
) {
  return `    '${keyprefix}${key}': '${value}'${!lastEl ? ',\n' : '\n'}`
}
/**
 * --------------------------------------
 *      END: Singular formatters
 * --------------------------------------
 */

// Go through all given placeholders and replace them with proper replacement
export function formatPlaceholders(input: string, platform: string) {
  for (const placeholder of placeholdersToReplace) {
    input = input.replace(
      placeholder.toReplace,
      platform === 'ios' ? placeholder.onIos : placeholder.onAndroid
    )
  }
  return input
}

// Check if the input contains on of the placeholders
export function checkPlaceholders(input: string) {
  return placeholdersToReplace.some((placeholder) =>
    placeholder.toReplace.test(input)
  )
}

export function addInitialFileFormatting(
  platform: string,
  lang: string,
  filetodownload: string
) {
  if (platform === 'flutter') {
    filetodownload += `class ${capitalizeFirstLetter(lang)} {` + `\n`
    filetodownload += `  static var translations {` + `\n`
    return filetodownload
  } else if (platform === 'android') {
    filetodownload += '<?xml version="1.0" encoding="utf-8"?>' + `\n`
    filetodownload += '<resources>' + `\n`
    return filetodownload
  } else {
    return filetodownload
  }
}

export const reduceKey = (key: string) => {
  let splitted = key.split('.')
  let reducedKey = splitted[splitted.length - 1]
  return reducedKey
}

export const reduceKeyWeb = (key: string) => {
  let splitted = key.split('.')
  let reducedKey = splitted.reduce((acc, value) => {
    let partialKey
    if (acc) {
      partialKey = capitalizeFirstLetter(value)
      acc += partialKey
    } else {
      acc += value
    }
    return acc
  }, '')
  return reducedKey
}

export function addFinalFileFormatting(
  filetodownload: string,
  platform: string
): string {
  if (platform === 'android') {
    // Replace ios string placeholder with android
    filetodownload = filetodownload.replace(/%@/g, '%s')
    filetodownload += '\n</resources>'
  } else if (platform === 'flutter') {
    filetodownload += `  }` + `\n`
    filetodownload += `}`
  }
  return filetodownload
}

export const returnWebExtension = (project: Project, keyprefix: string) => {
  let webExtension = `
  export const translationKeys = {
  `
  project.translations.forEach((translation) => {
    const key = translation.translations
      .find((el) => el.getKey() === 'key')!
      .getValue()
    if (key) {
      const reducedKey = reduceKeyWeb(key)
      const reducedKeyNoDigits = reducedKey.replace(/[0-9]/g, 'X')
      webExtension += `${reducedKeyNoDigits}: "${keyprefix}${key}",\n`
      if (
        translation.pluralTranslations !== undefined &&
        translation.pluralTranslations.length > 0
      ) {
        webExtension += `${reducedKeyNoDigits}Plural: "${webPluralFormat(
          key,
          keyprefix
        )}",\n`
      }
    }
  })
  webExtension += '}'
  return webExtension
}

export function addSwiftExtension(): string {
  return `
}

private extension String {
    func localized(_ args: [CVarArg]? = nil) -> String {
        guard let args = args else {
            return NSLocalizedString(self, comment: "")
        }
        return String(format: NSLocalizedString(self, comment: ""), arguments: args)
    }
}`
}
