import { Action } from 'redux';

export const CREATE_ORGANIZATIONS = 'CREATE_ORGANIZATIONS';

export interface CreateOrganizationsAction extends Action {
  type: typeof CREATE_ORGANIZATIONS;
  orgName: string;
}

export const createOrganizationsAction = (
  orgName: string,
): CreateOrganizationsAction => ({
  type: CREATE_ORGANIZATIONS,
  orgName
});