import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LogsTable from './LogsTable';
import './editlogsmodal-styling.css';

interface Props {
  open: boolean;
  setOpen: any;
  logs: any[];
}

const EditLogModal = ({ open, setOpen, logs }: Props) => (
  <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg" scrollable="true">
    <ModalHeader toggle={() => setOpen(!open)}>
      Editing log for translation
    </ModalHeader>
    <ModalBody>
      <LogsTable logs={logs} />
    </ModalBody>
    <ModalFooter>
      <Button
        className="small-text"
        color="link"
        style={{ color: 'black' }}
        onClick={() => setOpen(!open)}
      >
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

export default EditLogModal;