import * as React from 'react';
import { Link } from 'react-router-dom';
import './header-styling.css';
import './navigationbar-styling.css';
import LandingPageNavbar from '../navigation/LandingPageNavbar';
import video from '../../../assets/localice-demo.mp4';

// Redux methods
import * as orgApi from '../../../services/orgApi';
import { User } from '../../../models/User';
import { connect } from 'react-redux';
import { Organization } from '../../../models/Organization';
import { bindActionCreators, Dispatch } from 'redux';
import InteractiveDemo from './InteractiveDemo';
import styled from 'styled-components';

const VideoContainer = styled.div`
  max-width: 900px;
  max-height: 540px;
  box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.075) !important;
`;

interface Props {
  user: User | undefined;
  selectedOrganization: Organization | undefined;
  organizations: Organization[] | undefined;
}

const LandingPage = (props: Props) => {
  const renderGetStartedButton = () => {
    if (!!props.user && props.selectedOrganization) {
      return (
        <Link to={`/${props.selectedOrganization!.name}/${props.selectedOrganization!.id}`}>
          <button className="js-scroll-trigger landingpage-get-started">
            Get started for free
          </button>
        </Link>
      );
    } else if ((!props.selectedOrganization || !props.organizations) && props.user!.id !== undefined) {
      return (
        <Link to={`/create-organization`}>
          <button className="js-scroll-trigger landingpage-get-started">
            Get started for free
          </button>
        </Link>
      );
    } else {
      return (
        <Link to={`/signup`}>
          <button className="js-scroll-trigger landingpage-get-started">
            Get started for free
          </button>
        </Link>
      );
    }
  };

  return (
    <React.Fragment>
      <LandingPageNavbar
        user={props.user}
        selectedOrganization={props.selectedOrganization}
      />
      <div>
        <header>
          <div className="container">
            <div className="landingpage landingpage-text">
              <div className="landingpage-heading">
                Manage languages in your app, easily across your team.
          </div>
              {renderGetStartedButton()}
            </div>
          </div>
        </header>
        <section className="page-section" id="teams">
          <div className="landingpage-section-color d-flex container-fluid justify-content-center pt-5" />
        </section>
        <section className="page-section mt-5">
          <div className="d-flex container justify-content-center">
            <i className="material-icons" style={{ lineHeight: '36px', color: 'blue' }}>
              play_circle_outline
          </i>
            <a
              style={{ color: 'blue' }}
              className="landingpage-section-text ml-2"
              href="#demo"
            >
              Watch demo
            </a>
          </div>
        </section>
        <section className="page-section" id="tutorial">
          <div className="d-flex container justify-content-center">
            <VideoContainer className="shadow-sm">
              <video className="w-100" id="background-video" loop={true} autoPlay={true}>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </VideoContainer>
          </div>
        </section>
        <section className="page-section" id="teams">
          <div className="container">
            <div className="landingpage landingpage-text">
              <div className="landingpage-section-header">
                Leading product teams are already using Localice.io
                </div>
              <div className="landingpage-section-text">
                We have always been devoted to making translation of apps as simple as possible.
                Today, more than +300 teams are using Localice.io to manage their
                translations and make it easier for everyone.
                </div>
              {/* <a
                      style={{ color: 'blue' }}
                      className="landingpage-section-text"
                      href="#why"
                    >
                      Click here to read why
                    </a> */}
              <div className="landingpage-section-header mt-5">
                Try it for free
                </div>
              <div className="landingpage-section-text">
                Use Localice.io for free and see if you like it - no maximum time periode.
                We know you want to test before you buy - so feel free to get started
                with 1 project and 2 languages to feel out the magic.
              </div>
              {renderGetStartedButton()}
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row justify-content-between text-center">
              <div className="col">
                <span className="copyright">Copyright &copy; Localice.io 2019</span>
              </div>
              <div className="col">
                Localice.io
              </div>
              <div className="col">
                <ul className="list-inline quicklinks">
                  <li className="list-inline-item">
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(LandingPage);