import ReactGA from 'react-ga';

export const customGaMiddleWare = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case 'AUTO_TRANSLATE_SUCCESS':
      ReactGA.event({
        category: 'TRANSLATIONS',
        action: 'Update',
        label: `Translation updated using auto-translate.`
      });
      break;
    case 'ADD_PLURAL_ROW_SUCCESS':
      ReactGA.event({
        category: 'TRANSLATIONS',
        action: 'Add',
        label: 'Plural translation added'
      });
      break;
    case 'ADD_ROW_SUCCESS':
      ReactGA.event({
        category: 'TRANSLATIONS',
        action: 'Add',
        label: 'Translation row added'
      });
      break;
    case 'REMOVE_ROW':
      ReactGA.event({
        category: 'TRANSLATIONS',
        action: 'Delete',
        label: 'Translation row deleted'
      });
      break;
    case 'UPDATE_INPUT':
      ReactGA.event({
        category: 'TRANSLATIONS',
        action: 'Update',
        label: 'Translation updated'
      });
      break;
    case 'CREATE_PROJECT_SUCCESS':
      ReactGA.event({
        category: 'PROJECTS',
        action: 'Create',
        label: 'Project created'
      });
      break;
    case 'CREATE_PROJECT_FAIL':
      ReactGA.event({
        category: 'PROJECTS',
        action: 'Create',
        label: 'Project creation failed'
      });
      break;
    case 'DOWNLOAD_TRANSLATIONS_SUCCESS':
      ReactGA.event({
        category: 'PROJECTS',
        action: 'Download',
        label: 'Download translations'
      });
      break;
    case 'CREATE_ORGANIZATIONS_SUCCESS':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Create',
        label: 'Organization created'
      });
      break;
    case 'CREATE_ORGANIZATIONS_FAIL':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Create',
        label: 'Organization creation failed'
      });
      break;
    case 'ADD_USER_TO_ORG_SUCCESS':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Add',
        label: 'User added to organisation'
      });
      break;
    case 'ADD_USER_TO_ORG_FAIL':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Add',
        label: 'Failed to add user to organization'
      });
      break;
    case 'ADD_USER_TO_PROJECT_SUCCESS':
      ReactGA.event({
        category: 'PROJECTS',
        action: 'Add',
        label: 'User added to project'
      });
      break;
    case 'ADD_USER_TO_PROJECT_FAIL':
      ReactGA.event({
        category: 'PROJECTS',
        action: 'Add',
        label: 'Failed to add user to project'
      });
      break;
    case 'SIGN_UP_USER_SUCCESS':
      ReactGA.event({
        category: 'GENERAL',
        action: 'Signup',
        label: 'Signed up user'
      });
      break;
    case 'SIGN_UP_USER_FAIL':
      ReactGA.event({
        category: 'GENERAL',
        action: 'Signup',
        label: 'Failed to sign up user'
      });
      break;
    case 'API_KEY_CREATE_SUCCESS':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Create',
        label: 'Api key created'
      });
      break;
    case 'API_KEY_DELETE_SUCCESS':
      ReactGA.event({
        category: 'ORGANIZATIONS',
        action: 'Create',
        label: 'Api key deleted'
      });
      break;
    default:
      break;
  }
  next(action);
};