import { Action } from 'redux';

export const ADD_LANGUAGE_TO_PROJECT = 'ADD_LANGUAGE_TO_PROJECT';

export interface AddLanguageToProjectAction extends Action {
  type: typeof ADD_LANGUAGE_TO_PROJECT;
  language: string;
}

export const addLanguageToProjectAction = (
  language: string,
): AddLanguageToProjectAction => ({
  type: ADD_LANGUAGE_TO_PROJECT,
  language
});