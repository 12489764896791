import * as orgActions from './orgActions';
import * as authActions from '../auth/authActions';
import * as projectActions from '../project/projectActions';
import { Organization } from '../../models/Organization';
import update from 'immutability-helper';

export interface OrganizationState {
  organizations: Organization[];
  selectedOrganization: Organization | undefined;
  isLoadingOrganizations: boolean;
  isSelectingOrganization: boolean;
  isCreatingOrganization: boolean;
  isCreatingProject: boolean;
  isCreatingApiKey: boolean;
  isDeletingApiKey: boolean;
  errorMessage: string;
}

const initialState: OrganizationState = {
  organizations: [],
  selectedOrganization: undefined,
  isLoadingOrganizations: false,
  isSelectingOrganization: false,
  isCreatingOrganization: false,
  isCreatingProject: false,
  isCreatingApiKey: false,
  isDeletingApiKey: false,
  errorMessage: '',
};

type SupportedActions =
  orgActions.GetOrganizationsAction |
  orgActions.GetOrganizationsFailAction |
  orgActions.GetOrganizationsSuccessAction |
  orgActions.SelectOrganizationAction |
  orgActions.SelectOrganizationFailAction |
  orgActions.SelectOrganizationSuccessAction |
  orgActions.GetOrganizationUsersAction |
  projectActions.CreateProjectAction |
  projectActions.CreateProjectFailAction |
  projectActions.CreateProjectSuccessAction |
  orgActions.CreateOrganizationsAction |
  orgActions.CreateOrganizationsFailAction |
  orgActions.CreateOrganizationsSuccessAction |
  authActions.AddUserToOrgAction |
  authActions.AddUserToOrgFailAction |
  authActions.AddUserToOrgSuccessAction |
  orgActions.CreateApiKeyAction |
  orgActions.CreateApiKeyFailAction |
  orgActions.CreateApiKeySuccessAction |
  orgActions.DeleteApiKeyAction |
  orgActions.DeleteApiKeyFailAction |
  orgActions.DeleteApiKeySuccessAction;

export const organizationsReducer = (state = initialState, action: SupportedActions) => {
  switch (action.type) {
    case orgActions.GET_ORGANIZATIONS:
      return {
        ...state,
        isLoadingOrganizations: true,
      };
    case orgActions.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.organizations,
        isLoadingOrganizations: false,
      };
    case orgActions.GET_ORGANIZATIONS_FAIL:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoadingOrganizations: false,
      };
    case orgActions.SELECT_ORGANIZATION:
      return {
        ...state,
        isSelectingOrganization: true,
      };
    case orgActions.SELECT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        selectedOrganization: action.organization,
        isSelectingOrganization: false,
      };
    case orgActions.SELECT_ORGANIZATION_FAIL:
      return {
        ...state,
        isSelectingOrganization: false,
      };
    case orgActions.GET_ORGANIZATION_USERS:
      return update(state, {
        selectedOrganization: {
          users: { $set: action.users }
        }
      });
    case orgActions.CREATE_ORGANIZATIONS:
      return {
        ...state,
        isCreatingOrganization: true,
      };
    case orgActions.CREATE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: [...state.organizations, action.organization],
        isCreatingOrganization: false,
        selectedOrganization: action.organization
      };
    case orgActions.CREATE_ORGANIZATIONS_FAIL:
      return {
        ...state,
        // errorMessage: action.errorMessage,
        isCreatingOrganization: false,
      };
    case authActions.ADD_USER_TO_ORG:
      return {
        ...state
      };
    case authActions.ADD_USER_TO_ORG_SUCCESS:
      return update(state, {
        // selectedOrganization: {
        //   users: { $push: [{ [action.user.email]: action.user.role }] }
        // }
      });
    case authActions.ADD_USER_TO_ORG_FAIL:
      return {
        ...state
      };
    case projectActions.CREATE_PROJECT:
      return {
        ...state,
        isCreatingProject: true,
      };
    case projectActions.CREATE_PROJECT_FAIL:
      return {
        ...state,
        isCreatingProject: false,
      };
    case projectActions.CREATE_PROJECT_SUCCESS:
      return update(state, {
        selectedOrganization: {
          projects: { $push: [action.projectId] }
        },
        isCreatingProject: { $set: false }
      });
    case orgActions.CREATE_API_KEY:
      return {
        ...state,
        isCreatingApiKey: true
      };
    case orgActions.CREATE_API_KEY_SUCCESS:
      return update(state, {
        selectedOrganization: {
          apikeys: { $push: [action.apiKey] }
        },
        isCreatingApiKey: { $set: false }
      });
    case orgActions.CREATE_API_KEY_FAIL:
      return {
        ...state,
        isCreatingApiKey: false
      };
    case orgActions.DELETE_API_KEY:
      return {
        ...state,
        isDeletingApiKey: true
      };
    case orgActions.DELETE_API_KEY_SUCCESS:
      const index = state.selectedOrganization!.apikeys.findIndex(el => el === action.apiKey);
      if (!index) {
        return {
          ...state,
          isDeletingApiKey: false
        };
      }
      return update(state, {
        selectedOrganization: {
          apikeys: { $splice: [[index, 1]] }
        },
        isDeletingApiKey: { $set: false }
      });
    default:
      return state;
  }
};