import { Action } from 'redux';

export const CREATE_ORGANIZATIONS_FAIL = 'CREATE_ORGANIZATIONS_FAIL';

export interface CreateOrganizationsFailAction extends Action {
  type: typeof CREATE_ORGANIZATIONS_FAIL;
}

export const createOrganizationsFailAction = (): CreateOrganizationsFailAction => ({
  type: CREATE_ORGANIZATIONS_FAIL,
});