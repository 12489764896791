import { Action } from 'redux';

export const CREATE_API_KEY = 'CREATE_API_KEY';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAIL = 'CREATE_API_KEY_FAIL';

export interface CreateApiKeyAction extends Action {
  type: typeof CREATE_API_KEY;
}

export interface CreateApiKeySuccessAction extends Action {
  type: typeof CREATE_API_KEY_SUCCESS;
  apiKey: string;
}

export interface CreateApiKeyFailAction extends Action {
  type: typeof CREATE_API_KEY_FAIL;
}

export const createApiKeyAction = (): CreateApiKeyAction => ({
  type: CREATE_API_KEY,
});

export const createApiKeySuccessAction = (apiKey: string): CreateApiKeySuccessAction => ({
  type: CREATE_API_KEY_SUCCESS,
  apiKey
});

export const createApiKeyFailAction = (): CreateApiKeyFailAction => ({
  type: CREATE_API_KEY_FAIL,
});