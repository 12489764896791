import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: API_URL,
  // withCredentials: true
});

export default function getData(path: any) {
  return client.get(path);
}

export function request(method: any, path: any, headers: any = {}, payload: any = {}) {
  const options = {
    method,
    headers,
    url: path,
    data: {
      data: payload
    }
  };

  return client(options);
}