import * as React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CopyBlock, github } from 'react-code-blocks';
import { generateScript } from '../../../util/generateScript';
import { Project } from '../../../models/Project';
import { Organization } from '../../../models/Organization';

const InputField = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: #999;
`;

const Select = styled.select`
  border-color: #999;
  color: #999;
  max-width: 50%;
`;

interface Props {
  open: boolean;
  setOpen: any;
  organization: Organization;
  project: Project;
}

const BashScript = ({ code }: any) => (
  <CopyBlock
    text={code}
    language="bash"
    showLineNumbers={true}
    theme={github}
    wrapLines={true}
    codeBlock={true}
  />
);

const ScriptModal = ({ open, setOpen, organization, project }: Props) => {
  const [authToken, setAuthToken] = React.useState('');
  const [platform, setPlatform] = React.useState('');
  const [prefix, setPrefix] = React.useState('');
  const [script, setScript] = React.useState('');
  const [localPaths, setLocalPaths] = React.useState<{ [path: string]: string }>({});

  const handleGenerate = () => {
    if (organization && project) {
      setScript(
        generateScript(organization.id, project!.id, authToken, project!.languages, platform, prefix, localPaths!)
      );
    }
  };

  const handleLocalPath = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setLocalPaths(prevState => ({ ...prevState, [name]: value }));
  };

  const handleClose = () => {
    setAuthToken('');
    setPrefix('');
    setScript('');
    setOpen(false);
    setLocalPaths({});
  };

  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg" scrollable="true">
      <ModalHeader toggle={() => setOpen(!open)}>
        Generate script for downloading
    </ModalHeader>
      <ModalBody>
        <div>
          <label style={{ fontSize: '14px' }}>Add a prefix to your translations</label>
          <InputField
            autoComplete="off"
            className="form-control mb-3"
            type="text"
            name="add-prefix"
            value={prefix}
            onChange={(e) => setPrefix(e.currentTarget.value)}
            placeholder={`ex.: "prefixed.exampleTranslation"`}
          />
          <label style={{ fontSize: '14px' }}>Choose platform</label>
          <Select className="form-control mb-3" value={platform} onChange={(e) => setPlatform(e.currentTarget.value)} >
            <option value="" defaultValue="" disabled={true}>Please select</option>
            <option value="ios" >iOS</option>
          </Select>
          <label style={{ fontSize: '14px' }}>Choose api key</label>
          {!organization.apikeys ?
            <div className="mb-3">
              Oops... It looks like you don't have an api key yet. Go to organization settings to generate one.
             </div>
            : (
              <Select
                className="form-control mb-3"
                value={authToken}
                onChange={(e) => setAuthToken(e.currentTarget.value)}
              >
                <option value="" defaultValue="" disabled={true}>Please select</option>
                {organization.apikeys.map((k, i) => <option key={i} value={k} >{k}</option>)}
              </Select>
            )}
        </div>
        <label style={{ fontSize: '14px' }}>Specify local path for each translation file</label>{' '}
        {project!.languages.filter(el => el !== 'key').map((lang, i) => (
          <div key={i}>
            <label style={{ fontSize: '14px' }}>{lang.capitalize()}</label>
            <InputField
              autoComplete="off"
              className="form-control mb-3"
              type="text"
              name={lang}
              value={localPaths[lang] ? localPaths[lang] : ''}
              onChange={(e) => handleLocalPath(e)}
              placeholder={
                i === 0 ? `ex.: "resources/testproject/Base.lproj"`
                  : `ex.: "resources/testproject/${lang.substring(0, 2)}.lproj"`}

            />
          </div>
        ))}
        <Button
          className="small-text btn modal-button"
          onClick={() => handleGenerate()}
        >
          Generate
        </Button>{' '}
        {script && <BashScript code={script} />}
      </ModalBody>
      <ModalFooter>
        <Button
          className="small-text"
          color="link"
          style={{ color: 'black' }}
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScriptModal;