import moment from 'moment';

export function generateScript(
  orgId: string,
  projectId: string,
  authToken: string,
  languages: string[],
  platform: string,
  prefix: string = '',
  localPaths: { [path: string]: string }) {
  console.log(localPaths);
  let script = `
#!/bin/bash

# Created by Localice.io on ${moment().format('l')}

auth_token="${authToken}"
base_url="https://localice.io/api/v2/translations/download/${orgId}/${projectId}"
path_extension="orderDir=asc&prefix=${prefix}"
`;
  languages
    .filter(el => el !== 'key')
    .map(lang => script = script + generateRequestsUrls(lang, platform, localPaths[lang]));
  return script;
}

function generateRequestsUrls(language: string, platform: string, path: string) {
  return `
url_${language}="$base_url?platform=${platform}&lang=${language}&$path_extension"
curl -o ${path}/Localizable.strings --request GET $url_${language} -H "auth_token: $auth_token"
`;
}