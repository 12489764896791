import { Action } from 'redux';

export const ADD_LANGUAGE_TO_PROJECT_SUCCESS = 'ADD_LANGUAGE_TO_PROJECT_SUCCESS';

export interface AddLanguageToProjectSuccessAction extends Action {
  type: typeof ADD_LANGUAGE_TO_PROJECT_SUCCESS;
  language: string;
  isPlural: boolean;
}

export const addLanguageToProjectSuccessAction = (
  language: string,
  isPlural: boolean,
): AddLanguageToProjectSuccessAction => ({
  type: ADD_LANGUAGE_TO_PROJECT_SUCCESS,
  language,
  isPlural
});