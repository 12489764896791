import { Action } from 'redux';

export const CREATE_PROJECT = 'CREATE_PROJECT';

export interface CreateProjectAction extends Action {
  type: typeof CREATE_PROJECT;
}

export const createProjectAction = (
): CreateProjectAction => ({
  type: CREATE_PROJECT,
});