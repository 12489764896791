import * as React from 'react'
import * as _ from 'lodash'
import { debounce } from 'throttle-debounce'
// Redux
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import * as translationsApi from '../../services/translationsApi'
import * as projectApi from '../../services/projectApi'
import * as projectSelector from '../project/projectSelector'
import * as authSelectors from '../auth/authSelector'
import * as orgActions from '../organization/orgActions'
// Models
import { Project } from '../../models/Project'
import store, { RootState } from '../../store'
import { Translation } from '../../models/Translation'
// Components
import InputRow from './translation-components/singular-rows/InputRow'
import Spinner, { CenteredSpinner } from '../ui-components/Spinner'
import RowHeader from './translation-components/RowHeader'
import OrgNavbar from '../common/navigation/OrgNavbar'
import TopRow from './translation-components/TopRow'
import PluralInputRow from './translation-components/plural-rows/PluralInputRow'
// Components (npm)
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import { List } from 'react-virtualized/dist/commonjs/List'
import {
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized/dist/commonjs/CellMeasurer'
import { Dialog, DialogTitle } from '@material-ui/core'
import {
  createMatchSelector,
  RouterState,
  RouterRootState
} from 'connected-react-router'
import SettingsDrawer from '../common/settings/SettingsDrawer'
import { Organization } from '../../models/Organization'
import { params } from 'firebase-functions/v1'
import { Button } from './Button'

interface Props {
  selectedProject: Project | undefined
  projects: Project[] | undefined
  projectTranslations: Translation[] | undefined
  isSelectingProject: boolean
  isDownloadingTranslations: boolean
  keyprefix: string | undefined
  isGettingUser: boolean
  isAddingPluralRow: boolean
  isAddingLanguage: boolean
  isImportingTranslations: boolean
  router: RouterState
  languages: string[] | undefined
  selectedOrganization: Organization | undefined
  organizations: Organization[] | undefined

  removeRow: (projectId: string, translation: Translation) => void
  selectProject: (projectId: string, orgName: string, orgId: string) => void
}

interface State {
  searchString: string
  localPrefix: string
  allTranslations: Translation[]
  filtered: boolean
  searching: boolean
  filteredTranslations: Translation[]
  expansion: boolean
  pluralExpansion: boolean
  displayedSearchString: string
  settingsOpen: boolean
}

const projectMatchSelector = createMatchSelector(
  '/:organization/:orgid/:projectname/:projectid'
)

class Translations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchString: '',
      displayedSearchString: '',
      localPrefix: this.props.keyprefix ? this.props.keyprefix : '',
      allTranslations: this.props.projectTranslations
        ? this.props.projectTranslations
        : [],
      filteredTranslations: [],
      filtered: false,
      searching: false,
      expansion: false,
      pluralExpansion: false,
      settingsOpen: false
    }
    ;(this as any).throttledSearch = debounce(500, this.handleSearch)
  }

  componentDidMount() {
    const state = { router: this.props.router } as RouterRootState
    const projectMatchUrl = projectMatchSelector(state)
    if (projectMatchUrl) {
      const urlParams = projectMatchUrl.params
      if (
        urlParams['projectid'] &&
        urlParams['projectname'] &&
        urlParams['orgid'] &&
        urlParams['organization']
      ) {
        if (this.props.organizations && this.props.organizations.length > 0) {
          store.dispatch({ type: orgActions.SELECT_ORGANIZATION })
          const org = this.props.organizations.find(
            (o) => o.id === urlParams['orgid']
          )
          if (org) {
            store.dispatch({
              type: orgActions.SELECT_ORGANIZATION_SUCCESS,
              organization: org
            })
            this.props.selectProject(urlParams['projectid'], org.name, org.id)
          } else {
            store.dispatch({
              type: orgActions.SELECT_ORGANIZATION_SUCCESS,
              organization: this.props.selectedOrganization!
            })
            this.props.selectProject(
              urlParams['projectid'],
              this.props.selectedOrganization!.name,
              this.props.selectedOrganization!.id
            )
          }
        } else {
          if (!this.props.isGettingUser) {
            store.dispatch({ type: orgActions.SELECT_ORGANIZATION_FAIL })
          }
        }
      }
    }
  }

  cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50
  })

  listRef: any
  setRef = (ref: any) => {
    this.listRef = ref
  }

  toggleExpansion = (
    translation: Translation,
    index: number,
    isPlural: boolean
  ) => {
    if (isPlural) translation.pluralExpanded = !translation.pluralExpanded
    else translation.expanded = !translation.expanded
    this.cache.clear(index, 0)
    this.listRef.recomputeRowHeights(index)
  }

  updatePrefix = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ localPrefix: e.currentTarget.value })

  sortByField = (a: Translation) => {
    var num: number = 1
    a.translations.forEach((translation) => {
      if (
        translation.getValue() === undefined ||
        translation.getValue() === ''
      ) {
        num = -1
      }
    })
    return num
  }

  filterByTag = (tag: string) => {
    let current = this.props.projectTranslations
      ? this.props.projectTranslations
      : []
    if (tag === 'All') {
      this.setState({ filteredTranslations: current, filtered: false })
    } else {
      let filter = current.filter((item: Translation) => item.tag === tag)
      this.setState({ filteredTranslations: filter, filtered: true })
    }
  }

  handleSearchString = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ searchString: e.currentTarget.value }, () => {
      // @ts-ignore
      this.throttledSearch(this.state.searchString)
    })
  }

  handleSearch = (searchStr: string) => {
    let current: Translation[],
      filtered: Translation[] = []
    if (searchStr !== '') {
      const translations = this.props.projectTranslations
      current = translations ? translations : []
      current.forEach((item, i) => {
        item.translations.filter((item2) => {
          if (
            item2.getValue().toUpperCase().includes(searchStr.toUpperCase())
          ) {
            filtered.push(item)
          }
        })
        // @ts-ignore
        filtered = [...new Set(filtered)].sort(this.sortByField)
      })
      this.setState({ filteredTranslations: filtered, filtered: true })
    } else {
      this.setState({ filtered: false })
    }
    this.cache.clearAll()
  }

  rowItemCount = (translation: Translation) => {
    if (translation.expanded) {
      if (translation.comment !== undefined)
        return translation.translations.length + 1
      else return translation.translations.length
    } else if (translation.pluralExpanded) {
      if (translation.pluralcomment !== undefined)
        return translation.translations.length + 2
      else return translation.translations.length + 1
    }
    if (
      translation.pluralTranslations &&
      translation.pluralTranslations.length > 0
    ) {
      return 2
    }
    return 1
  }

  rowHeight = (translations: Translation[], index: number) =>
    this.rowItemCount(translations[index]) * 50

  rowRenderer = (
    translations: Translation[],
    { index, parent, key, style }: any
  ) => {
    const selectedProject = this.props.selectedProject
    const translation = translations[index]
    if (!selectedProject || !translation) return null
    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div key={translations[index].id} style={style}>
          <InputRow
            selectedProject={selectedProject}
            translation={translation}
            toggleExpansion={() =>
              this.toggleExpansion(translation, index, false)
            }
            expanded={translation.expanded!}
            listRef={this.listRef}
          />
          {translation.pluralTranslations &&
            translation.pluralTranslations!.length > 0 &&
            !translation.expanded && (
              <PluralInputRow
                selectedProject={selectedProject}
                translation={translation}
                toggleExpansion={() =>
                  this.toggleExpansion(translation, index, true)
                }
                expanded={translation.pluralExpanded!}
                listRef={this.listRef}
              />
            )}
        </div>
      </CellMeasurer>
    )
  }

  renderTranslations = () => {
    this.cache.clearAll()
    if (this.props.isSelectingProject) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      )
    } else {
      const translations = this.props.projectTranslations
        ? this.props.projectTranslations.sort()
        : []
      const rows = this.state.filtered
        ? this.state.filteredTranslations.sort()
        : translations
      return (
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={this.setRef}
              style={{ outline: 'none' }}
              rowCount={rows.length}
              width={width}
              height={height}
              rowHeight={({ index }) => this.rowHeight(rows, index)}
              rowRenderer={({ index, parent, key, style }) => {
                return this.rowRenderer(rows, { index, parent, key, style })
              }}
              overscanRowCount={20}
            />
          )}
        </AutoSizer>
      )
    }
  }

  render() {
    if (
      this.props.isAddingPluralRow ||
      this.props.isAddingLanguage ||
      this.props.isImportingTranslations
    ) {
      return <CenteredSpinner title="" />
    }
    return (
      <React.Fragment>
        <OrgNavbar
          settingsOpen={this.state.settingsOpen}
          setSettingsOpen={() =>
            this.setState({ settingsOpen: !this.state.settingsOpen })
          }
        />
        <div
          id="translations-page"
          className="container-fluid text-center px-0 container-color"
          style={{ margin: 0, overflow: 'hidden' }}
        >
          <TopRow handleSearch={this.handleSearchString} />
          <RowHeader languages={this.props.languages} />
          <div style={{ height: '70vh' }}>{this.renderTranslations()}</div>
          <Dialog open={this.props.isDownloadingTranslations}>
            <DialogTitle>
              Downloading files <br />
              <div className="text-center">
                <Spinner />
              </div>
            </DialogTitle>
          </Dialog>
        </div>
        <SettingsDrawer
          selectedOrganization={this.props.selectedOrganization}
          organizations={this.props.organizations}
          setSettingsOpen={() =>
            this.setState({ settingsOpen: !this.state.settingsOpen })
          }
          settingsOpen={this.state.settingsOpen}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: authSelectors.user(state),
    isSelectingProject: projectSelector.isSelectingProject(state),
    selectedProject: projectSelector.selectedProject(state),
    projectTranslations: projectSelector.projectTranslations(state),
    isDownloadingTranslations: projectSelector.isDownloadingTranslations(state),
    keyprefix: projectSelector.keyPrefix(state),
    isGettingUser: authSelectors.isGettingUser(state),
    isAddingPluralRow: projectSelector.isAddingPluralRow(state),
    isAddingLanguage: projectSelector.isAddingLanguage(state),
    isImportingTranslations: projectSelector.isImportingTranslations(state),
    languages: projectSelector.languages(state),
    router: authSelectors.router(state),
    projects: projectSelector.projects(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      removeRow: (projectId: string, translation: Translation) =>
        translationsApi.removeRow(projectId, translation),
      selectProject: (projectId: string, orgName: string, orgId: string) =>
        projectApi.selectProject(projectId, orgName, orgId)
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Translations)
