import * as React from 'react';
import { connect } from 'react-redux';
import * as translationApi from '../../../services/translationsApi';
import styled from 'styled-components';
import EditLogModal from '../../translation/translation-components/EditLogModal';
import Badge from '@material-ui/core/Badge';
import { Fab } from '@material-ui/core';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FabProps } from '@material-ui/core/Fab';

interface StyledFabProps extends FabProps {
  hascomment: string;
}

const StyledFab = styled(Fab)`
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
` as React.ComponentType<FabProps>;

const StyledCommentFab = styled(Fab)`
  background-color: ${(props: StyledFabProps) =>
    props.hascomment === 'true' ? '#f5a623 !important' : '#ffffff !important'};
  color: ${(props: StyledFabProps) =>
    props.hascomment === 'true' ? '#ffffff !important' : '#000000 !important'};
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
` as React.ComponentType<StyledFabProps>;

interface Props {
  translationId: string;
  plural: boolean;
  hasComment: boolean;
  expanded: boolean;
  expand: any;
  emptyFieldCount?: number;
  addPluralRow?: any;
  removeRow?: any;
  selectedProjectId: string;
  addComment?: any;
  hasPlural: boolean;
}

const RowTools = (props: Props) => {
  const [showLogs, setShowLogs] = React.useState(false);
  const [logs, setLogs] = React.useState<any[]>([]);
  const [settings, showSettings] = React.useState(false);

  const showTranslationLogs = async () => {
    await translationApi.getTranslationLogs(props.translationId)
      .then((result) => {
        setLogs(result);
        setShowLogs(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="row pl-3 justify-content-start">
      <div className="pr-2">
        <StyledFab
          onClick={props.expand}
          color="primary"
          size="small"
        >
          <Badge
            style={{ marginTop: '5px' }}
            badgeContent={props.emptyFieldCount ? props.emptyFieldCount : 0}
            color="primary"
          >
            <i className="material-icons">
              {props.expanded ? 'expand_less' : 'expand_more'}
            </i>
          </Badge>
        </StyledFab>
      </div>
      <div className="pr-2">
        <StyledCommentFab
          color="primary"
          hascomment={props.hasComment ? 'true' : 'false'}
          onClick={props.expand}
          size="small"
        >
          <i className="material-icons">
            message
          </i>
        </StyledCommentFab>
      </div>
      {!props.plural &&
        <Dropdown
          className="pr-2"
          isOpen={settings}
          toggle={() => showSettings(!settings)}
        >
          <DropdownToggle caret={false} tag={'a'}>
            <StyledFab
              onClick={() => showSettings(!settings)}
              color="primary"
              size="small"
            >
              <i className="material-icons">
                settings
              </i>
            </StyledFab>
          </DropdownToggle>
          <DropdownMenu right={true} className="dropdown-menu-arrow">
            <DropdownItem
              onClick={props.removeRow}
            >
              Remove row
            </DropdownItem>
            <DropdownItem
              onClick={props.addPluralRow}
              disabled={props.hasPlural}
            >
              Add plural row
            </DropdownItem>
            <DropdownItem
              onClick={() => showTranslationLogs()}
            >
              Show history
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }
      <EditLogModal open={showLogs} setOpen={setShowLogs} logs={logs} />
    </div>
  );
};

export default connect(null, null)(RowTools);