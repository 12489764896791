import { Action } from 'redux';
import { Translation } from '../../../models/Translation';

export const IMPORT_TRANSLATIONS_SUCCESS = 'IMPORT_TRANSLATIONS_SUCCESS';

export interface ImportTranslationsSuccessAction extends Action {
  type: typeof IMPORT_TRANSLATIONS_SUCCESS;
  translations: Translation[];
}

export const importTranslationsSuccessAction = (
  translations: Translation[]
): ImportTranslationsSuccessAction => ({
  type: IMPORT_TRANSLATIONS_SUCCESS,
  translations
});