import { Action } from 'redux';

export const AUTO_TRANSLATE_FAIL = 'AUTO_TRANSLATE_FAIL';

export interface AutoTranslateFailAction extends Action {
  type: typeof AUTO_TRANSLATE_FAIL;
  field: string;
  loadingIdentifier: string;
}

export const autoTranslateFailAction = (
  field: string,
  loadingIdentifier: string
): AutoTranslateFailAction => ({
  type: AUTO_TRANSLATE_FAIL,
  field,
  loadingIdentifier
});