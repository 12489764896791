import { Action } from 'redux';

export const SELECT_ORGANIZATION_FAIL = 'SELECT_ORGANIZATION_FAIL';

export interface SelectOrganizationFailAction extends Action {
  type: typeof SELECT_ORGANIZATION_FAIL;
}

export const selectOrganizationFailAction = (
): SelectOrganizationFailAction => ({
  type: SELECT_ORGANIZATION_FAIL,
});