import { Action } from 'redux';

export const DOWNLOAD_TRANSLATIONS_SUCCESS = 'DOWNLOAD_TRANSLATIONS_SUCCESS';

export interface DownloadTranslationsSuccessAction extends Action {
  type: typeof DOWNLOAD_TRANSLATIONS_SUCCESS;
  projectId: string;
}

export const downloadTranslationsSuccessAction = (
  projectId: string,
): DownloadTranslationsSuccessAction => ({
  type: DOWNLOAD_TRANSLATIONS_SUCCESS,
  projectId
});