import { Action } from 'redux';
export const ADD_USER_TO_ORG_FAIL = 'ADD_USER_TO_ORG_FAIL';

export interface AddUserToOrgFailAction extends Action {
  type: typeof ADD_USER_TO_ORG_FAIL;
}

export const addUserToOrgFailAction = (
): AddUserToOrgFailAction => ({
  type: ADD_USER_TO_ORG_FAIL,
});