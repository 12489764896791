import { Action } from 'redux';
import { Organization } from '../../../models/Organization';

export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';

export interface GetOrganizationsSuccessAction extends Action {
  type: typeof GET_ORGANIZATIONS_SUCCESS;
  organizations: Organization[];
}

export const getOrganizationsSuccessAction = (
  organizations: Organization[]
): GetOrganizationsSuccessAction => ({
  type: GET_ORGANIZATIONS_SUCCESS,
  organizations
});