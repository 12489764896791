import { Dispatch } from 'redux';
import { dbRef, history } from '../store';
import firebase from 'firebase/app';
import { toastr } from 'react-redux-toastr';
import * as projectActions from '../modules/project/projectActions';
import * as FSROUTES from '../constants/firestoreRoutes';
import SortableTranslation from '../models/SortableTranslation';
import { Project } from '../models/Project';
import { Organization } from '../models/Organization';
import { Translation } from '../models/Translation';
import { User } from '../models/User';
import { KeysNotOfInterest } from '../models/KeysNotOfInterest';

function getUserProjects(userId: string) {
  return new Promise<Array<string>>((resolve, reject) => {
    dbRef
      .collection(FSROUTES.USERS)
      .doc(userId)
      .get()
      .then((snapshot) => {
        resolve(Object.keys(snapshot.get('project_roles')));
      })
      .catch((error) => {
        reject(new Error('No projects'));
      });
  });
}

export function getProjects(organization: Organization, userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectActions.GET_PROJECTS });
    const projectsRef = dbRef.collection(FSROUTES.PROJECTS);

    let projects: Project[] = [];

    const userProjects = await getUserProjects(userId);

    if (!userProjects) {
      dispatch({ type: projectActions.GET_PROJECTS_FAIL });
    }

    for (const project of userProjects) {
      await projectsRef
        .where('organization_id', '==', organization.id)
        .where(firebase.firestore.FieldPath.documentId(), '==', project)
        .get()
        .then(documentSnapshot => {
          const projectDoc = documentSnapshot.docs[0];
          if (projectDoc) {
            projects.push({
              id: projectDoc.id,
              orgId: organization.id,
              name: projectDoc.get('name'),
              translations: [],
              languages: projectDoc.get('languages'),
              users: projectDoc.get('users'),
              created: projectDoc.get('created')
            } as Project);
          }
        });
    }

    dispatch({ type: projectActions.GET_PROJECTS_SUCCESS, projects });
  };
}

const sortByField = (a: Translation) => {
  var num: number = 1;
  a.translations.forEach(translation => {
    if (translation.getValue() === undefined || translation.getValue() === '') num = -1;
  });
  return num;
};

export function selectProject(projectId: string, orgName: string, orgId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: projectActions.SELECT_PROJECT });
    let allTranslations: Translation[] = [];
    const projectRef = dbRef.collection(FSROUTES.PROJECTS).doc(projectId);
    projectRef.collection(FSROUTES.TRANSLATIONS).orderBy('key', 'asc').get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let translations: Array<SortableTranslation> = [];
          let pluralTranslation: Array<SortableTranslation> = [];
          let data = doc.data();
          for (const key of Object.keys(data)) {
            // Skip all the "fake" translation keys, that is a part of the database entry.
            if (Object.keys(KeysNotOfInterest).includes(key)) continue;
            if (key.startsWith('plural')) pluralTranslation.push(new SortableTranslation(key, data[key]));
            else translations.push(new SortableTranslation(key, data[key]));
          }
          var sorted = translations.sort().sort((x, y) => {
            if (x.getKey() === 'key') { return -1; }
            if (y.getKey() === 'key') { return 1; }
            return 0;
          });
          var translation: Translation = {
            id: doc.id,
            translations: sorted,
            pluralTranslations: pluralTranslation,
            tag: doc.get('tag'),
            comment: doc.get('comment'),
            pluralcomment: doc.get('pluralcomment'),
          };
          allTranslations.push(translation);
        });
        let project = {} as Project;
        projectRef.get()
          .then(projectDoc => {
            project.id = projectDoc.id;
            project.name = projectDoc.get('name');
            project.orgId = projectDoc.get('organization_id');
            project.users = projectDoc.get('users');
            project.languages = projectDoc.get('languages');
            project.translations = allTranslations.sort(sortByField);
            project.keyprefix = projectDoc.get('key_prefix');
          })
          .then(() => {
            dispatch({ type: projectActions.SELECT_PROJECT_SUCCESS, project });
            history.push(`/${orgName}/${orgId}/${project.name}/${project.id}`);
          })
          .catch((err) => {
            dispatch({ type: projectActions.SELECT_PROJECT_FAIL, errorMessage: err });
          });
      })
      .catch((err) => {
        dispatch({ type: projectActions.SELECT_PROJECT_FAIL, errorMessage: err });
      });
  };
}

export function createProject(name: string, org: Organization, user: User, languages: string[]) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectActions.CREATE_PROJECT });
    // Firestore references
    const newProjectRef = dbRef.collection(FSROUTES.PROJECTS).doc();
    const userRef = dbRef.collection(FSROUTES.USERS).doc(user.id);
    const orgRef = dbRef.collection(FSROUTES.ORGANIZATIONS).doc(org.id);
    // New state object to replace current state
    let userProjects = {};
    let newProject: Project = {} as Project;
    // Run transaction to ensure atomicity of the database operations
    await dbRef.runTransaction(async (transaction: firebase.firestore.Transaction) => {
      return await transaction.get(userRef).then(async (doc) => {
        const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        await newProjectRef.set({
          name,
          languages,
          organization_id: org.id,
          tags: [],
          users: { [user.email]: 'owner' },
          created: timestamp
        });
        await transaction.update(orgRef, {
          projects: firebase.firestore.FieldValue.arrayUnion(newProjectRef.id)
        });
        userProjects = doc.get('project_roles');
        userProjects[newProjectRef.id] = 'owner';
        // Add the 'owner' role to the user who created the org.
        await transaction.set(userRef, {
          project_roles: userProjects,
        }, { merge: true });
        newProject = {
          id: newProjectRef.id,
          orgId: org.id,
          name,
          translations: [],
          languages,
          tags: [],
          users: [{ [user.email]: 'owner' }],
          created: timestamp
        };
      }).catch(error => console.log(error));
    })
      .then(() => {
        dispatch({ type: projectActions.CREATE_PROJECT_SUCCESS, projectId: newProjectRef.id, project: newProject });
        toastr.success('Success!', `Project created, ${name}`);
      })
      .catch((error: any) => {
        console.log(error);
        dispatch({ type: projectActions.CREATE_PROJECT_FAIL });
        toastr.error('Failure!', `Error creating project. Please try again.`);
      });
  };
}