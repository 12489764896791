import { Action } from 'redux';
import { Project } from '../../../models/Project';

export const SELECT_PROJECT_SUCCESS = 'SELECT_PROJECT_SUCCESS';

export interface SelectProjectSuccessAction extends Action {
  type: typeof SELECT_PROJECT_SUCCESS;
  project: Project;
}

export const selectProjectSuccessAction = (
  project: Project,
): SelectProjectSuccessAction => ({
  type: SELECT_PROJECT_SUCCESS,
  project
});