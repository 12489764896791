import { Action } from 'redux';

export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';

export interface GetProjectsFailAction extends Action {
  type: typeof GET_PROJECTS_FAIL;
}

export const getProjectsFailAction = (
): GetProjectsFailAction => ({
  type: GET_PROJECTS_FAIL,
});