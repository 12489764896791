import { Action } from 'redux';

export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';

export interface GetOrganizationsFailAction extends Action {
  type: typeof GET_ORGANIZATIONS_FAIL;
  errorMessage: string;
}

export const getOrganizationsFailAction = (
  errorMessage: string,
): GetOrganizationsFailAction => ({
  type: GET_ORGANIZATIONS_FAIL,
  errorMessage
});