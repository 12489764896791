import { Action } from 'redux';
import { Translation } from '../../../../models/Translation';

export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS';

export interface GetTranslationsSuccessAction extends Action {
  type: typeof GET_TRANSLATIONS_SUCCESS;
  projectId: string;
  translations: Translation[];
}

export const getTranslationsSuccessAction = (
  projectId: string,
  translations: Translation[],
): GetTranslationsSuccessAction => ({
  type: GET_TRANSLATIONS_SUCCESS,
  projectId,
  translations
});