import * as React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as ROUTES from '../../constants/routes';
import * as authApi from '../../services/authApi';
import * as orgApi from '../../services/orgApi';
import * as authSelectors from '../auth/authSelector';
import * as orgSelectors from '../organization/orgSelector';
// Models
import { RootState } from '../../store';
import { Organization as OrganizationModel } from '../../models/Organization';
import { CommonComponentProps } from '../../models/CommonProps';
import { User } from '../../models/User';
// Components
import SignUp from '../auth/SignUp';
import { CenteredSpinner } from '../ui-components/Spinner';
import Translations from '../translation/Translations';
// import Settings from './settings/Settings';
import SignIn from '../auth/SignIn';
import LandingPage from './landingpage/LandingPage';
import Organization from '../organization/Organization';
import CreateOrganization from '../organization/CreateOrganization';
import ToSmallWindow from './ToSmallWindow';

interface Props extends CommonComponentProps<{ orgid: string }> {
  firebaseUserId: string | undefined;
  organizations: OrganizationModel[] | undefined;
  selectedOrganization: OrganizationModel | undefined;
  getUser: () => void;
  user: User | undefined;
  isGettingUser: boolean;
  isEmpty: boolean;
  isLoaded: boolean;
  isLoadingOrganizations: boolean;
  getOrganizations: (userId: string) => void;
}

interface State {
  windowWidth: number;
  settingsOpen: boolean;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      settingsOpen: true
    };
  }
  isLoading() {
    return !this.props.isLoaded && this.props.isGettingUser;
  }

  isReadyAndAuthenticated() {
    return !this.props.isEmpty && this.props.user && this.props.isLoaded;
  }

  isAuthenticated() {
    return !!(!this.props.isEmpty && this.props.firebaseUserId);
  }

  updateDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.user !== this.props.user && this.props.user) {
      this.props.getOrganizations(this.props.user.id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillMount() {
    this.props.getUser();
  }

  render() {
    if (this.isLoading() || this.props.isLoadingOrganizations) {
      return (
        <CenteredSpinner title={'Loading organizations...'} />
      );
    }
    if (this.state.windowWidth < 1025) {
      return <ToSmallWindow />;
    }
    return (
      <div>
        <Switch>
          <Route
            exact={true}
            path={ROUTES.HOME}
            component={() => (
              <LandingPage
                user={this.props.user}
                organizations={this.props.organizations}
                selectedOrganization={this.props.selectedOrganization}
              />
            )}
          />
          <Route
            exact={true}
            path={ROUTES.SIGN_IN}
            component={(routeProps: any) => (<SignIn {...routeProps} userId={this.props.firebaseUserId} />)}
          />
          <Route
            exact={true}
            path={ROUTES.SIGN_UP}
            component={() => <SignUp userId={this.props.firebaseUserId} />}
          />
          <Route
            exact={true}
            path="/:organization/:orgid"
            render={() => (
              <Organization
                organizations={this.props.organizations}
                selectedOrganization={this.props.selectedOrganization}
                userId={this.props.firebaseUserId}
                isLoadingOrganizations={this.props.isLoadingOrganizations}
                user={this.props.user}
              />
            )}
          />
          <Route
            exact={true}
            path="/create-organization"
            component={(routeProps: any) => (
              <CreateOrganization
                {...routeProps}
                user={this.props.user}
                organizations={this.props.organizations}
                selectedOrganization={this.props.selectedOrganization}
              />
            )}
          />
          <Route
            exact={true}
            path="/:organization/:orgid/:project/:projectid"
            render={() => (
              <Translations
                organizations={this.props.organizations}
                selectedOrganization={this.props.selectedOrganization}
              />
            )}
          />
          <Route
            path=""
            render={() => <Redirect to={ROUTES.HOME} />}
          />
        </Switch>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={true}
          closeOnToastrClick={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    firebaseUserId: authSelectors.firebaseUserId(state),
    isGettingUser: authSelectors.isGettingUser(state),
    isEmpty: state.firebase.auth.isEmpty,
    isLoaded: state.firebase.auth.isLoaded,
    isLoadingOrganizations: orgSelectors.isLoadingOrganizations(state),
    user: authSelectors.user(state),
    organizations: orgSelectors.organizations(state),
    selectedOrganization: orgSelectors.selectedOrganization(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getUser: () => authApi.getUser(),
    getOrganizations: (userId: string) => orgApi.getOrgs(userId)
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
