import { Action } from 'redux';
import { Translation } from '../../../../models/Translation';

export const UPDATE_INPUT = 'UPDATE_INPUT';

export interface UpdateInputAction extends Action {
  type: typeof UPDATE_INPUT;
  translation: Translation;
  field: string;
  value: string;
  plural: boolean;
  isComment: boolean;
}

export const updateInputAction = (
  translation: Translation,
  field: string,
  value: string,
  plural: boolean,
  isComment: boolean,
): UpdateInputAction => ({
  type: UPDATE_INPUT,
  translation,
  field,
  value,
  plural,
  isComment
});