import { Action } from 'redux';
import { Project } from '../../../models/Project';

export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';

export interface CreateProjectSuccessAction extends Action {
  type: typeof CREATE_PROJECT_SUCCESS;
  projectId: string;
  project: Project;
}

export const createProjectSuccessAction = (
  projectId: string,
  project: Project
): CreateProjectSuccessAction => ({
  type: CREATE_PROJECT_SUCCESS,
  projectId,
  project
});