import * as FileSaver from 'file-saver'
import { toastr } from 'react-redux-toastr'
import JSZip from 'jszip'
import * as projectActions from '../modules/project/projectActions'
import * as translationActions from '../modules/translation/translationsActions'
import * as projectSelectors from '../modules/project/projectSelector'
import { Project } from '../models/Project'
import { Dispatch } from 'redux'
import {
  handlePluralKeyFormat,
  handleKeyFormat,
  firstNotNulTrans,
  addInitialFileFormatting,
  addSwiftExtension,
  addFinalFileFormatting,
  reduceKey,
  returnWebExtension,
  webPluralFormat,
  getPluralValue
} from '../util/downloadUtil'
import { KeysNotOfInterest } from '../models/KeysNotOfInterest'
import SortableTranslation from '../models/SortableTranslation'
import store, { dbRef } from '../store'
import * as FSROUTES from '../constants/firestoreRoutes'
import parser from 'xml2js'
import { Translation } from '../models/Translation'

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const placeholdersToCheckFor = ['%s', '$@', '%d', '%D', '%f', '%@']
const checker = (value: string) =>
  placeholdersToCheckFor.some((element) => value.includes(element))

const replacePlaceholdersWeb = (value: string) => {
  let newValue = value
  placeholdersToCheckFor.forEach((p) => {
    const regex = new RegExp(p, 'g')
    newValue = newValue.replace(regex, '%s')
  })
  return newValue
}

export function downloadWeb(
  project: Project,
  platform: string,
  keyprefix: string = ''
) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectActions.DOWNLOAD_TRANSLATIONS })
    let zip = new JSZip()
    let allTranslations = {}
    const webExtension = returnWebExtension(project, keyprefix)
    for (const language of project.languages) {
      // Since key is in the list of languages, we need to skip this
      if (language === 'key') continue
      // Initialize the file to which we write the translations
      // All translations in one object
      project.translations.forEach((translation) => {
        // Get the key/value pair of a SortableTranslation
        let key = translation.translations
          .find((el) => el.getKey() === 'key')!
          .getValue()
        if (Object.keys(KeysNotOfInterest).includes(key)) return
        console.log(translation.translations)
        console.log(language)
        let value = translation.translations
          .find((el) => el.getKey() === language)!
          .getValue()
        // If the translation has not yet been translated, or for some reason there is no emtry ->
        // Just take the first value not being null or undefined
        if (value === undefined || value === '')
          value = firstNotNulTrans(translation.translations)
        // Replacing, so all placeholsers are %s
        const hasPlaceholder = checker(value)
        if (hasPlaceholder) {
          value = replacePlaceholdersWeb(value)
        }
        // Add key/value pair to object
        allTranslations[`${keyprefix}${key}`] = value
        // Should we handle plural translations?
        if (
          translation.pluralTranslations !== undefined &&
          translation.pluralTranslations.length > 0
        ) {
          let valuePlural = getPluralValue(translation, language)
          const pluralKey = webPluralFormat(key, keyprefix)
          // Replacing, so all placeholsers are %s
          const hasPlaceholder = checker(valuePlural)
          if (hasPlaceholder) {
            valuePlural = replacePlaceholdersWeb(valuePlural)
          }
          allTranslations[pluralKey] = valuePlural
        }
      })
      let formattedTranslations: string = ''
      try {
        formattedTranslations = JSON.stringify(allTranslations)
        if (formattedTranslations) {
          await zip.file(
            `${platform}-translation-${language}.json`,
            formattedTranslations
          )
        }
      } catch (error) {
        dispatch({ type: projectActions.DOWNLOAD_TRANSLATIONS_FAIL })
        toastr.error(
          'Error',
          'Error downloading translations. Please check formatting of keys.'
        )
        return
      }
    }
    await zip.file('translationKeys.ts', webExtension)
    generateZip(zip, platform)
      .then(() =>
        dispatch({
          type: projectActions.DOWNLOAD_TRANSLATIONS_SUCCESS,
          projectId: project.id
        })
      )
      .catch(() =>
        dispatch({ type: projectActions.DOWNLOAD_TRANSLATIONS_FAIL })
      )
  }
}

export function downloadTranslations(
  project: Project,
  platform: string,
  keyprefix: string = ''
) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectActions.DOWNLOAD_TRANSLATIONS })
    let zip = new JSZip()
    let swiftExtensionFile = `import Foundation

struct Localice {
`
    let languageCounter = 0
    for (const language of project.languages) {
      // Since key is in the list of languages, we need to skip this
      if (language === 'key') continue
      // Initialize the file to which we write the translations
      let filetodownload: string = ''
      // If android we add XML specific file formatting
      filetodownload = addInitialFileFormatting(
        platform,
        language,
        filetodownload
      )
      project.translations.forEach((translation, i) => {
        const isLastTranslation = project.translations.length - 1 === i
        // Get the key/value pair of a SortableTranslation
        let key = translation.translations
          .find((el) => el.getKey() === 'key')!
          .getValue()
        if (Object.keys(KeysNotOfInterest).includes(key)) return
        let value = translation.translations
          .find((el) => el.getKey() === language)!
          .getValue()
        // If the translation has not yet been translated, or for some reason there is no emtry ->
        // Just take the first value not being null or undefined
        if (value === undefined || value === '')
          value = firstNotNulTrans(translation.translations)
        // Check if we need to handle a plural translation
        if (
          translation.pluralTranslations !== undefined &&
          translation.pluralTranslations.length > 0
        ) {
          // If this is the case, we fetch the value and make the null check again
          let trans = translation.pluralTranslations!.find(
            (el) => el.getKey() === `plural${language}`
          )
          let valuePlural =
            !trans || trans.getValue() === ''
              ? firstNotNulTrans(translation.translations)
              : trans.getValue()
          filetodownload += handlePluralKeyFormat(
            platform,
            key,
            value,
            valuePlural,
            keyprefix,
            isLastTranslation
          )
          if (platform === 'ios' && languageCounter === 1) {
            let splitted = key.split('.')
            let reducedKey = splitted[splitted.length - 1]
            if (checker(value)) {
              /* tslint:disable */
              swiftExtensionFile += `    static func ${reducedKey}(_ args: [CVarArg]) -> String { return "${keyprefix}${key}.single".localized(args) };\n`
              swiftExtensionFile += `    static func ${reducedKey}Plural(_ args: [CVarArg]) -> String { return "${keyprefix}${key}.plural".localized(args) };\n`
            } else {
              swiftExtensionFile += `    static func ${reducedKey}() -> String { return "${keyprefix}${key}".localized() };\n`
              swiftExtensionFile += `    static func ${reducedKey}Plural() -> String { return "${keyprefix}${key}".localized() };\n`
              /* tslint:enable */
            }
          }
        } else {
          filetodownload += handleKeyFormat(
            platform,
            key,
            value,
            keyprefix,
            isLastTranslation
          )
          if (platform === 'ios' && languageCounter === 1) {
            let reducedKey = reduceKey(key)
            if (checker(value)) {
              /* tslint:disable */
              swiftExtensionFile += `    static func ${reducedKey}(_ args: [CVarArg]) -> String { return "${keyprefix}${key}".localized(args) };\n`
            } else {
              swiftExtensionFile += `    static func ${reducedKey}() -> String { return "${keyprefix}${key}".localized() };\n`
              /* tslint:enable */
            }
          }
        }
      })
      filetodownload = addFinalFileFormatting(filetodownload, platform)
      if (platform === 'android') {
        await zip.file(
          `${platform}-translation-${language}.txt`,
          filetodownload
        )
      } else if (platform === 'ios') {
        await zip.file(
          `${platform}-translation-${language}.strings`,
          filetodownload
        )
      } else {
        await zip.file(
          `${platform}-translation-${language}.dart`,
          filetodownload
        )
      }
      languageCounter += 1
    }
    if (platform === 'ios') {
      swiftExtensionFile += addSwiftExtension()
      await zip.file(`Localice.swift`, swiftExtensionFile)
    }
    // Lastly generate the zip file
    generateZip(zip, platform)
      .then(() =>
        dispatch({
          type: projectActions.DOWNLOAD_TRANSLATIONS_SUCCESS,
          projectId: project.id
        })
      )
      .catch(() =>
        dispatch({ type: projectActions.DOWNLOAD_TRANSLATIONS_FAIL })
      )
  }
}

// Generate zip file
async function generateZip(zip: any, platform: string) {
  await zip
    .generateAsync({ type: 'blob' })
    .then((blob: Blob) =>
      FileSaver.saveAs(blob, `${platform}-translations.zip`)
    )
}

export function handleImportGeneric(
  projectId: string,
  language: string,
  languages: string[],
  importedTranslations: Array<SortableTranslation>,
  dispatch: Dispatch
) {
  // Initialize batch write to firestore, such that we ensure atomicity of our importing
  const dbTranslations = dbRef
    .collection(FSROUTES.PROJECTS)
    .doc(projectId)
    .collection(FSROUTES.TRANSLATIONS)
  const otherLanguages: string[] = languages.filter(
    (el) => el !== language && el !== 'key'
  )
  const projectTranslations = projectSelectors.selectedProject(store.getState())
  // Run through all the imported translations and add/update to firestore
  const splittedArr = []
  while (importedTranslations.length) {
    splittedArr.push(importedTranslations.splice(0, 50))
  }
  for (const trans of splittedArr) {
    let batchWrite = dbRef.batch()
    Promise.all(
      trans.map((translation) => {
        const k = translation.getKey() as string
        const v = translation.getValue() as string
        return (
          dbTranslations
            .where('key', '==', translation.getKey())
            .get()
            .then((res) => {
              // Construct new Translation for state
              // Try to update doc first and add imported entry to translation
              batchWrite.update(dbTranslations.doc(res.docs[0].id), {
                key: k,
                [language]: v
              })
              const t =
                projectTranslations &&
                projectTranslations.translations.find(
                  (el) => el.id === res.docs[0].id
                )
              if (!t) return
              const index = t.translations.findIndex(
                (el) => el.getKey() === language
              )
              if (index) {
                t.translations[index] = new SortableTranslation(language, v)
              }
            })
            // If the update failed, we create new doc
            .catch((err) => {
              // Create firestoreRef
              const newDocRef = dbTranslations.doc()
              // Construct new Translation for state
              const t: Translation = {
                id: newDocRef.id,
                translations: [],
                pluralTranslations: []
              }
              batchWrite.set(newDocRef, {
                key: k,
                [language]: v
              })
              t.translations.push(new SortableTranslation('key', k))
              t.translations.push(new SortableTranslation(language, v))
              otherLanguages.forEach((l) => {
                batchWrite.update(dbTranslations.doc(newDocRef.id), {
                  [l]: ''
                })
                t.translations.push(new SortableTranslation(l, ''))
              })
              projectTranslations!.translations.push(t)
            })
        )
      })
    )
      .then(() => {
        batchWrite.commit()
      })
      .catch((error) => {
        dispatch({ type: translationActions.IMPORT_TRANSLATIONS_FAIL })
      })
  }
  dispatch({
    type: translationActions.IMPORT_TRANSLATIONS_SUCCESS,
    translations: projectTranslations
  })
}

export function parseDotStringsFormat(
  inputFile: File,
  language: string,
  languages: string[],
  projectId: string
) {
  // Regex to find keys in input file
  const regexToFindKeyValue = new RegExp(/\"(.*)\"[ ]*=[ ]*\"(.*)\";/gm)
  return (dispatch: Dispatch) => {
    dispatch({ type: translationActions.IMPORT_TRANSLATIONS })
    // Initialize filereader
    var fileReader = new FileReader()
    fileReader.onload = async () => {
      var content = fileReader.result as string
      // Split at line breaks
      var text = content.split(/[\r\n]+/g)
      // Initialize map of translations to store key/value pairs
      var map: Array<SortableTranslation> = []
      for (var i = 0; i < text.length; i++) {
        var key = text[i].split(regexToFindKeyValue)[1]
        if (
          text[i].split(regexToFindKeyValue)[1] !== undefined &&
          !key.startsWith('//')
        ) {
          map.push(
            new SortableTranslation(
              text[i].split(regexToFindKeyValue)[1],
              text[i].split(regexToFindKeyValue)[2]
            )
          )
        }
      }
      handleImportGeneric(projectId, language, languages, map, dispatch)
    }
    fileReader.readAsText(inputFile, 'UTF-8')
  }
}

export function parseXmlFormat(
  inputFile: File,
  language: string,
  languages: string[],
  projectId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({ type: translationActions.IMPORT_TRANSLATIONS })
    // Initialize filereader
    var fileReader = new FileReader()
    fileReader.onload = async () => {
      const content = fileReader.result as string
      var xmlparser = new parser.Parser({ attrkey: 'key', charkey: 'value' })
      // Split at line breaks
      var map: Array<SortableTranslation> = []
      xmlparser.parseString(content, (err: any, result: any) => {
        var test = result.resources.string
        for (var i = 0; i < test.length; i++) {
          const key = (
            Object.values(test[i].key)[0] ? Object.values(test[i].key)[0] : ' '
          ) as string
          const value = test[i].value ? test[i].value : ' '
          map.push(new SortableTranslation(key, value))
        }
      })
      // Initialize map of translations to store key/value pairs
      handleImportGeneric(projectId, language, languages, map, dispatch)
    }
    fileReader.readAsText(inputFile, 'UTF-8')
  }
}
