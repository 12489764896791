export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

export const stagingFirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_STAGING,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_STAGING,
  databaseURL: process.env.REACT_APP_DATABASE_URL_STAGING,
  projectId: process.env.REACT_APP_PROJECT_ID_STAGING,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_STAGING,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_STAGING,
  appId: process.env.REACT_APP_APP_ID
};