import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as projectApi from '../../services/projectApi';
import * as orgApi from '../../services/orgApi';
import * as projectSelector from '../project/projectSelector';
import * as orgSelector from './orgSelector';
import * as authSelector from '../auth/authSelector';
import * as authApi from '../../services/authApi';
import * as orgActions from '../../modules/organization/orgActions';
// Models
import { Organization as OrganizationModel } from '../../models/Organization';
import { Project } from '../../models/Project';
import store, { RootState, authRef } from '../../store';
import { User } from '../../models/User';
import { projectCardColors } from '../../constants/colors';
// UI Components
import OrgNavbar from '../common/navigation/OrgNavbar';
import Spinner, { CenteredSpinner } from '../ui-components/Spinner';
// UI Components (npm)
import AddIcon from '@material-ui/icons/Add';
import { Dialog, DialogTitle, Fab } from '@material-ui/core/';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CreateUpdateOrganizations } from '../../models/Roles';
import { RouterRootState, createMatchSelector, RouterState } from 'connected-react-router';
import { GeneralRowButton } from '../translation/translation-components/TopRow';
import SettingsDrawer from '../common/settings/SettingsDrawer';

const CardContainer = styled.div`
  background-color: ${(props: { bgColor: string }) => props.bgColor};
  height: 100px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
`;

interface Props {
  userId: string | undefined;
  user: User | undefined;
  userRole: string;
  isGettingUser: boolean;

  projects: Project[] | undefined;
  isLoadingProjects: boolean;

  organizations: OrganizationModel[] | undefined;
  isSelectingOrganization: boolean;

  isSelectingProject: boolean;
  isLoadingOrganizations: boolean;
  selectedOrganization: OrganizationModel | undefined;
  isCreatingOrganization: boolean;
  getProjects: (organization: OrganizationModel, userId: string) => void;
  createProject: (projectName: string, org: OrganizationModel, user: User, languages: string[]) => void;
  addUserToOrg: (email: string, role: string, organizationId: string, orgName: string) => void;
  router: RouterState;
}

interface State {
  settingsOpen: boolean;
  // Project related
  showProjectModal: boolean;
  languages: string[];
  language: string;
  projectName: string;
  // Organization related
  showOrgModal: boolean;
  orgName: string;
  userToInvite: string;
  usersToInvite: string[];
  showInviteModal: boolean;
  userRole: string;
}

const orgMatchSelector = createMatchSelector('/:organization/:orgid');

class Organization extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      settingsOpen: false,
      showProjectModal: false,
      languages: ['key'],
      language: '',
      projectName: '',
      showOrgModal: false,
      orgName: '',
      userToInvite: '',
      usersToInvite: [],
      showInviteModal: false,
      userRole: '',
    };
  }

  toggleProjectModal = () => {
    this.setState({
      showProjectModal: !this.state.showProjectModal,
      languages: ['key'],
      language: '',
      projectName: ''
    });
  }

  toggleOrgModal = () => {
    this.setState({
      showOrgModal: !this.state.showOrgModal,
      orgName: '',
      userToInvite: '',
      usersToInvite: []
    });
  }

  handleMouting = () => {
    const state = { router: this.props.router } as RouterRootState;
    const orgMatchUrl = orgMatchSelector(state);
    if (orgMatchUrl) {
      const urlParams = orgMatchUrl.params;
      if (urlParams['orgid'] && urlParams['organization']) {
        if (this.props.organizations && this.props.organizations.length > 0) {
          store.dispatch({ type: orgActions.SELECT_ORGANIZATION });
          const org = this.props.organizations.find(o => o.id === urlParams['orgid']);
          if (org) {
            store.dispatch({ type: orgActions.SELECT_ORGANIZATION_SUCCESS, organization: org });
            this.props.getProjects(org, this.props.userId!);
          } else {
            store.dispatch({ type: orgActions.SELECT_ORGANIZATION_FAIL });
          }
        }
      }
    }
  }

  componentDidMount() {
    this.handleMouting();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.router.location.pathname === this.props.router.location.pathname) return;
    this.handleMouting();
  }

  renderLanguages = (languages: string[]) => {
    return (
      <ul>
        {languages.map((language, i) => (
          <li key={i} style={{ listStyle: 'none' }}>
            <i className="material-icons mr-2" style={{ verticalAlign: 'middle' }}>
              language
            </i>
            <p className="small-text" style={{ display: 'inline', verticalAlign: 'middle' }}>{language}</p>
          </li>
        ))}
      </ul>
    );
  }

  checkForProjects = (org: OrganizationModel) => {
    if (!org.projects || org.projects.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  renderModal = (org: OrganizationModel) => {
    return (
      <Modal isOpen={this.state.showProjectModal} toggle={this.toggleProjectModal}>
        <ModalHeader toggle={this.toggleProjectModal}>Create project for {org.name}</ModalHeader>
        <ModalBody>
          <input
            autoComplete="off"
            className="form-control mb-3"
            type="text"
            name="project-name"
            value={this.state.projectName}
            onChange={(e) => this.setState({ projectName: e.currentTarget.value })}
            placeholder="Project name"
            style={{ borderColor: '#999' }}
          />
          <div style={{ display: 'flex', marginBottom: 0 }}>
            <input
              autoComplete="off"
              className="form-control mb-3"
              type="text"
              name="language"
              value={this.state.language}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.setState({
                    languages: [...this.state.languages, this.state.language],
                    language: ''
                  });
                }
              }}
              onChange={(e) => this.setState({ language: e.currentTarget.value })}
              placeholder="Add language"
              style={{ width: '90%', borderColor: '#999' }}
            />
            <div
              className="ml-2 mt-1"
              style={{ cursor: 'pointer' }}
              onClick={
                () => this.setState({
                  languages: [...this.state.languages, this.state.language],
                  language: ''
                })}
            >
              <AddIcon />
            </div>
          </div>
          {this.renderLanguages(this.state.languages)}
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            onClick={this.toggleProjectModal}
            style={{ color: 'black' }}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              this.props.createProject(this.state.projectName, org, this.props.user!, this.state.languages);
              this.toggleProjectModal();
            }}
            disabled={this.state.languages.length < 3}
          >
            Create project
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }

  renderCreateProjectCard = () => {
    return (
      <div
        className={`
          card shadow create-project-card col-sm-3 mt-5 mx-4
          ${Object.keys(CreateUpdateOrganizations).includes(this.props.userRole) ? '' : 'disabled'}
        `}
        onClick={this.toggleProjectModal}
        style={{ cursor: 'pointer' }}
      >
        <div className="card-img-top" style={{ height: '100px' }}>
          <div className="text-center mt-5">
            <Fab
              color="primary"
              aria-label="Add"
              style={{ margin: 'theme.spacing.unit', backgroundColor: '#720ccb' }}
              size="small"
            >
              <AddIcon />
            </Fab>
          </div>
        </div>
        <div className="card-body">
          <p className="card-subtitle card-title-style">
            Create new project
            </p>
          <p className="card-text card-text-style text-muted">
            Click here to create a new project for your organization
            </p>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.isGettingUser) {
      return <CenteredSpinner title="Logging in..." />;
    }

    if (this.props.isSelectingOrganization || this.props.isCreatingOrganization) {
      return <CenteredSpinner title="Selecting your organization..." />;
    }

    if (this.props.isLoadingOrganizations) {
      return <CenteredSpinner title="Fetching your organizations..." />;
    }

    if (this.props.isLoadingProjects) {
      return <CenteredSpinner title="Fetching your projects..." />;
    }

    return (
      <React.Fragment>
        <OrgNavbar
          settingsOpen={this.state.settingsOpen}
          setSettingsOpen={() => this.setState({ settingsOpen: !this.state.settingsOpen })}
        />
        <div className="container-fluid px-0">
          <div id="organization-page" className="bg-light container-color" >
            <GeneralRowButton
              className="btn mt-3 ml-3"
              gradient={true}
              onClick={() => this.setState({ showInviteModal: true })}
              disabled={!Object.keys(CreateUpdateOrganizations).includes(this.props.userRole)}
            >
              Invite user
            </GeneralRowButton>
            <div className="row justify-content-center" >
              {this.renderCreateProjectCard()}
              {this.props.selectedOrganization && this.renderModal(this.props.selectedOrganization)}
              {this.props.projects && this.props.projects.map((project, i) => (
                <div
                  id={`project-${i}`}
                  key={i}
                  className="card shadow col-sm-3 mx-4 mt-5 project-card p-0"
                >
                  <Link
                    to={
                      `/${this.props.selectedOrganization!.name}/` +
                      `${this.props.selectedOrganization!.id}/${project.name}/${project.id}`}
                  >
                    <CardContainer className="card-img-top" bgColor={projectCardColors[i]}>
                      <div />
                    </CardContainer>
                    <div className="card-body text-left">
                      <p className="card-subtitle card-title-style">
                        {project.name}
                      </p>
                      <p className="card-text card-text-style text-muted">
                        This is supposed to a description of what your project consists of. Maybe show members.
                        </p>
                    </div>
                  </Link>
                </div>
              ))
              }
            </div>
            <Dialog open={this.props.isSelectingProject}>
              <DialogTitle><div className="text-center"><Spinner /></div></DialogTitle>
            </Dialog>
          </div>
        </div>
        <Modal isOpen={this.state.showInviteModal} toggle={() => this.setState({ showInviteModal: false })}>
          <ModalHeader
            toggle={() => this.setState({ showInviteModal: false })}
          >
            Invite user to organization
          </ModalHeader>
          <ModalBody>
            <label>Email</label>
            <input
              autoComplete="off"
              className="form-control mb-3"
              type="text"
              name="user-to-invite"
              value={this.state.userToInvite}
              onChange={(e) => this.setState({ userToInvite: e.currentTarget.value })}
              placeholder="Invite user"
              style={{ borderColor: '#999' }}
            />
            <label>Choose role</label>
            <select
              className="form-control"
              value={this.state.userRole}
              onChange={(e) => this.setState({ userRole: e.currentTarget.value })}
              style={{ borderColor: '#999', color: '#999' }}
            >
              <option value="" defaultValue="" disabled={true}>Please select</option>
              <option>admin</option>
              <option>developer</option>
              <option>translator</option>
              <option>member</option>
            </select>
          </ModalBody>
          <ModalFooter>
            <Button
              className="small-text"
              color="link"
              style={{ color: 'black' }}
              onClick={() => this.setState({ showInviteModal: false })}
            >
              Cancel
            </Button>
            <Button
              className="small-text btn modal-button"
              onClick={() => {
                this.props.addUserToOrg(
                  this.state.userToInvite,
                  this.state.userRole,
                  this.props.selectedOrganization!.id,
                  this.props.selectedOrganization!.name
                );
                this.setState({ showInviteModal: false, userToInvite: '' });
              }}
            >
              Invite
            </Button>{' '}
          </ModalFooter>
        </Modal>
        <SettingsDrawer
          organizations={this.props.organizations}
          selectedOrganization={this.props.selectedOrganization}
          setSettingsOpen={() => this.setState({ settingsOpen: !this.state.settingsOpen })}
          settingsOpen={this.state.settingsOpen}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const user = authSelector.user(state);
  return {
    projects: projectSelector.projects(state),
    isLoadingProjects: projectSelector.isLoadingProjects(state),
    isSelectingOrganization: orgSelector.isSelectingOrganization(state),
    isCreatingProject: projectSelector.isCreatingProject(state),
    isSelectingProject: projectSelector.isSelectingProject(state),
    isGettingUser: authSelector.isGettingUser(state),
    userRole: orgSelector.orgRole(state, user!.email),
    isCreatingOrganization: orgSelector.isCreatingOrganization(state),
    router: authSelector.router(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    selectProject: (projectId: string, org: OrganizationModel) => projectApi.selectProject(projectId, org.name, org.id),
    getProjects: (organization: OrganizationModel, userId: string) =>
      projectApi.getProjects(organization, userId),
    createProject: (projectName: string, org: OrganizationModel, user: User, languages: string[]) =>
      projectApi.createProject(projectName, org, user, languages),
    addUserToOrg: (email: string, role: string, organizationId: string, orgName: string) =>
      authApi.addUserToOrg(email, role, organizationId, orgName),
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Organization);