import { Action } from 'redux';
export const SIGN_UP_USER = 'SIGN_UP_USER';

export interface SignUpUserAction extends Action {
  type: typeof SIGN_UP_USER;
}

export const signUpUserAction = (
): SignUpUserAction => ({
  type: SIGN_UP_USER,
});