import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
// import * as authSelectors from '../../modules/auth/authSelector';
import * as orgApi from '../../services/orgApi';
import * as orgSelectors from '../../modules/organization/orgSelector';
import { User } from '../../models/User';
import OrgNavbar from '../common/navigation/OrgNavbar';
// import { RootState } from '../../store';
import { CenteredSpinner } from '../ui-components/Spinner';
import { RootState } from '../../store';
import SettingsDrawer from '../common/settings/SettingsDrawer';
import { Organization } from '../../models/Organization';

interface Props {
  createOrganization: (name: string, user: User, usersToInvite: string[]) => void;
  user: User | undefined;
  organizations: Organization[] | undefined;
  selectedOrganization: Organization | undefined;
  isGettingUser: boolean;
  isCreatingOrganization: boolean;
}

const CreateOrganization = (props: Props) => {
  const [orgModal, setOrgModal] = React.useState(false);
  const [orgName, setOrgName] = React.useState('');
  const [userToInvite, setUserToInvite] = React.useState('');
  const [usersToInvite, setUsersToInvite] = React.useState([] as string[]);
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  if (props.isGettingUser) {
    return <CenteredSpinner title="" />;
  }
  if (props.isCreatingOrganization) {
    return <CenteredSpinner title="" />;
  }
  return (
    <React.Fragment>
      <OrgNavbar
        settingsOpen={settingsOpen}
        setSettingsOpen={() => setSettingsOpen(!settingsOpen)}
      />
      <div id="home-page" className="container-fluid text-center mt-5">
        <div className="text-center container-color" style={{ fontFamily: 'SF UI Display Thin' }}>
          <h2 className="mt-5">It looks like, you don't have an organization yet.</h2>
          <h1 className="mt-5">Add your first organization to get started localizing!</h1>
          <div>
            <button
              color="primary"
              className="landingpage-get-started mt-3"
              onClick={() => setOrgModal(true)}
            >
              Create organization
            </button>
          </div>
          <Modal isOpen={orgModal} toggle={() => setOrgModal(false)}>
            <ModalHeader toggle={() => setOrgModal}>Creating new organization</ModalHeader>
            <ModalBody>
              <input
                autoComplete="off"
                className="form-control mb-3"
                type="text"
                name="org-name"
                value={orgName}
                onChange={(e) => setOrgName(e.currentTarget.value)}
                placeholder="Organization name"
                style={{ borderColor: '#999' }}
              />
              <div style={{ display: 'flex', marginBottom: 0 }}>
                <input
                  autoComplete="off"
                  className="form-control mb-3"
                  type="email"
                  name="user"
                  value={userToInvite}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setUsersToInvite([...usersToInvite, userToInvite]);
                      setUserToInvite('');
                    }
                  }}
                  onChange={(e) => setUserToInvite(e.currentTarget.value)}
                  placeholder="Add user to invite list"
                  style={{ width: '90%', borderColor: '#999' }}
                />
                <div
                  className="ml-2 mt-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setUsersToInvite([...usersToInvite, userToInvite]);
                    setUserToInvite('');
                  }}
                >
                  <AddIcon />
                </div>
              </div>
              <ul>
                {usersToInvite.map((user, i) => (
                  <li key={i} style={{ listStyle: 'none' }}>
                    <Person className="mr-2" />
                    {user}
                  </li>
                ))}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button
                className="small-text"
                color="link"
                onClick={() => setOrgModal(false)}
                style={{ color: 'black' }}
              >
                Cancel
              </Button>
              <Button
                className="small-text btn modal-button"
                onClick={() => {
                  props.createOrganization(orgName, props.user!, usersToInvite);
                  setOrgModal(false);
                }}
              >
                Create
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <SettingsDrawer
        organizations={props.organizations}
        selectedOrganization={props.selectedOrganization}
        setSettingsOpen={() => setSettingsOpen(!settingsOpen)}
        settingsOpen={settingsOpen}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isCreatingOrganization: orgSelectors.isCreatingOrganization(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    createOrganization: (name: string, user: User, usersToInvite: string[]) =>
      orgApi.createOrganization(name, user, usersToInvite)
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganization);