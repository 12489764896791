import { Action } from 'redux';

export const ADD_LANGUAGE_TO_PROJECT_FAIL = 'ADD_LANGUAGE_TO_PROJECT_FAIL';

export interface AddLanguageToProjectFailAction extends Action {
  type: typeof ADD_LANGUAGE_TO_PROJECT_FAIL;
  language: string;
}

export const addLanguageToProjectFailAction = (
  language: string,
): AddLanguageToProjectFailAction => ({
  type: ADD_LANGUAGE_TO_PROJECT_FAIL,
  language
});