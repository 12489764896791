import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo/icecream@2x.png';
import { User } from '../../../models/User';
import { Organization } from '../../../models/Organization';
import { bindActionCreators, Dispatch } from 'redux';
import * as projectApi from '../../../services/projectApi';
import * as orgApi from '../../../services/orgApi';
import * as authApi from '../../../services/authApi';
import { connect } from 'react-redux';

export const LocaliceLogo = styled.img`
  width: 39px;
  height: 72px;
`;

const LogoContainer = styled.a`
  cursor: pointer;
`;

const NavbarItem = styled.p`
  color: #6c757d;
  font-size: 14px;
  font-family: "SF UI Display Semibold";
  transition: 0.3s;
  &:hover {
    color: black;
    text-shadow: 0px 5px 10px rgba(150,101,190, 0.4);
  }
`;

interface Props {
  user: User | undefined;
  selectedOrganization: Organization | undefined;
  signOut: () => void;
}

const LandingPageNavbar = (props: Props) => {

  const renderNavbarOptions = () => {
    if (props.user === undefined || props.user.id === undefined) {
      return (
        <>
          <li className="nav-item">
            <Link to="/signin">
              <NavbarItem className="nav-link js-scroll-trigger">Login</NavbarItem>
            </Link>
          </li>
          <li className="nav-item mr-5">
            <Link to="/signup" >
              <NavbarItem className="nav-link js-scroll-trigger">Sign up for free</NavbarItem>
            </Link>
          </li>
        </>
      );
    } else if (props.selectedOrganization) {
      return (
        <>
          <li className="nav-item ">
            <Link to={`/${props.selectedOrganization!.name}/${props.selectedOrganization!.id}`} >
              <NavbarItem className="nav-link js-scroll-trigger">Go to projects</NavbarItem>
            </Link>
          </li>
          <li className="nav-item mr-5">
            <NavbarItem className="nav-link js-scroll-trigger" onClick={() => props.signOut()}>Log out</NavbarItem>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className="nav-item">
            <Link
              to={`/create-organization`}
            >
              <NavbarItem className="nav-link js-scroll-trigger">Go to projects</NavbarItem>
            </Link>
          </li>
          <li className="nav-item mr-5">
            <NavbarItem className="nav-link js-scroll-trigger" onClick={() => props.signOut()}>Log out</NavbarItem>
          </li>
        </>
      );
    }
  };

  return (
    <nav className="main-nav navbar navbar-expand-lg fixed-top" id="mainNav">
      <div className="container-fluid px-0">
        <LogoContainer className="navbar-brand localize-logo js-scroll-trigger ml-5">
          <LocaliceLogo
            src={Logo}
            alt="logo"
            className="mr-3"
          />
        </LogoContainer>
        <ul className="nav justify-content-end text-uppercase">
          {renderNavbarOptions()}
        </ul>
      </div>
    </nav>
  );
};

const mapDispatch = (dispatch: Dispatch) => {
  return bindActionCreators({
    signOut: () => authApi.signOut(),
  }, dispatch);
};

export default connect(null, mapDispatch)(LandingPageNavbar);