import { Dispatch } from 'redux'
import { dbRef } from '../store'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import * as projectAction from '../modules/project/projectActions'
import * as translationAction from '../modules/translation/translationsActions'
import * as FSROUTES from '../constants/firestoreRoutes'
import { Translation } from '../models/Translation'
import SortableTranslation from '../models/SortableTranslation'

export function updateInput(
  projectId: string,
  translation: Translation,
  field: string,
  value: string,
  plural: boolean,
  isComment: boolean,
  editor: string
) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: projectAction.UPDATE_INPUT,
      translation,
      field,
      value,
      plural,
      isComment
    })
    let original = ''
    const docRef = dbRef
      .collection(FSROUTES.PROJECTS)
      .doc(projectId)
      .collection(FSROUTES.TRANSLATIONS)
      .doc(translation.id)
    await dbRef
      .runTransaction(function (transaction: firebase.firestore.Transaction) {
        return transaction.get(docRef).then((doc) => {
          original = doc.get(field)
          transaction.update(docRef, { [field]: value })
        })
      })
      .then(() => {
        addToTranslationLogs(translation.id, value, original, field, editor)
      })
      .catch((error) => {
        toastr.error('Error', 'Could not update translation')
      })
  }
}

export function removeRow(projectId: string, translation: Translation) {
  return (dispatch: Dispatch) => {
    dispatch({ type: projectAction.REMOVE_ROW, translation })
    try {
      dbRef
        .collection(FSROUTES.PROJECTS)
        .doc(projectId)
        .collection(FSROUTES.TRANSLATIONS)
        .doc(translation.id)
        .delete()
    } catch (error) {
      toastr.error('Error', 'Could not remove rows')
    }
  }
}

export function addRow(projectId: string, languages: string[]) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectAction.ADD_ROW })
    let translation: Translation
    try {
      const projectRef = dbRef.collection(FSROUTES.PROJECTS).doc(projectId)
      projectRef
        .get()
        .then((docs) => {
          languages = docs.get('languages')
        })
        .then(() => {
          const translationRef = projectRef
            .collection(FSROUTES.TRANSLATIONS)
            .doc()
          translationRef.set({ key: '' })
          translation = {
            id: translationRef.id,
            translations: [],
            pluralTranslations: []
          }
          languages
            .sort()
            .sort((x, y) => {
              if (x === 'key') {
                return -1
              }
              if (y === 'key') {
                return 1
              }
              return 0
            })
            .forEach((language) => {
              translation.translations.push(
                new SortableTranslation(language, '')
              )
              translation.comment = ''
              translationRef
                .update({
                  [language]: '',
                  comment: ''
                })
                .catch((err) => console.log(err))
            })
        })
        .then(() => {
          dispatch({ type: projectAction.ADD_ROW_SUCCESS, translation })
        })
        .catch((error) => console.log(error))
    } catch (error) {
      dispatch({ type: projectAction.ADD_ROW_FAIL })
      toastr.error('Error', 'Could not add row')
    }
  }
}

export function addPluralRow(
  projectId: string,
  translation: Translation,
  languages: string[]
) {
  return (dispatch: Dispatch) => {
    dispatch({ type: projectAction.ADD_PLURAL_ROW })
    try {
      const projectRef = dbRef.collection(FSROUTES.PROJECTS).doc(projectId)
      projectRef
        .get()
        .then((docs) => (languages = docs.get('languages')))
        .then(() => {
          const translationRef = projectRef
            .collection(FSROUTES.TRANSLATIONS)
            .doc(translation.id)
          languages
            .filter((language) => language !== 'key')
            .sort()
            .forEach((language2) => {
              translation.pluralTranslations!.push(
                new SortableTranslation(`plural${language2}`, '')
              )
              translation.pluralcomment = ''
              translationRef
                .update({
                  [`plural${language2}`]: '',
                  pluralcomment: ''
                })
                .catch((err) => console.log(err))
            })
        })
        .then(() => {
          dispatch({ type: projectAction.ADD_PLURAL_ROW_SUCCESS, translation })
        })
    } catch (error) {
      dispatch({ type: projectAction.ADD_PLURAL_ROW_FAIL })
      toastr.error('Error', 'Could not add plural row')
    }
  }
}

export function addLanguage(projectId: string, language: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: projectAction.ADD_LANGUAGE_TO_PROJECT })
    let hasPlural: boolean
    const projectRef = dbRef.collection(FSROUTES.PROJECTS).doc(projectId)
    projectRef
      .update({
        languages: firebase.firestore.FieldValue.arrayUnion(language)
      })
      .then(() => {
        projectRef
          .collection(FSROUTES.TRANSLATIONS)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              // Check if document contains plural translations
              // Then we need to add the plural version of the language as well
              hasPlural = !!Object.keys(doc.data()).find((el) =>
                el.startsWith('plural')
              )
              if (hasPlural) {
                projectRef
                  .collection(FSROUTES.TRANSLATIONS)
                  .doc(doc.id)
                  .update({
                    [language]: '',
                    [`plural${language}`]: ''
                  })
              } else {
                projectRef
                  .collection(FSROUTES.TRANSLATIONS)
                  .doc(doc.id)
                  .update({
                    [language]: ''
                  })
              }
            })
          })
          .then(() => {
            dispatch({
              type: projectAction.ADD_LANGUAGE_TO_PROJECT_SUCCESS,
              language,
              isPlural: hasPlural
            })
          })
          .catch(() =>
            dispatch({ type: projectAction.ADD_LANGUAGE_TO_PROJECT_FAIL })
          )
      })
      .catch(() =>
        dispatch({ type: projectAction.ADD_LANGUAGE_TO_PROJECT_FAIL })
      )
  }
}

export function translate(
  input: string,
  targetLanguage: string,
  translation: Translation,
  field: string,
  projectId: string,
  loadingIdentifier: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: translationAction.AUTO_TRANSLATE,
      field: input + field,
      loadingIdentifier
    })
    axios
      .post('https://localice-api.herokuapp.com/translate', {
        input,
        targetLanguage
      })
      .then((res) => {
        const translatedValue = res.data
        dbRef
          .collection(FSROUTES.PROJECTS)
          .doc(projectId)
          .collection(FSROUTES.TRANSLATIONS)
          .doc(translation.id)
          .update({
            [field]: translatedValue
          })
          .then(() => {
            dispatch({
              type: translationAction.AUTO_TRANSLATE_SUCCESS,
              translation,
              field,
              value: translatedValue,
              loadingIdentifier
            })
          })
          .catch((error) => {
            toastr.error('Error', 'Could not update translation')
            dispatch({
              type: translationAction.AUTO_TRANSLATE_FAIL,
              field: input + field
            })
          })
      })
      .catch((error) => {
        toastr.error('Error', 'Could not update translation')
        dispatch({
          type: translationAction.AUTO_TRANSLATE_FAIL,
          field: input + field
        })
      })
  }
}

async function addToTranslationLogs(
  translationId: string,
  change: string,
  original: string,
  field: string,
  editor: string
) {
  return await dbRef
    .collection('editing_logs')
    .add({
      change,
      original,
      editor,
      field,
      translation_id: translationId,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
    .catch((error) => console.log(error))
}

export async function getTranslationLogs(
  translationId: string
): Promise<any[]> {
  return new Promise(async (resolve, reject) => {
    let logs = [] as any[]
    await dbRef
      .collection('editing_logs')
      .where('translation_id', '==', translationId)
      .limit(20)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          logs.push({
            change: doc.get('change'),
            original: doc.get('original'),
            timestamp: doc.get('timestamp'),
            editor: doc.get('editor'),
            field: doc.get('field')
          })
        })
      })
      .catch((error) => {
        reject(error)
      })
    resolve(logs)
  })
}
