import * as React from 'react';
import iso from 'iso-639-1';
import styled from 'styled-components';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as translationApi from '../../services/translationsApi';
import * as projectSelectors from '../project/projectSelector';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translation } from '../../models/Translation';
import { countryCodes } from '../../constants/countryCodes';
import Spinner from './Spinner';
import { RootState } from '../../store';

const StyledInput = styled.input`
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  background-color: ${(props: { backgroundColor: string }) => props.backgroundColor} !important;
  border-radius: 8px !important;
`;

const CharacterCount = styled.label`
  font-size: 10px;
  position: absolute;
  right: 0;
  margin-right: 4px;
  color: gray;
  z-index: 1000;
`;

const AutoTranslate = styled.label`
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
  margin-right: 2px;
  margin-bottom: 0 !important;
  color: gray;
  z-index: 1000;
`;

const AutoTranslateSpinner = styled.label`
  position: absolute;
  left: 45%;
  top: 20%;
`;

interface OwnProps {
  name: string;
}

interface Props extends OwnProps {
  name: string;
  value?: string;
  keyId?: number;
  translation: Translation;
  projectId: string;
  updateInput: (event: React.FormEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  isAutoTranslating: (name: string) => boolean;
  translate: (
    input: string,
    target: string,
    trans: Translation,
    field: string,
    projectId: string,
    loadingIdentifier: string
  ) => void;
}

const TextField = (props: Props) => {
  const [charCount, setCharCount] = React.useState(props.value ? props.value.length : 0);
  const [autoTranslateModal, setAutoTranslateModal] = React.useState(false);
  const [translateInput, setTranslateInput] = React.useState('');
  const [targetLanguage, setTargetLanguage] = React.useState<{ value: '', label: '' } | null>(null);

  function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
    setCharCount(e.currentTarget.value.length);
    if (!e.currentTarget.value || e.currentTarget.value.length === 0) {
      e.currentTarget.style.backgroundColor = '#d0021b';
      e.currentTarget.style.color = '#ffffff';
    } else {
      e.currentTarget.style.backgroundColor = '#00a700';
      e.currentTarget.style.color = '#ffffff';
    }
  }

  function decideColor() {
    if (props.name === 'comment' || props.name === 'pluralcomment') {
      return '#fffce7';
    }
    if (!props.value && props.name !== 'comment') {
      return '#d0021b';
    }
    if (!props.value && props.name !== 'pluralcomment') {
      return '#d0021b';
    }
    return '';
  }

  const backgroundColor = decideColor();

  function autoTranslateDisabled() {
    if (translateInput.length === 0 || targetLanguage === null) {
      return true;
    } else {
      return false;
    }
  }

  const handleSelectedOption = (selectedOption: any) => setTargetLanguage(selectedOption);

  const options = iso.getAllCodes().map(code => {
    return (
      {
        value: code,
        label: iso.getName(code)
      }
    );
  });

  return (
    <React.Fragment>
      <div className="row">
        <StyledInput
          id={props.translation.id + props.keyId}
          readOnly={props.readOnly}
          backgroundColor={backgroundColor}
          type="text"
          autoComplete="off"
          className="form-control border-0 small-text"
          name={props.name}
          defaultValue={props.value}
          onBlur={(e) => {
            props.updateInput(e);
            e.currentTarget.style.color = '#000000';
            e.currentTarget.value ?
              e.currentTarget.style.backgroundColor = 'white' :
              e.currentTarget.style.backgroundColor = '#d0021b';
          }}
          onChange={(e) => handleOnChange(e)}
          disabled={props.disabled || props.isAutoTranslating(props.translation.id + props.keyId)}
        />
        {props.isAutoTranslating(props.translation.id + props.keyId) && (
          <AutoTranslateSpinner>
            <Spinner containerWidth={'10px'} containerHeight={'10px'} width={'8px'} height={'8px'} borderSize={'2px'} />
          </AutoTranslateSpinner>
        )}
        <CharacterCount className="character-count">
          {charCount}
        </CharacterCount>
        <AutoTranslate className="google-translate" onClick={() => setAutoTranslateModal(true)}>
          <i className="material-icons" style={{ fontSize: '15px' }}>
            language
          </i>
        </AutoTranslate>
      </div>
      <Modal isOpen={autoTranslateModal} toggle={() => setAutoTranslateModal(!autoTranslateModal)}>
        <ModalHeader toggle={() => setAutoTranslateModal(!autoTranslateModal)}>
          Let Google translate do the job!
        </ModalHeader>
        <ModalBody>
          <input
            autoComplete="off"
            className="form-control mb-3"
            type="text"
            name="source-text"
            value={translateInput}
            onChange={(e) => setTranslateInput(e.currentTarget.value)}
            placeholder="Input text for translation"
            style={{ borderColor: '#999' }}
          />
          <Select
            className="mb-3"
            name="target-language"
            value={targetLanguage}
            onChange={handleSelectedOption}
            placeholder="Choose target language for translation"
            options={options}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            onClick={() => setAutoTranslateModal(!autoTranslateModal)}
            style={{ color: 'black' }}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              if (props.translation.id) {
                props.translate(
                  translateInput,
                  targetLanguage!.value,
                  props.translation,
                  props.name,
                  props.projectId,
                  props.translation.id + props.keyId);
              }
              setAutoTranslateModal(!autoTranslateModal);
            }}
            disabled={autoTranslateDisabled()}
          >
            Generate translation
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    translate: (
      input: string,
      target: string,
      trans: Translation,
      field: string,
      projectId: string,
      loadingIdentifier: string
    ) => translationApi.translate(input, target, trans, field, projectId, loadingIdentifier)
  }, dispatch);
};

const mapDispatchToState = (state: RootState) => {
  return {
    isAutoTranslating: (name: string) => projectSelectors.isAutoTranslating(state, name)
  };
};

export default connect(mapDispatchToState, mapDispatchToProps)(TextField);