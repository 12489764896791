import { Action } from 'redux';
import { User } from '../../../models/User';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export interface GetUserSuccessAction extends Action {
  type: typeof GET_USER_SUCCESS;
  user: User;
}

export const getUserSuccessAction = (
  user: User
): GetUserSuccessAction => ({
  type: GET_USER_SUCCESS,
  user
});