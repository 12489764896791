import { Action } from 'redux';
import { Organization } from '../../../models/Organization';

export const CREATE_ORGANIZATIONS_SUCCESS = 'CREATE_ORGANIZATIONS_SUCCESS';

export interface CreateOrganizationsSuccessAction extends Action {
  type: typeof CREATE_ORGANIZATIONS_SUCCESS;
  organization: Organization;
}

export const createOrganizationsSuccessAction = (
  organization: Organization,
): CreateOrganizationsSuccessAction => ({
  type: CREATE_ORGANIZATIONS_SUCCESS,
  organization,
});