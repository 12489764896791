import { Action } from 'redux';
export const SIGN_UP_USER_FAIL = 'SIGN_UP_USER_FAIL';

export interface SignUpUserFailAction extends Action {
  type: typeof SIGN_UP_USER_FAIL;
}

export const signUpUserFailAction = (
): SignUpUserFailAction => ({
  type: SIGN_UP_USER_FAIL,
});