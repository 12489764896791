import { Action } from 'redux';
import { Organization } from '../../../models/Organization';

export const SELECT_PROJECT = 'SELECT_PROJECT';

export interface SelectProjectAction extends Action {
  type: typeof SELECT_PROJECT;
  org: Organization;
  projectId: string;
}

export const selectProjectAction = (
  projectId: string,
  org: Organization
): SelectProjectAction => ({
  type: SELECT_PROJECT,
  org,
  projectId
});