import { Action } from 'redux';
export const SIGN_OUT_USER_FAIL = 'SIGN_OUT_USER_FAIL';

export interface SignOutUserFailAction extends Action {
  type: typeof SIGN_OUT_USER_FAIL;
}

export const signOutUserFailAction = (
): SignOutUserFailAction => ({
  type: SIGN_OUT_USER_FAIL,
});