import { Action } from 'redux';

export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export interface CreateProjectFailAction extends Action {
  type: typeof CREATE_PROJECT_FAIL;
}

export const createProjectFailAction = (
): CreateProjectFailAction => ({
  type: CREATE_PROJECT_FAIL,
});