import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
// Redux methods
import * as projectSelectors from '../../project/projectSelector'
import * as orgSelectors from '../../organization/orgSelector'
import * as authSelectors from '../../auth/authSelector'
import * as importExportApi from '../../../services/importExportApi'
import * as authApi from '../../../services/authApi'
import * as translationApi from '../../../services/translationsApi'
// Models
import { Project } from '../../../models/Project'
import { RootState } from '../../../store'
// Components
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Organization } from '../../../models/Organization'
import {
  CreateDeleteUpdateTranslations,
  CreateUpdateProjects
} from '../../../models/Roles'
import ScriptModal from './ScriptModal'

export const GeneralRowButton = styled.button`
  border-radius: 30px;
  ${(props: { gradient: boolean }) =>
    props.gradient
      ? 'background-image: linear-gradient(to top, #5b2cc0, #9665be); color: white;'
      : 'background-color: white; color: #720ccb;'}
  font-family: 'SF UI Display Semibold';
  transition: all 0.3s ease 0s;
  border-color: transparent;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  &:hover {
    box-shadow: 0px 2px 5px rgba(150,101,190, 0.4);
    ${(props: { gradient: boolean }) =>
      props.gradient ? 'color: #ffffff;' : 'color: #720ccb;'}
`

interface Props {
  projects: Project[] | undefined
  selectedProject: Project | undefined
  selectedOrg: Organization | undefined
  languages: string[] | undefined
  userRole: string
  handleSearch: (e: React.FormEvent<HTMLInputElement>) => void
  downloadTranslations: (
    project: Project,
    platform: string,
    prefix: string
  ) => void
  downloadWeb: (project: Project, platform: string, keyprefix: string) => void
  addUserToProject: (email: string, role: string, project: Project) => void
  addRow: (projectId: string, languages: string[]) => void
  addLanguage: (projectId: string, language: string) => void
  importDotStringsFormat: (
    inputFile: File,
    language: string,
    languages: string[],
    projectId: string
  ) => void
  importXmlFormat: (
    inputFile: File,
    language: string,
    languages: string[],
    projectId: string
  ) => void
}

const TopRow = (props: Props) => {
  const [inviteUserModal, setInviteUserModal] = React.useState(false)
  const [userToInvite, setUserToInvite] = React.useState('')
  const [role, setRole] = React.useState('')

  const [scriptModal, setScriptModal] = React.useState(false)

  const [downloadModal, setDownloadModal] = React.useState(false)
  const [platform, setPlatform] = React.useState('')
  const [prefix, setPrefix] = React.useState('')

  const [addLanguageModal, setaddLanguageModal] = React.useState(false)
  const [language, setLanguage] = React.useState('')

  // Regex to find ending of uploaded file
  const regex = /\.[0-9a-z]+$/i
  const [importModal, setImportModal] = React.useState(false)
  const [languageToImport, setLanguageToImport] = React.useState('')
  const [file, setFile] = React.useState(
    {} as { file: File | undefined; format: string }
  )

  function handleSetFile(files: FileList | null) {
    const fileFormat = files ? regex.exec(files[0].name)![0] : ''
    const uploadedFile = files ? files[0] : undefined
    setFile({ file: uploadedFile, format: fileFormat })
  }

  function importTranslations() {
    switch (file.format) {
      case '.xml':
        if (file.file) {
          props.importXmlFormat(
            file.file,
            languageToImport,
            props.languages!,
            props.selectedProject!.id
          )
        } else {
          return
        }
        break
      case '.strings':
        if (file.file) {
          props.importDotStringsFormat(
            file.file,
            languageToImport,
            props.languages!,
            props.selectedProject!.id
          )
        } else {
          return
        }
        break
      default:
        return
    }
  }

  function downloadTranslations() {
    switch (platform) {
      case 'ios':
        props.downloadTranslations(props.selectedProject!, 'ios', prefix)
        break
      case 'android':
        props.downloadTranslations(props.selectedProject!, 'android', prefix)
        break
      case 'flutter':
        props.downloadTranslations(props.selectedProject!, 'flutter', prefix)
        break
      case 'web':
        props.downloadWeb(props.selectedProject!, 'web', prefix)
        break
      default:
        return
    }
  }

  return (
    <div className="container-fluid px-5">
      <div className="row justify-content-between">
        <div className="col text-right">
          <GeneralRowButton
            className="btn"
            gradient={false}
            onClick={() => setImportModal(true)}
            disabled={
              Object.keys(CreateUpdateProjects).includes(props.userRole)
                ? false
                : true
            }
          >
            Import
          </GeneralRowButton>
          <GeneralRowButton
            className="btn"
            gradient={false}
            onClick={() => setaddLanguageModal(true)}
            disabled={
              Object.keys(CreateUpdateProjects).includes(props.userRole)
                ? false
                : true
            }
          >
            Add extra language
          </GeneralRowButton>
          <GeneralRowButton
            className="btn"
            gradient={false}
            onClick={() => setInviteUserModal(true)}
            disabled={
              Object.keys(CreateUpdateProjects).includes(props.userRole)
                ? false
                : true
            }
          >
            Invite
          </GeneralRowButton>
          <GeneralRowButton
            className="btn"
            gradient={false}
            type="button"
            onClick={() => setDownloadModal(true)}
          >
            Download
          </GeneralRowButton>
        </div>
      </div>
      <div className="row justify-content-between mt-3">
        <div className="col text-left p-0">
          <input
            autoComplete="off"
            type="text"
            className="top-row-input form-control shadow-sm"
            placeholder="Search your translations..."
            onChange={(e) => props.handleSearch(e)}
          />
        </div>
        <div className="col text-right">
          <GeneralRowButton
            style={{ marginTop: '0px' }}
            gradient={true}
            className="btn"
            disabled={
              Object.keys(CreateDeleteUpdateTranslations).includes(
                props.userRole
              )
                ? false
                : true
            }
            onClick={() =>
              props.addRow(
                props.selectedProject!.id,
                props.selectedProject!.languages
              )
            }
          >
            Add new line
          </GeneralRowButton>
        </div>
      </div>
      <Modal isOpen={inviteUserModal} toggle={() => setInviteUserModal(false)}>
        <ModalHeader toggle={() => setInviteUserModal(false)}>
          Invite user to project
        </ModalHeader>
        <ModalBody>
          <label>Email</label>
          <input
            autoComplete="off"
            className="form-control mb-3"
            type="text"
            name="user-to-invite"
            value={userToInvite}
            onChange={(e) => setUserToInvite(e.currentTarget.value)}
            placeholder="Invite user"
            style={{ borderColor: '#999' }}
          />
          <label>Choose role</label>
          <select
            className="form-control"
            value={role}
            onChange={(e) => setRole(e.currentTarget.value)}
            style={{ borderColor: '#999', color: '#999' }}
          >
            <option value="" defaultValue="" disabled={true}>
              Please select
            </option>
            <option>admin</option>
            <option>developer</option>
            <option>translator</option>
            <option>member</option>
          </select>
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            style={{ color: 'black' }}
            onClick={() => setInviteUserModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              props.addUserToProject(userToInvite, role, props.selectedProject!)
              setInviteUserModal(false)
            }}
          >
            Invite
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={addLanguageModal}
        toggle={() => setaddLanguageModal(false)}
      >
        <ModalHeader toggle={() => setaddLanguageModal(false)}>
          Add language to project
        </ModalHeader>
        <ModalBody>
          <input
            autoComplete="off"
            className="form-control mb-3"
            type="text"
            name="add-language"
            value={language}
            onChange={(e) => setLanguage(e.currentTarget.value)}
            placeholder="Add language"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            style={{ color: 'black' }}
            onClick={() => setaddLanguageModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              console.log(props.selectedProject!.id)
              props.addLanguage(props.selectedProject!.id, language)
              setaddLanguageModal(false)
            }}
          >
            Add
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Modal isOpen={importModal} toggle={() => setImportModal(false)}>
        <ModalHeader toggle={() => setImportModal(false)}>
          Import translations
        </ModalHeader>
        <ModalBody>
          <div>
            <input
              autoComplete="off"
              className="mb-3"
              accept=".xml,.strings"
              type="file"
              onChange={(e) => handleSetFile(e.currentTarget.files)}
              placeholder="Choose file"
              style={{ borderColor: '#999', fontSize: '18px' }}
            />
          </div>
          <label style={{ fontSize: '18px' }}>Choose language</label>
          <select
            className="form-control"
            value={languageToImport}
            onChange={(e) => setLanguageToImport(e.currentTarget.value)}
            style={{ borderColor: '#999', color: '#999' }}
          >
            <option value="" defaultValue="" disabled={true}>
              Please select
            </option>
            {props.languages &&
              props.languages
                .filter((el) => el !== 'key')
                .map((el, i) => <option key={i}>{el}</option>)}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            style={{ color: 'black' }}
            onClick={() => setImportModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              importTranslations()
              setImportModal(false)
            }}
          >
            Import
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={downloadModal}
        toggle={() => {
          setPrefix('')
          setPlatform('')
          setDownloadModal(false)
        }}
      >
        <ModalHeader
          toggle={() => {
            setPrefix('')
            setPlatform('')
            setDownloadModal(false)
          }}
        >
          Downloading translations
        </ModalHeader>
        <ModalBody>
          <div>
            <label style={{ fontSize: '18px' }}>
              Add a prefix to your translations
            </label>
            <input
              autoComplete="off"
              className="form-control mb-3"
              style={{
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: 0,
                borderColor: '#999'
              }}
              type="text"
              name="add-prefix"
              value={prefix}
              onChange={(e) => setPrefix(e.currentTarget.value)}
              placeholder={`ex.: "prefixed.exampleTranslation"`}
            />
            <label style={{ fontSize: '18px' }}>Choose platform</label>
            <select
              className="form-control"
              value={platform}
              onChange={(e) => setPlatform(e.currentTarget.value)}
              style={{ borderColor: '#999', color: '#999' }}
            >
              <option value="" defaultValue="" disabled={true}>
                Please select
              </option>
              <option value="ios">iOS</option>
              <option value="android">Android</option>
              <option value="flutter">Flutter</option>
              <option value="web">Web (i18n)</option>
            </select>
          </div>
          <div className="mt-2">
            Alternatively,&nbsp;
            <span
              style={{ cursor: 'pointer', color: '#0000EE' }}
              onClick={() => {
                setDownloadModal(false)
                setScriptModal(true)
              }}
            >
              generate a bash script&nbsp;
            </span>
            to manage the downloading.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="small-text"
            color="link"
            style={{ color: 'black' }}
            onClick={() => {
              setPrefix('')
              setPlatform('')
              setDownloadModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            disabled={platform === ''}
            onClick={() => {
              downloadTranslations()
              setPrefix('')
              setPlatform('')
              setDownloadModal(false)
            }}
          >
            Download
          </Button>{' '}
        </ModalFooter>
      </Modal>
      {props.selectedOrg && props.selectedProject ? (
        <ScriptModal
          open={scriptModal}
          setOpen={setScriptModal}
          organization={props.selectedOrg}
          project={props.selectedProject}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const user = authSelectors.user(state)

  return {
    userRole: projectSelectors.userRole(state, user!.email),
    projects: projectSelectors.projects(state),
    selectedProject: projectSelectors.selectedProject(state),
    selectedOrg: orgSelectors.selectedOrganization(state),
    languages: projectSelectors.languages(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      downloadTranslations: (
        project: Project,
        platform: string,
        keyprefix: string
      ) => importExportApi.downloadTranslations(project, platform, keyprefix),
      addUserToProject: (email: string, role: string, project: Project) =>
        authApi.addUserToProject(email, role, project),
      addRow: (projectId: string, languages: string[]) =>
        translationApi.addRow(projectId, languages),
      addLanguage: (projectId: string, language: string) =>
        translationApi.addLanguage(projectId, language),
      importDotStringsFormat: (
        inputFile: File,
        language: string,
        languages: string[],
        projectId: string
      ) =>
        importExportApi.parseDotStringsFormat(
          inputFile,
          language,
          languages,
          projectId
        ),
      importXmlFormat: (
        inputFile: File,
        language: string,
        languages: string[],
        projectId: string
      ) =>
        importExportApi.parseXmlFormat(
          inputFile,
          language,
          languages,
          projectId
        ),
      downloadWeb: (project: Project, platform: string, keyprefix: string) =>
        importExportApi.downloadWeb(project, platform, keyprefix)
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TopRow)
