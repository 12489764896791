import { Action } from 'redux';

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION';

export interface SelectOrganizationAction extends Action {
  type: typeof SELECT_ORGANIZATION;
}

export const selectOrganizationAction = (
): SelectOrganizationAction => ({
  type: SELECT_ORGANIZATION,
});