import * as React from 'react';
import { Link } from 'react-router-dom';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import Logo from '../../../assets/logo/icecream@2x.png';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as orgSelector from '../../organization/orgSelector';
import * as authSelector from '../../auth/authSelector';
import * as organizationApi from '../../../services/orgApi';
import * as authApi from '../../../services/authApi';
import { RootState } from '../../../store';
import { Organization } from '../../../models/Organization';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import { User } from '../../../models/User';
import { OrgNavbarContainer } from './NavbarStyles';
import { LocaliceLogo } from './LandingPageNavbar';

interface Props {
  organizations: Organization[] | undefined;
  user: User | undefined;
  selectedOrganization: Organization | undefined;
  signOut: () => void;
  settingsOpen: boolean;
  setSettingsOpen: any;
  createOrganization: (name: string, user: User, usersToInvite: string[]) => void;
  router: {
    location: {
      pathname: string
    }
  };
}

const projectMatchSelector = createMatchSelector('/:organization/:orgid/:projectname/:projectid');

const OrgNavbar = (props: Props) => {
  const [orgDropdown, setOrgDropdown] = React.useState(false);
  const [createOrgModal, setCreateOrgModal] = React.useState(false);
  const [orgName, setOrgName] = React.useState('');
  const [usersToInvite, setUsersToInvite] = React.useState([] as string[]);
  const [userToInvite, setUserToInvite] = React.useState('');

  const toggleModal = () => {
    setCreateOrgModal(!createOrgModal);
    setOrgName('');
    setUserToInvite('');
    setUsersToInvite([]);
  };

  const state = { router: props.router } as RouterRootState;
  const projectMatchUrl = projectMatchSelector(state);

  const OrgDropdown = () => {
    const hideNavbarTitle: boolean = props.router && props.router.location &&
      (props.router.location.pathname === '/') ? true : false;

    return (
      <Dropdown isOpen={orgDropdown} toggle={() => setOrgDropdown(!orgDropdown)}>
        <div className="row justify-content-center">
          {props.selectedOrganization && !hideNavbarTitle && (
            <>
              <div className="medium-text" style={{ pointerEvents: 'none' }}>
                {props.selectedOrganization && props.selectedOrganization.name} projects
                  </div>
              <DropdownToggle caret={false} tag={'a'} className="nav-link pl-0">
                <i className="ml-3 fa fa-angle-down" aria-hidden="true" style={{ fontSize: '18px' }} />
              </DropdownToggle>
            </>
          )}
        </div>
        <DropdownMenu right={true} className="dropdown-menu-arrow">
          {
            props.organizations && props.organizations.map((org, i) => (
              <Link to={`/${org.name}/${org.id}`} key={i}>
                <DropdownItem>
                  {org.name}
                </DropdownItem>
              </Link>
            ))}
          <DropdownItem divider={true} />
          <DropdownItem
            id="create-org-modal"
            onClick={
              toggleModal}
          >
            Create new organization
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderUsersToInvite = (users: string[]) => (
    <ul>
      {users.map((user, i) => (
        <li key={i} style={{ listStyle: 'none' }}>
          <Person className="mr-2" /> {user}
        </li>
      ))}
    </ul>
  );

  return (
    <OrgNavbarContainer className="row shadow-sm fixed-top">
      <div className="ml-5 col">
        <Link to={'/'} className="navbar-brand align-self-center mt-2">
          <LocaliceLogo src={Logo} alt="logo" className="mr-3" />
        </Link>
      </div>
      <div className="align-self-center text-center col">
        <OrgDropdown />
        <Modal isOpen={createOrgModal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Creating new organization</ModalHeader>
          <ModalBody>
            <input
              autoComplete="off"
              className="form-control mb-3"
              type="text"
              name="org-name"
              value={orgName}
              onChange={(e) => setOrgName(e.currentTarget.value)}
              placeholder="Organization name"
              style={{ borderColor: '#999' }}
            />
            <div style={{ display: 'flex', marginBottom: 0 }}>
              <input
                autoComplete="off"
                className="form-control mb-3"
                type="email"
                name="user"
                value={userToInvite}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setUsersToInvite([...usersToInvite, userToInvite]);
                    setUserToInvite('');
                  }
                }}
                onChange={(e) => setUserToInvite(e.currentTarget.value)}
                placeholder="Add user to invite list"
                style={{ borderColor: '#999' }}
              />
              <div
                className="ml-2 mt-1"
                style={{ cursor: 'pointer' }}
                onClick={() => setUsersToInvite([...usersToInvite, userToInvite])}
              >
                <AddIcon />
              </div>
            </div>
            {renderUsersToInvite(usersToInvite)}
          </ModalBody>
          <ModalFooter>
            <Button
              className="small-text"
              color="link"
              onClick={toggleModal}
              style={{ color: 'black' }}
            >
              Cancel
            </Button>
            <Button
              className="small-text btn modal-button"
              disabled={orgName.length > 0 ? false : true}
              onClick={() => {
                props.createOrganization(orgName, props.user!, usersToInvite);
                toggleModal();
              }}
            >
              Create
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
      {!projectMatchUrl ? (
        <div className="align-self-center mr-5 col">
          {props.settingsOpen ?
            (<i
              className="fa fa-times float-right"
              aria-hidden="true"
              style={{ fontSize: '25px', cursor: 'pointer' }}
              onClick={props.setSettingsOpen}
            />) :
            (<i
              className="fa fa-bars float-right"
              aria-hidden="true"
              style={{ fontSize: '25px', cursor: 'pointer' }}
              onClick={props.setSettingsOpen}
            />)}
        </div>
      ) : <div className="col" />}
    </OrgNavbarContainer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    organizations: orgSelector.organizations(state),
    selectedOrganization: orgSelector.selectedOrganization(state),
    user: authSelector.user(state),
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    createOrganization: (name: string, user: User, usersToInvite: string[]) =>
      organizationApi.createOrganization(name, user, usersToInvite),
    signOut: () => authApi.signOut(),
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgNavbar);