import * as React from 'react'
import * as authSelectors from '../../../auth/authSelector'
import RowTools from '../RowTools'
import { Chip } from '@material-ui/core'
import { Translation } from '../../../../models/Translation'
import TextField from '../../../ui-components/TextField'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as translationsApi from '../../../../services/translationsApi'
import { RootState } from '../../../../store'

interface Props {
  selectedProjectId: string
  translation: Translation | undefined
  toggleExpansion: () => void
  updateInput: (
    projectId: string,
    translation: Translation,
    key: string,
    value: string,
    plural: boolean,
    isComment: boolean,
    editor: string
  ) => void
  userEmail: string
}

const NormalPluralInputRow = (props: Props) => {
  const translation = props.translation
  if (!translation) return null

  const hasComment = translation.pluralcomment ? true : false

  const emptyFieldCount = translation.pluralTranslations!.filter(
    (el) => el.getValue() === '' || undefined
  ).length
  return (
    <React.Fragment>
      <div className="container-fluid px-5 mt-2">
        <div id={`input-row`} className="row">
          <div className="col text-left float-left mr-2">
            <i className="material-icons">subdirectory_arrow_right</i>
            <Chip label="Plural" />
          </div>
          {translation.pluralTranslations &&
            translation.pluralTranslations!.map((language, i) => {
              let key = language.getKey()
              let value = language.getValue()
              return (
                <div className="col text-left mr-2" key={i}>
                  <TextField
                    keyId={i}
                    translation={props.translation!}
                    projectId={props.selectedProjectId}
                    value={value}
                    name={key}
                    updateInput={(e) =>
                      props.updateInput(
                        props.selectedProjectId,
                        translation,
                        e.currentTarget.name,
                        e.currentTarget.value,
                        true,
                        false,
                        props.userEmail
                      )
                    }
                  />
                </div>
              )
            })}
          <div className="col-2 text-left">
            <RowTools
              translationId={translation.id}
              hasComment={hasComment}
              emptyFieldCount={emptyFieldCount}
              expand={props.toggleExpansion}
              expanded={false}
              plural={true}
              selectedProjectId={props.selectedProjectId}
              hasPlural={
                translation.pluralTranslations.length === 0 ? false : true
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateInput: (
        projectId: string,
        translation: Translation,
        field: string,
        value: string,
        plural: boolean,
        isComment: boolean,
        editor: string
      ) =>
        translationsApi.updateInput(
          projectId,
          translation,
          field,
          value,
          plural,
          isComment,
          editor
        )
    },
    dispatch
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: authSelectors.userEmail(state)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NormalPluralInputRow)
