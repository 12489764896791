import { Action } from 'redux';

export const SELECT_PROJECT_FAIL = 'SELECT_PROJECT_FAIL';

export interface SelectProjectFailAction extends Action {
  type: typeof SELECT_PROJECT_FAIL;
  errorMessage: string;
}

export const selectProjectFailAction = (
  errorMessage: string,
): SelectProjectFailAction => ({
  type: SELECT_PROJECT_FAIL,
  errorMessage
});