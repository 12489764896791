import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
require('dotenv').config();
import { ConnectedRouter } from 'connected-react-router';
import { default as rootStore, history } from './store';

import './extensions.d.ts';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// @ts-ignore
import 'font-awesome/css/font-awesome.min.css';
import 'react-virtualized/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import App from './modules/common/App';

ReactDOM.render(
  <Provider store={rootStore}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
