export const projectCardColors = [
  '#50e3c2',
  '#f5a623',
  '#720ccb',
  '#E0FEFE',
  '#C7CEEA',
  '#FFDAC1',
  '#FF9AA2',
  '#FFFFD8',
  '#B5EAD7',
  '#FFF5FB',
  '#DBC4DF',
  '#F4E2D2',
  '#B88BAD',
];