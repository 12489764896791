import { Action } from 'redux';

export const IMPORT_TRANSLATIONS = 'IMPORT_TRANSLATIONS';

export interface ImportTranslationsAction extends Action {
  type: typeof IMPORT_TRANSLATIONS;
}

export const importTranslationsAction = (
): ImportTranslationsAction => ({
  type: IMPORT_TRANSLATIONS,
});