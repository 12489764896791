import { Action } from 'redux';

export const ADD_PLURAL_ROW_FAIL = 'ADD_PLURAL_ROW_FAIL';

export interface AddPluralRowFailAction extends Action {
  type: typeof ADD_PLURAL_ROW_FAIL;
}

export const addPluralRowFailAction = (
): AddPluralRowFailAction => ({
  type: ADD_PLURAL_ROW_FAIL,
});