import { Action } from 'redux';

export const ADD_PLURAL_ROW = 'ADD_PLURAL_ROW';

export interface AddPluralRowAction extends Action {
  type: typeof ADD_PLURAL_ROW;
}

export const addPluralRowAction = (
): AddPluralRowAction => ({
  type: ADD_PLURAL_ROW,
});