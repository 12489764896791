import { RootState } from '../../store';

export const organizations = (state: RootState) => {
  if (!state.organizations) {
    return undefined;
  }
  return state.organizations.organizations;
};

export const isLoadingOrganizations = (state: RootState) => {
  if (!state.organizations) {
    return false;
  }
  return !!state.organizations.isLoadingOrganizations;
};

export const defaultOrganization = (state: RootState) => {
  return state.organizations.organizations[0];
};

export const selectedOrganization = (state: RootState) => {
  if (!state.organizations) {
    return undefined;
  }

  const org = state.organizations.selectedOrganization;

  if (!org) {
    return state.organizations.organizations[0];
  }
  return org;
};

export const isSelectingOrganization = (state: RootState) => {
  if (!state.organizations.isSelectingOrganization) {
    return false;
  }
  return !!state.organizations.isSelectingOrganization;
};

export const isCreatingOrganization = (state: RootState) => {
  if (!state.organizations.isCreatingOrganization) {
    return false;
  }
  return !!state.organizations.isCreatingOrganization;
};

export const orgUsers = (state: RootState) => {
  if (!state.organizations.selectedOrganization) {
    return undefined;
  }

  return state.organizations.selectedOrganization.users;
};

export const orgRole = (state: RootState, email: string) => {
  if (!state.organizations.selectedOrganization) {
    return undefined;
  }

  if (!state.organizations.selectedOrganization.users) {
    return undefined;
  }
  return state.organizations.selectedOrganization.users[email];
};

export const apikeys = (state: RootState) => {
  if (!state.organizations.selectedOrganization) {
    return undefined;
  }

  if (!state.organizations.selectedOrganization.apikeys) {
    return undefined;
  }
  return state.organizations.selectedOrganization.apikeys;
};

export const isCreatingApiKey = (state: RootState) => {
  if (!state.organizations.isCreatingApiKey) {
    return false;
  }
  return !!state.organizations.isCreatingApiKey;
}

export const isDeletingApiKey = (state: RootState) => {
  if (!state.organizations.isDeletingApiKey) {
    return false;
  }
  return !!state.organizations.isDeletingApiKey;
}
