import * as React from 'react';
// Redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as translationsApi from '../../../../services/translationsApi';
import * as authSelectors from '../../../../modules/auth/authSelector';
// Models
import { Translation } from '../../../../models/Translation';
import { Project } from '../../../../models/Project';
// UI Components
import NormalInputRow from './NormalInputRow';
import RowTools from '../RowTools';
import TextField from '../../../ui-components/TextField';
import { RootState } from '../../../../store';

interface Props {
  removeRow: (projectId: string, translation: Translation) => void;
  addPluralRow: (projectId: string, translation: Translation, languages: string[]) => void;
  updateInput: (
    projectId: string,
    translation: Translation,
    field: string,
    value: string,
    plural: boolean,
    isComment: boolean,
    editor: string) => void;
  selectedProject: Project;
  translation: Translation;
  expanded: boolean;
  toggleExpansion: any;
  listRef: any;
  userEmail: string;
}

interface ExpandedProps {
  updateInput: (e: React.FormEvent<HTMLInputElement>) => void;
  addPluralRow: () => void;
  removeRow: () => void;
  selectedProject: Project;
  translation: Translation;
  toggleExpansion: any;
  listRef: any;
}

const ExpandedInputRow = (props: ExpandedProps) => {
  const translation = props.translation;
  const selectedProject = props.selectedProject;
  const hasComment = translation.comment !== undefined && translation.comment.length > 0 ? true : false;
  if (!selectedProject) return null;
  const fullKeyRow = translation.translations.find(el => el.getKey() === 'key');
  return (
    <>
      <div className="container-fluid px-5">
        <div id={`input-row`} className="row">
          <div className="col-10 text-left" style={{ maxWidth: '83%' }}>
            <TextField
              projectId={props.selectedProject.id}
              translation={props.translation}
              value={fullKeyRow!.getValue()}
              name={fullKeyRow!.getKey()}
              updateInput={() => console.log('lol')}
            />
          </div>
          <div className="col-2 text-left">
            <RowTools
              translationId={translation.id}
              hasComment={hasComment}
              expand={props.toggleExpansion}
              expanded={true}
              removeRow={props.removeRow}
              addPluralRow={() => props.addPluralRow()}
              plural={false}
              selectedProjectId={props.selectedProject.id}
              hasPlural={props.translation.pluralTranslations.length === 0 ? false : true}
            />
          </div>
          {translation!.translations.filter(el => el.getKey() !== 'key').map((row, i) => (
            <div className="row" key={i} style={{ width: '100%' }}>
              {i === 0 ? (
                <>
                  <i className="col-1 material-icons mt-2 text-left float-left"> subdirectory_arrow_right</i>
                  <div className="col-1 small-text text-right mt-3 mr-2" style={{ float: 'right' }}>
                    {row.getKey().capitalize()}
                  </div>
                </>
              ) : (
                  <div className="col-2 small-text text-right mt-3 mr-2" style={{ float: 'right' }}>
                    {row.getKey().capitalize()}
                  </div>
                )
              }
              <div className="col-8 text-left mt-2 mr-2">
                <TextField
                  keyId={i}
                  projectId={props.selectedProject.id}
                  translation={props.translation}
                  value={row.getValue()}
                  name={row.getKey()}
                  updateInput={(e) => props.updateInput(e)}
                />
              </div>
            </div>
          ))}
          {translation.comment !== undefined &&
            <div className="row" style={{ width: '100%' }}>
              <div className="col-1" />
              <div className="col-1 small-text text-right mt-3 mr-2" style={{ float: 'right' }}>
                Comment
              </div>
              <div className="col-8 text-left mt-2 mr-2">
                <TextField
                  translation={props.translation}
                  projectId={props.selectedProject.id}
                  value={translation.comment}
                  name="comment"
                  updateInput={(e) => props.updateInput(e)}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

const InputRow = (props: Props) => {

  const updateInput = (e: React.FormEvent<HTMLInputElement>) => {
    props.updateInput(
      props.selectedProject.id,
      props.translation,
      e.currentTarget.name,
      e.currentTarget.value,
      false,
      false,
      props.userEmail
    );
  };

  const addPluralRow = () => {
    props.addPluralRow(
      props.selectedProject.id,
      props.translation!,
      props.selectedProject.languages
    );
  };

  return (
    <>
      {props.expanded ? (
        <ExpandedInputRow
          removeRow={() => props.removeRow(props.selectedProject.id, props.translation)}
          selectedProject={props.selectedProject}
          translation={props.translation}
          toggleExpansion={props.toggleExpansion}
          listRef={props.listRef}
          updateInput={updateInput}
          addPluralRow={addPluralRow}
        />
      ) : (
          <NormalInputRow
            removeRow={() => props.removeRow(props.selectedProject.id, props.translation)}
            selectedProject={props.selectedProject}
            translation={props.translation}
            toggleExpansion={props.toggleExpansion}
          />
        )
      }
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    removeRow: (projectId: string, translation: Translation) => translationsApi.removeRow(projectId, translation),
    updateInput: (
      projectId: string,
      translation: Translation,
      field: string,
      value: string,
      plural: boolean,
      isComment: boolean,
      editor: string) =>
      translationsApi.updateInput(projectId, translation, field, value, plural, isComment, editor),
    addPluralRow: (projectId: string, translation: Translation, languages: string[]) =>
      translationsApi.addPluralRow(projectId, translation, languages),
  }, dispatch);
};

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: authSelectors.userEmail(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputRow);