import * as React from 'react';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const LogsTable = ({
  logs
}: { logs: any[] }) => {

  const logsSorted = _.orderBy(logs, (o: any) => {
    return moment(o.timestamp.toDate());
  }, ['desc']);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="font-weight-bold" align="center">New</TableCell>
            <TableCell className="font-weight-bold" align="center">Old</TableCell>
            <TableCell className="font-weight-bold" align="center">Field</TableCell>
            <TableCell className="font-weight-bold" align="center">Edited by</TableCell>
            <TableCell className="font-weight-bold" align="center">Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logsSorted.map((log: any, i: number) => (
            <TableRow key={i}>
              <TableCell align="center">{log.change}</TableCell>
              <TableCell align="center">{log.original}</TableCell>
              <TableCell align="center">{log.field}</TableCell>
              <TableCell align="center">{log.editor}</TableCell>
              <TableCell align="center">{moment(log.timestamp.toDate()).format('MMM DD YYYY HH:mm:ss z')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogsTable;