import * as React from 'react'
import * as authSelectors from '../../../auth/authSelector'
// Redux
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import * as translationsApi from '../../../../services/translationsApi'
// Models
import { Translation } from '../../../../models/Translation'
import { Project } from '../../../../models/Project'
// UI Components
import TextField from '../../../ui-components/TextField'
import RowTools from '../RowTools'
import { RootState } from '../../../../store'

interface Props {
  updateInput: (
    projectId: string,
    translation: Translation,
    field: string,
    value: string,
    plural: boolean,
    isComment: boolean,
    editor: string
  ) => void
  addPluralRow: (
    projectId: string,
    translation: Translation,
    languages: string[]
  ) => void
  removeRow: () => void
  selectedProject: Project
  translation: Translation
  toggleExpansion: any
  userEmail: string
}

const NormalInputRow = (props: Props) => {
  const translation = props.translation
  const selectedProject = props.selectedProject

  const hasComment =
    translation.comment !== undefined && translation.comment.length > 0
      ? true
      : false

  if (!selectedProject) return null

  const emptyFieldCount =
    translation.translations &&
    translation.translations.filter((el) => el.getValue() === '' || undefined)
      .length
  return (
    <React.Fragment>
      <div className="container-fluid px-5">
        <div id={`input-row`} className="row">
          {translation.translations &&
            translation.translations.map((language, i) => {
              let key = language.getKey()
              let value = language.getValue()
              return (
                <div className="col text-left mr-2" key={i}>
                  <TextField
                    keyId={i}
                    projectId={props.selectedProject.id}
                    translation={translation}
                    value={value}
                    name={key}
                    updateInput={(e) =>
                      props.updateInput(
                        selectedProject.id,
                        translation,
                        e.currentTarget.name,
                        e.currentTarget.value,
                        false,
                        false,
                        props.userEmail
                      )
                    }
                  />
                </div>
              )
            })}
          <div className="col-2 text-left">
            <RowTools
              translationId={translation.id}
              hasComment={hasComment}
              emptyFieldCount={emptyFieldCount}
              expand={props.toggleExpansion}
              expanded={false}
              removeRow={props.removeRow}
              addPluralRow={() => {
                props.addPluralRow(
                  selectedProject.id,
                  translation,
                  selectedProject.languages
                )
              }}
              plural={false}
              selectedProjectId={props.selectedProject.id}
              hasPlural={
                props.translation.pluralTranslations.length === 0 ? false : true
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addPluralRow: (
        projectId: string,
        translation: Translation,
        languages: string[]
      ) => translationsApi.addPluralRow(projectId, translation, languages),
      updateInput: (
        projectId: string,
        translation: Translation,
        field: string,
        value: string,
        plural: boolean,
        isComment: boolean,
        editor: string
      ) =>
        translationsApi.updateInput(
          projectId,
          translation,
          field,
          value,
          plural,
          isComment,
          editor
        )
    },
    dispatch
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: authSelectors.userEmail(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NormalInputRow)
