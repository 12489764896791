import { Action } from 'redux';
export const ADD_USER_TO_PROJECT_FAIL = 'ADD_USER_TO_PROJECT_FAIL';

export interface AddUserToProjectFailAction extends Action {
  type: typeof ADD_USER_TO_PROJECT_FAIL;
}

export const addUserToProjectFailAction = (
): AddUserToProjectFailAction => ({
  type: ADD_USER_TO_PROJECT_FAIL,
});