import { Action } from 'redux';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAIL = 'DELETE_API_KEY_FAIL';

export interface DeleteApiKeyAction extends Action {
  type: typeof DELETE_API_KEY;
}

export interface DeleteApiKeySuccessAction extends Action {
  type: typeof DELETE_API_KEY_SUCCESS;
  apiKey: string;
}

export interface DeleteApiKeyFailAction extends Action {
  type: typeof DELETE_API_KEY_FAIL;
}

export const deleteApiKeyAction = (): DeleteApiKeyAction => ({
  type: DELETE_API_KEY,
});

export const deleteApiKeySuccessAction = (apiKey: string): DeleteApiKeySuccessAction => ({
  type: DELETE_API_KEY_SUCCESS,
  apiKey
});

export const deleteApiKeyFailAction = (): DeleteApiKeyFailAction => ({
  type: DELETE_API_KEY_FAIL,
});