import { Action } from 'redux';
import { Translation } from '../../../models/Translation';

export const AUTO_TRANSLATE_SUCCESS = 'AUTO_TRANSLATE_SUCCESS';

export interface AutoTranslateSuccessAction extends Action {
  type: typeof AUTO_TRANSLATE_SUCCESS;
  translation: Translation;
  field: string;
  value: string;
  loadingIdentifier: string;
}

export const autoTranslateSuccessAction = (
  translation: Translation,
  field: string,
  value: string,
  loadingIdentifier: string
): AutoTranslateSuccessAction => ({
  type: AUTO_TRANSLATE_SUCCESS,
  translation,
  field,
  value,
  loadingIdentifier
});