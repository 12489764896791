import { Action } from 'redux';
import { Project } from '../../../models/Project';

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

export interface GetProjectsSuccessAction extends Action {
  type: typeof GET_PROJECTS_SUCCESS;
  projects: Project[]
}

export const getProjectsSuccessAction = (
  projects: Project[],
): GetProjectsSuccessAction => ({
  type: GET_PROJECTS_SUCCESS,
  projects
});