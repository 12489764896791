import { Action } from 'redux';

export const GET_PROJECTS = 'GET_PROJECTS';

export interface GetProjectsAction extends Action {
  type: typeof GET_PROJECTS;
}

export const getProjectsAction = (
): GetProjectsAction => ({
  type: GET_PROJECTS,
});