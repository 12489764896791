import { Project } from '../../../models/Project';
import * as projectActions from '../projectActions';
import * as translationActions from '../../translation/translationsActions';
import * as authActions from '../../auth/authActions';
import update from 'immutability-helper';

// Helper functions
import {
  updateInput,
  removeRow,
  addRowSuccess,
  addUserToProject,
  addRowPluralSuccess,
  updatePuralInput,
  addLanguageToProject,
  autoTranslate
} from './projectReducerFunctions';

export interface ProjectState {
  projects: Project[] | undefined;
  selectedProject: Project | undefined;
  isAutoTranslating: { [textField: string]: boolean | undefined };
  isSelectingProject: boolean;
  isLoadingProjects: boolean;
  isDownloadingTranslations: boolean;
  isGettingRowImage: boolean;
  isAddingPluralRow: boolean;
  isAddingLanguage: boolean;
  isImportingTranslations: boolean;
  errorMessage: string;
}

const initialState: ProjectState = {
  projects: undefined,
  selectedProject: undefined,
  isAutoTranslating: {},
  isSelectingProject: false,
  isLoadingProjects: false,
  isDownloadingTranslations: false,
  isGettingRowImage: false,
  isAddingPluralRow: false,
  isAddingLanguage: false,
  isImportingTranslations: false,
  errorMessage: '',
};

export type SupportedActions =
  projectActions.GetProjectsAction |
  projectActions.GetProjectsFailAction |
  projectActions.GetProjectsSuccessAction |
  projectActions.SelectProjectAction |
  projectActions.SelectProjectFailAction |
  projectActions.SelectProjectSuccessAction |
  projectActions.GetTranslationsAction |
  projectActions.GetTranslationsFailAction |
  projectActions.GetTranslationsSuccessAction |
  projectActions.UpdateInputAction |
  projectActions.RemoveRowAction |
  projectActions.AddRowSuccessAction |
  projectActions.DownloadTranslationsAction |
  projectActions.DownloadTranslationsFailAction |
  projectActions.DownloadTranslationsSuccessAction |
  projectActions.AddPluralRowAction |
  projectActions.AddPluralRowFailAction |
  projectActions.AddPluralRowSuccessAction |
  projectActions.AddLanguageToProjectAction |
  projectActions.AddLanguageToProjectFailAction |
  projectActions.AddLanguageToProjectSuccessAction |
  projectActions.CreateProjectSuccessAction |
  translationActions.AutoTranslateAction |
  translationActions.AutoTranslateFailAction |
  translationActions.AutoTranslateSuccessAction |
  translationActions.ImportTranslationsAction |
  translationActions.ImportTranslationsFailAction |
  translationActions.ImportTranslationsSuccessAction |
  authActions.AddUserToProjectAction |
  authActions.AddUserToProjectFailAction |
  authActions.AddUserToProjectSuccessAction;

export const projectsReducer = (state = initialState, action: SupportedActions) => {
  switch (action.type) {
    case projectActions.CREATE_PROJECT_SUCCESS:
      return update(state, {
        projects: { $push: [action.project] }
      });
    case projectActions.SELECT_PROJECT:
      return {
        ...state,
        isSelectingProject: true,
      };
    case projectActions.SELECT_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.project,
        isSelectingProject: false,
      };
    case projectActions.SELECT_PROJECT_FAIL:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isSelectingProject: false,
      };
    case projectActions.GET_PROJECTS:
      return {
        ...state,
        isLoadingProjects: true
      };
    case projectActions.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        isLoadingProjects: false,
      };
    case projectActions.GET_PROJECTS_FAIL:
      return {
        ...state,
        isLoadingProjects: false
      };
    case projectActions.GET_TRANSLATIONS:
      return {
        ...state,
        isLoadingTranslations: true,
      };
    case projectActions.GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.translations,
        projectId: action.projectId,
        isLoadingTranslations: false,
      };
    case projectActions.GET_TRANSLATIONS_FAIL:
      return {
        ...state,
        isLoadingTranslations: false,
      };
    case projectActions.DOWNLOAD_TRANSLATIONS:
      return {
        ...state,
        isDownloadingTranslations: true
      };
    case projectActions.DOWNLOAD_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        isDownloadingTranslations: false
      };
    case projectActions.DOWNLOAD_TRANSLATIONS_FAIL:
      return {
        ...state,
        isDownloadingTranslations: false
      };
    case authActions.ADD_USER_TO_PROJECT:
      return { ...state };
    case authActions.ADD_USER_TO_PROJECT_SUCCESS:
      return addUserToProject(state, action);
    case authActions.ADD_USER_TO_PROJECT_FAIL:
      return { ...state };
    case projectActions.UPDATE_INPUT:
      return action.plural ? updatePuralInput(state, action) : updateInput(state, action);
    case projectActions.REMOVE_ROW:
      return removeRow(state, action);
    case projectActions.ADD_ROW_SUCCESS:
      return addRowSuccess(state, action);
    case projectActions.ADD_PLURAL_ROW:
      return {
        ...state,
        isAddingPluralRow: true
      };
    case projectActions.ADD_PLURAL_ROW_FAIL:
      return {
        ...state,
        isAddingPluralRow: false,
      };
    case projectActions.ADD_PLURAL_ROW_SUCCESS:
      return addRowPluralSuccess(state, action);
    case projectActions.ADD_LANGUAGE_TO_PROJECT:
      return {
        ...state,
        isAddingLanguage: true,
      };
    case projectActions.ADD_LANGUAGE_TO_PROJECT_FAIL:
      return {
        ...state,
        isAddingLanguage: false,
      };
    case projectActions.ADD_LANGUAGE_TO_PROJECT_SUCCESS:
      return addLanguageToProject(state, action);
    case translationActions.AUTO_TRANSLATE:
      return {
        ...state,
        isAutoTranslating: { [action.loadingIdentifier]: true }
      };
    case translationActions.AUTO_TRANSLATE_FAIL:
      return {
        ...state,
        isAutoTranslating: { [action.loadingIdentifier]: false }
      };
    case translationActions.AUTO_TRANSLATE_SUCCESS:
      return autoTranslate(state, action);
    case translationActions.IMPORT_TRANSLATIONS:
      return {
        ...state,
        isImportingTranslations: true
      };
    case translationActions.IMPORT_TRANSLATIONS_FAIL:
      return {
        ...state,
        isImportingTranslations: false
      };
    case translationActions.IMPORT_TRANSLATIONS_SUCCESS:
      return update(state, {
        isImportingTranslations: { $set: false },
        // selectedProject: {
        //   translations: { $set: action.translations }
        // }
      });
    default:
      return state;
  }
};