import * as authActions from './authActions';
import { User } from '../../models/User';

export interface AuthenticationState {
  user: User;
  isGettingUser: boolean;
  isSigningOut: boolean;
}

const initialState = {
  user: {} as User,
  isGettingUser: false,
  isSigningOut: false,
};

type SupportedActions =
  authActions.GetUserAction |
  authActions.GetUserFailAction |
  authActions.GetUserSuccessAction |
  authActions.SignOutUserAction |
  authActions.SignOutUserFailAction |
  authActions.SignOutUserSuccessAction;

export const authReducer = (state = initialState, action: SupportedActions) => {
  switch (action.type) {
    case authActions.GET_USER:
      return {
        ...state,
        isGettingUser: true,
      };
    case authActions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        isGettingUser: false,
      };
    case authActions.GET_USER_FAIL:
      return {
        ...state,
        isGettingUser: false,
      };
    case authActions.SIGN_OUT_USER:
      return {
        ...state,
        isSigningOut: true
      };
    case authActions.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        isSigningOut: false
      };
    case authActions.SIGN_OUT_USER_FAIL:
      return {
        ...state,
        isSigningOut: false
      };
    default:
      return state;
  }
};