import * as React from 'react';
import styled from 'styled-components';
import { Organization } from '../../../models/Organization';
import SettingsCollapse from './SettingsCollapse';

const Container = styled.div`
  position: fixed;
  top: 100px;
  right: 0;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: ${(props: { open: boolean }) => props.open ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.5s ease-in-out;
  overflow: auto;
  text-align: center;
  width: 35%;
`;

const OrgContainer = styled.div`
  height: 60px;
  font-family: "SF UI Display Light";
`;

const OrgName = styled.div`
  font-size: 26px;
  font-family: "SF UI Display Light";
`;

const OrgLabel = styled.label`
  margin-top: 15px;
  color: #818182;
`;

interface Props {
  selectedOrganization: Organization | undefined;
  organizations: Organization[] | undefined;
  settingsOpen: boolean;
  setSettingsOpen: any;
}

const SettingsDrawer = (props: Props) => {
  const [settingsCollapseOpen, setSettingsCollapseOpen] = React.useState({
    project: false,
    users: false,
    keys: false
  });

  return (
    <Container open={props.settingsOpen}>
      <div className="container">
        <OrgContainer className="row mt-4 shadow-sm justify-content-center mb-2">
          <OrgName className="mx-2">
            {props.selectedOrganization ? props.selectedOrganization!.name : 'No organisation selected'}
          </OrgName>
          <OrgLabel>Organization</OrgLabel>
        </OrgContainer>
        <div className="row mt-4">
          <div className="col-12 mb-3 px-4">
            <div className="">
              <div className="pb-4">
                <SettingsCollapse
                  selectedOrganization={props.selectedOrganization}
                  settingsOpen={settingsCollapseOpen}
                  setSettingsOpen={setSettingsCollapseOpen}
                  stateKey="projects"
                  title="Projects"
                />
              </div>
              <div className="pb-4">
                <SettingsCollapse
                  selectedOrganization={props.selectedOrganization}
                  settingsOpen={settingsCollapseOpen}
                  setSettingsOpen={setSettingsCollapseOpen}
                  stateKey="users"
                  title="Users"
                />
              </div>
              <div className="pb-4">
                <SettingsCollapse
                  selectedOrganization={props.selectedOrganization}
                  settingsOpen={settingsCollapseOpen}
                  setSettingsOpen={setSettingsCollapseOpen}
                  stateKey="keys"
                  title="API Keys"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container >
  );
};

export default SettingsDrawer;