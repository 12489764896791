import { Action } from 'redux';

export const IMPORT_TRANSLATIONS_FAIL = 'IMPORT_TRANSLATIONS_FAIL';

export interface ImportTranslationsFailAction extends Action {
  type: typeof IMPORT_TRANSLATIONS_FAIL;
}

export const importTranslationsFailAction = (
): ImportTranslationsFailAction => ({
  type: IMPORT_TRANSLATIONS_FAIL,
});