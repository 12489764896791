export default class Placeholder {

  // properties
  public toReplace: RegExp;
  public onIos: string;
  public onAndroid: string;

  constructor(toReplace: RegExp, onIos: string, onAndroid: string) {
    this.toReplace = toReplace;
    this.onIos = onIos;
    this.onAndroid = onAndroid;
  }
}