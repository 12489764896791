import { Action } from 'redux';

export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';

export interface GetTranslationsAction extends Action {
  type: typeof GET_TRANSLATIONS;
  projectId: string;
}

export const getTranslationsAction = (
  projectId: string,
): GetTranslationsAction => ({
  type: GET_TRANSLATIONS,
  projectId
});