import { Action } from 'redux';

export const DOWNLOAD_TRANSLATIONS_FAIL = 'DOWNLOAD_TRANSLATIONS_FAIL';

export interface DownloadTranslationsFailAction extends Action {
  type: typeof DOWNLOAD_TRANSLATIONS_FAIL;
}

export const downloadTranslationsFailAction = (
): DownloadTranslationsFailAction => ({
  type: DOWNLOAD_TRANSLATIONS_FAIL,
});