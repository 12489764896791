import { Action } from 'redux';

export const AUTO_TRANSLATE = 'AUTO_TRANSLATE';

export interface AutoTranslateAction extends Action {
  type: typeof AUTO_TRANSLATE;
  field: string;
  loadingIdentifier: string;
}

export const autoTranslateAction = (
  field: string,
  loadingIdentifier: string
): AutoTranslateAction => ({
  type: AUTO_TRANSLATE,
  field,
  loadingIdentifier
});