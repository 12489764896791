import { Action } from 'redux';

export const ADD_ROW = 'ADD_ROW';

export interface AddRowAction extends Action {
  type: typeof ADD_ROW;
}

export const addRowAction = (): AddRowAction => ({
  type: ADD_ROW,
});