import { Action } from 'redux';
import { Organization } from '../../../models/Organization';

export const SELECT_ORGANIZATION_SUCCESS = 'SELECT_ORGANIZATION_SUCCESS';

export interface SelectOrganizationSuccessAction extends Action {
  type: typeof SELECT_ORGANIZATION_SUCCESS;
  organization: Organization;
}

export const selectOrganizationSuccessAction = (
  organization: Organization,
): SelectOrganizationSuccessAction => ({
  type: SELECT_ORGANIZATION_SUCCESS,
  organization
});