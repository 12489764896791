import * as React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { RemoveRedEye } from '@material-ui/icons';

interface Props {
  label: string;
  name: string;
  value: string;
  disabled: boolean;
  setApiKey: any;
  disabledPointerEvents: boolean;
}

const ApiKeyTextfield = (props: Props) => {
  const [isMasked, setIsMasked] = React.useState(true);

  return (
    <TextField
      className="w-100 mt-1"
      type={isMasked ? 'password' : 'text'}
      label={props.label}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end" disablePointerEvents={props.disabledPointerEvents}>
              <RemoveRedEye
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsMasked(!isMasked)}
              />
            </InputAdornment>
            <i
              className="fa fa-times float-right ml-2"
              aria-hidden="true"
              style={{ fontSize: '12px', cursor: 'pointer', color: '#a3a3a3' }}
              onClick={() => props.setApiKey({ open: true, key: props.value })}
            />
          </>
        ),
      }}
    />
  );
};

export default ApiKeyTextfield;