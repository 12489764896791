import { Action } from 'redux';
export const SIGN_OUT_USER_SUCCESS = 'SIGN_OUT_USER_SUCCESS';

export interface SignOutUserSuccessAction extends Action {
  type: typeof SIGN_OUT_USER_SUCCESS;
}

export const signOutUserSuccessAction = (
): SignOutUserSuccessAction => ({
  type: SIGN_OUT_USER_SUCCESS,
});