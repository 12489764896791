import { Dispatch } from 'redux'
import { authRef, history, dbRef, functionsRef } from '../store'
import * as authActions from '../modules/auth/authActions'
import * as orgActions from '../modules/organization/orgActions'
import * as FSROUTES from '../constants/firestoreRoutes'
import { User } from '../models/User'
import { toastr } from 'react-redux-toastr'
import * as emailApi from './emailApi'
import { Project } from '../models/Project'
import { UserRole } from '../models/UserRole'
import { request } from './request'

export function getUser() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: authActions.GET_USER })
    return authRef.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        var user: User = {
          id: firebaseUser.uid,
          email: firebaseUser.email ? firebaseUser.email : 'Not added',
          name: ''
        }
        const userRef = dbRef.collection(FSROUTES.USERS).doc(user.id)
        userRef
          .get()
          .then(async (doc) => {
            const invitations = await dbRef
              .collection(FSROUTES.INVITATIONS)
              .where('user', '==', user.email)
              .get()
            for (let invitation of invitations.docs) {
              if (invitation.get('status') === 'pending') {
                if (invitation.get('organization_id')) {
                  userRef.update({
                    [`organization_roles.${invitation.get('organization_id')}`]:
                      invitation.get('role')
                  })
                } else if (invitation.get('project_id')) {
                  userRef.update({
                    [`project_roles.${invitation.get('project_id')}`]:
                      invitation.get('role')
                  })
                }
                dbRef
                  .collection(FSROUTES.INVITATIONS)
                  .doc(invitation.id)
                  .update({
                    status: 'accepted'
                  })
              }
            }
            user.organizations = doc.get('organization_roles')
            user.projects = doc.get('project_roles')
            user.name = doc.get('display_name')
              ? doc.get('display_name')
              : 'Not added'
            dispatch({ type: authActions.GET_USER_SUCCESS, user })
          })
          .catch(() => {
            dispatch({ type: authActions.GET_USER_FAIL })
          })
      } else {
        dispatch({ type: authActions.GET_USER_FAIL })
      }
    })
  }
}

function checkForInvitations(
  user: User,
  userRef: firebase.firestore.DocumentReference
) {
  dbRef
    .collection(FSROUTES.INVITATIONS)
    .where('user', '==', user.email)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.get('status') === 'pending') {
          if (doc.get('organization_id')) {
            userRef.update({
              [`organization_roles.${doc.get('organization_id')}`]:
                doc.get('role')
            })
          } else if (doc.get('project_id')) {
            userRef.update({
              [`project_roles.${doc.get('project_id')}`]: doc.get('role')
            })
          }
          dbRef.collection(FSROUTES.INVITATIONS).doc(doc.id).update({
            status: 'accepted'
          })
        }
      })
    })
}

export function addUserToOrg(
  email: string,
  role: string,
  organizationId: string,
  orgName: string
) {
  return (dispatch: Dispatch) => {
    dispatch({ type: authActions.ADD_USER_TO_ORG })
    // Batch write to Firestore
    let batch = dbRef.batch()
    // Firestore refs
    const invitationsRef = dbRef.collection(FSROUTES.INVITATIONS).doc()
    const orgRef = dbRef.collection(FSROUTES.ORGANIZATIONS).doc(organizationId)
    batch.set(invitationsRef, {
      user: email,
      organization_id: organizationId,
      role,
      status: 'pending'
    })
    batch.set(
      orgRef,
      {
        users: { [email]: role }
      },
      { merge: true }
    )
    batch
      .commit()
      .then(async () => {
        // If user was added to DB, send email invite
        const userExists = await dbRef
          .collection('users')
          .where('email', '==', email)
          .get()
        if (userExists.docs[0]) {
          const sendInvitation = functionsRef.httpsCallable('signinInvitation')
          await sendInvitation({
            organization: orgName,
            organization_id: organizationId,
            email
          })
        } else {
          const sendInvitation = functionsRef.httpsCallable('signupInvitation')
          await sendInvitation({
            organization: orgName,
            organization_id: organizationId,
            email
          })
        }

        dispatch({
          type: authActions.ADD_USER_TO_ORG_SUCCESS,
          user: { email, role } as UserRole
        })
        toastr.success('Success!', `Added user, ${email}, to organization.`)
      })
      .catch((error) => {
        dispatch({ type: authActions.ADD_USER_TO_ORG_FAIL })
        toastr.error('Failure!', `Could not add user to organization.`)
      })
  }
}

export function addUserToProject(
  email: string,
  role: string,
  project: Project
) {
  return (dispatch: Dispatch) => {
    dispatch({ type: authActions.ADD_USER_TO_PROJECT })
    // Batch write to Firestore
    let batch = dbRef.batch()
    // Firestore refs
    const invitationsRef = dbRef.collection(FSROUTES.INVITATIONS).doc()
    const projectRef = dbRef.collection(FSROUTES.PROJECTS).doc(project.id)
    batch.set(invitationsRef, {
      user: email,
      project_id: project.id,
      role,
      status: 'pending'
    })
    batch.set(
      projectRef,
      {
        users: { [email]: role }
      },
      { merge: true }
    )
    batch
      .commit()
      .then(() => {
        dispatch({ type: authActions.ADD_USER_TO_PROJECT_SUCCESS, email })
        toastr.success('Success!', `Added user, ${email}, to project.`)
      })
      .catch(() => {
        dispatch({ type: authActions.ADD_USER_TO_PROJECT_FAIL })
        toastr.error('Failure!', `Could not add user to project.`)
      })
  }
}

export function signUp(email: string, password: string, displayName: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: authActions.SIGN_UP_USER })
    authRef
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        const userRef = dbRef.collection(FSROUTES.USERS).doc(user.user!.uid)
        // Add new user to the collection of users with username (if provided)
        userRef.set({
          display_name: displayName,
          email: email,
          organization_roles: {},
          project_roles: {}
        })
        dbRef
          .collection(FSROUTES.INVITATIONS)
          .where('user', '==', email)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.get('status') === 'pending') {
                if (doc.get('organization_id')) {
                  userRef.update({
                    [`organization_roles.${doc.get('organization_id')}`]:
                      doc.get('role')
                  })
                } else if (doc.get('project_id')) {
                  userRef.update({
                    [`project_roles.${doc.get('project_id')}`]: doc.get('role')
                  })
                }
                dbRef.collection(FSROUTES.INVITATIONS).doc(doc.id).update({
                  status: 'accepted'
                })
              }
            })
          })
          .catch((error) => {
            toastr.error(
              'Failure',
              'An error has happened when signing up. Please try again'
            )
            dispatch({ type: authActions.SIGN_OUT_USER_FAIL })
          })
      })
      .then(() => {
        dispatch({ type: authActions.SIGN_UP_USER_SUCCESS })
        history.push('/')
      })
      .catch((error) => {
        toastr.error(
          'Failure',
          'An error has happened when signing up. Please try again'
        )
        dispatch({ type: authActions.SIGN_UP_USER_FAIL })
      })
  }
}

export function signOut() {
  return (dispatch: Dispatch) => {
    dispatch({ type: authActions.SIGN_OUT_USER })
    authRef
      .signOut()
      .then(() => {
        dispatch({ type: authActions.SIGN_OUT_USER_SUCCESS })
        history.push('/signin')
      })
      .catch((error) => {
        dispatch({ type: authActions.SIGN_UP_USER_FAIL })
      })
  }
}

export function createApiKey(orgId: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: orgActions.CREATE_API_KEY })
    if (authRef.currentUser) {
      try {
        const idToken = await authRef.currentUser.getIdToken(true)
        const result = await request('GET', `/organizations/${orgId}/apikey`, {
          auth_token: idToken
        })
        dispatch({
          type: orgActions.CREATE_API_KEY_SUCCESS,
          apiKey: result.data
        })
      } catch (error) {
        dispatch({ type: orgActions.CREATE_API_KEY_FAIL })
      }
    }
  }
}

export function deleteApiKey(orgId: string, apiKey: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: orgActions.DELETE_API_KEY })
    if (authRef.currentUser) {
      try {
        const idToken = await authRef.currentUser.getIdToken(true)
        await request('DELETE', `/organizations/${orgId}/apikey/${apiKey}`, {
          auth_token: idToken
        })
        dispatch({ type: orgActions.DELETE_API_KEY_SUCCESS, apiKey })
      } catch (error) {
        dispatch({ type: orgActions.DELETE_API_KEY_FAIL })
      }
    }
  }
}
