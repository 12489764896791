import { Action } from 'redux';
import { UserRole } from '../../../models/UserRole';
export const ADD_USER_TO_ORG_SUCCESS = 'ADD_USER_TO_ORG_SUCCESS';

export interface AddUserToOrgSuccessAction extends Action {
  type: typeof ADD_USER_TO_ORG_SUCCESS;
  user: UserRole;
}

export const addUserToOrgSuccessAction = (
  user: UserRole,
): AddUserToOrgSuccessAction => ({
  type: ADD_USER_TO_ORG_SUCCESS,
  user
});