import { Action } from 'redux';
export const GET_USER = 'GET_USER';

export interface GetUserAction extends Action {
  type: typeof GET_USER;
}

export const getUserAction = (
): GetUserAction => ({
  type: GET_USER,
});