import { Action } from 'redux';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';

export interface GetOrganizationsAction extends Action {
  type: typeof GET_ORGANIZATIONS;
  userId: string;
}

export const getOrganizationsAction = (
  userId: string,
): GetOrganizationsAction => ({
  type: GET_ORGANIZATIONS,
  userId
});