import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import * as projectSelectors from '../../../modules/project/projectSelector';
import * as orgSelectors from '../../../modules/organization/orgSelector';
import * as authSelectors from '../../../modules/auth/authSelector';
import * as authApi from '../../../services/authApi';
import ApiKeyTextfield from './ApiKeyTextfield';
import { Collapse } from 'react-bootstrap';
import { Project } from '../../../models/Project';
import { UpdateTranslations } from '../../../models/Roles';
import { GeneralRowButton } from '../../translation/translation-components/TopRow';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dispatch, bindActionCreators } from 'redux';
import { Organization } from '../../../models/Organization';
import Spinner from '../../ui-components/Spinner';

const Container = styled.div`
  font-size: 14px !important;
  cursor: pointer;
  color: #495057;
  ul {
    font-size: 10px;
  }
`;

interface Props {
  settingsOpen: {
    [str: string]: boolean
  };
  setSettingsOpen: any;
  stateKey: string;
  title?: string;
  settings?: string;
  projects: Project[] | undefined;
  users: { [email: string]: string }[] | undefined;
  userRole: string;
  apikeys: string[] | undefined;
  selectedOrganization: Organization | undefined;
  deleteApiKey: (orgId: string, apiKey: string) => void;
  createApiKey: (orgId: string) => void;
  isCreatingApiKey: boolean;
  isDeletingApiKey: boolean;
}

const SettingsCollapse = (props: Props) => {
  const [deleteModal, setDeleteModal] = React.useState({ key: '', open: false });

  const renderCollapseContent = () => {
    switch (props.stateKey) {
      case 'projects':
        if (!props.projects) return;
        return (
          <ul className="pl-0" style={{ listStyle: 'none' }}>
            <div className="row text-left">
              <div className="col">Name</div>
              <div className="col">Num. of users</div>
              <div className="col">Created</div>
            </div>
            <li>
              {props.projects.map((project, i) => (
                <div className="row text-left" key={i}>
                  <div className="col">
                    {project.name}
                  </div>
                  <div className="col">
                    {Object.keys(project.users).length}
                  </div>
                  <div className="col">
                    {moment(project.created.toDate()).format('MMM DD YYYY HH:mm:ss z')}
                  </div>
                </div>
              ))}
            </li>
          </ul>
        );
      case 'users':
        if (!props.users) return;
        return (
          <ul className="pl-0" style={{ listStyle: 'none' }}>
            <div className="row text-left">
              <div className="col">Email</div>
              <div className="col">Role</div>
            </div>
            <li>
              {Object.entries(props.users).map((user, i) => (
                <div className="row text-left" key={i}>
                  <div className="col">
                    {user[0]}
                  </div>
                  <div className="col">
                    {user[1]}
                  </div>
                </div>
              ))}
            </li>
          </ul>
        );
      case 'keys':
        return (
          <div>
            {props.apikeys && props.apikeys.map((ak, i) => (
              <ApiKeyTextfield
                key={i}
                label={i === 0 ? 'API: [INSERT DOCS]' : ''}
                name="keys"
                value={ak}
                setApiKey={setDeleteModal}
                disabled={true}
                disabledPointerEvents={!Object.keys(UpdateTranslations).includes(props.userRole)}
              />
            ))}
            {props.isCreatingApiKey && <Spinner containerHeight="30px" containerWidth="30px" width="20px" height="20px" borderSize="2px" />}
            {props.isDeletingApiKey && <Spinner containerHeight="30px" containerWidth="30px" width="20px" height="20px" borderSize="2px" />}
            {props.isCreatingApiKey || props.isDeletingApiKey ? <div /> : (
              <GeneralRowButton
                className="btn mt-3 ml-3 float-right"
                style={{ fontSize: '12px' }}
                gradient={true}
                onClick={() => props.createApiKey(props.selectedOrganization!.id)}
              // disabled={!Object.keys(CreateUpdateOrganizations).includes(this.props.userRole)}
              >
                Create key
              </GeneralRowButton>
            )}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <Container className="col-12 px-0">
      <div
        aria-expanded={props.setSettingsOpen[props.stateKey]}
        style={{ borderBottom: props.settingsOpen[props.stateKey] ? 'none' : '1px solid #495057' }}
        onClick={() => {
          props.setSettingsOpen({ [props.stateKey]: !props.settingsOpen[props.stateKey] });
        }}
      >
        <div className="mb-1 text-left">
          {props.title}
          <span className="float-right" style={{ fontSize: '12px' }}>
            {props.settingsOpen[props.stateKey] ? <>&#9650;</> : <>&#x25bc;</>}
          </span>
        </div>
      </div>
      <Collapse in={props.settingsOpen[props.stateKey]}>
        <div>
          {renderCollapseContent()}
        </div>
      </Collapse>
      <Modal isOpen={deleteModal.open} toggle={() => setDeleteModal({ open: false, key: '' })}>
        <ModalBody className="justify-content-center">
          Are you sure you want to delete key, {deleteModal.key}?
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="small-text"
            color="link"
            onClick={() => setDeleteModal({ open: false, key: '' })}
            style={{ color: 'black' }}
          >
            Cancel
          </Button>
          <Button
            className="small-text btn modal-button"
            onClick={() => {
              props.deleteApiKey(props.selectedOrganization!.id, deleteModal.key);
              setDeleteModal({ open: false, key: '' });
            }}
          >
            Delete
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  const email = authSelectors.userEmail(state);
  return {
    users: orgSelectors.orgUsers(state),
    projects: projectSelectors.projects(state),
    userRole: orgSelectors.orgRole(state, email),
    apikeys: orgSelectors.apikeys(state),
    isCreatingApiKey: orgSelectors.isCreatingApiKey(state),
    isDeletingApiKey: orgSelectors.isDeletingApiKey(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    deleteApiKey: (orgId: string, apiKey: string) => authApi.deleteApiKey(orgId, apiKey),
    createApiKey: (orgId: string) => authApi.createApiKey(orgId)
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCollapse);