import * as React from 'react';
import styled from 'styled-components';
import windowResize from '../../assets/window-resize.svg';
import Logo from '../../assets/logo/icecream@3x.png';

const Image = styled.img`
  width: 100px;
  height: 100px
`;

const LocaliceLogo = styled.img`
  width: 60px;
  height: 120px;
`;

const ToSmallWindow = () => (
  <div className="container-fluid px-0">
    <div className="row text-center vertical-align-center mt-5">
      <div className="col-12 mx-auto mt-5">
        <LocaliceLogo src={Logo} alt="logo"/>
      </div>
      <div className="col-12 mx-auto mt-5">
        <h1>Sorry! We currently only support desktop screen sizes.</h1>
      </div>
      <div className="col-12 mx-auto">
        <Image src={windowResize} alt="window-resize-to-use" />
      </div>
    </div>
  </div>
);

export default ToSmallWindow;