import { Action } from 'redux';

export const ADD_ROW_FAIL = 'ADD_ROW';

export interface AddRowFailAction extends Action {
  type: typeof ADD_ROW_FAIL;
}

export const addRowFailAction = (): AddRowFailAction => ({
  type: ADD_ROW_FAIL,
});