import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer, AuthenticationState } from './modules/auth/authReducer';
import ReactGA from 'react-ga';
import { organizationsReducer, OrganizationState } from './modules/organization/orgReducer';
import createHistory from 'history/createBrowserHistory';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { stagingFirebaseConfig } from './firebaseConfig';
import reduxThunk from 'redux-thunk';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import { ProjectState, projectsReducer } from './modules/project/projectReducer/projectReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import * as authActions from './modules/auth/authActions';
import * as orgActions from './modules/organization/orgActions';
import { customGaMiddleWare } from './constants/eventsMap';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';

firebase.initializeApp(stagingFirebaseConfig);

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

export const dbRef = firebase.firestore();
export const authRef = firebase.auth();
export const functionsRef = firebase.functions();

export const history = createHistory();

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_ID!);
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export interface RootState {
  auth: AuthenticationState;
  organizations: OrganizationState;
  projects: ProjectState;
  firebase: any;
  firestore: any;
  router: RouterState;
  toastr: any;
}

const rootReducer = combineReducers({
  auth: authReducer,
  organizations: organizationsReducer,
  projects: projectsReducer,
  router: connectRouter(history),
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  toastr: toastrReducer
});

type AppActions =
  authActions.SignOutUserAction |
  authActions.SignOutUserFailAction |
  authActions.SignOutUserSuccessAction |
  orgActions.SelectOrganizationSuccessAction |
  orgActions.SelectOrganizationAction |
  orgActions.SelectOrganizationFailAction;

export const appReducer = (state: RootState | undefined, action: AppActions) => {
  if (action.type === authActions.SIGN_OUT_USER_SUCCESS) {
    state = undefined;
  }
  return rootReducer(state, action);
};
const createStoreWithFirebase = composeWithDevTools(
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase),
  applyMiddleware(reduxThunk, customGaMiddleWare, routerMiddleware(history))
)(createStore);

const store = createStoreWithFirebase(appReducer);
export default store;
