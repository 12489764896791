import { Action } from 'redux';
import { Translation } from '../../../../models/Translation';

export const REMOVE_ROW = 'REMOVE_ROW';

export interface RemoveRowAction extends Action {
  type: typeof REMOVE_ROW;
  translation: Translation;
}

export const removeRowAction = (
  translation: Translation
): RemoveRowAction => ({
  type: REMOVE_ROW,
  translation
});