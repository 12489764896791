import { Action } from 'redux';
export const ADD_USER_TO_ORG = 'ADD_USER_TO_ORG';

export interface AddUserToOrgAction extends Action {
  type: typeof ADD_USER_TO_ORG;
}

export const addUserToOrgAction = (
): AddUserToOrgAction => ({
  type: ADD_USER_TO_ORG,
});