import { RootState } from '../../store';

export const user = (state: RootState) => {
  if (!state.auth) {
    return undefined;
  }

  if (!state.auth.user) {
    return undefined;
  }
  return state.auth.user;
};

export const firebaseUserId = (state: RootState) => {
  if (!state.firebase.auth.uid) {
    return undefined;
  }
  return state.firebase.auth.uid;
};

export const userEmail = (state: RootState) => {
  if (!state.firebase.auth) {
    return undefined;
  }

  return state.firebase.auth.email;
};

export const isGettingUser = (state: RootState) => {
  if (!state.auth) {
    return false;
  }
  return !!state.auth.isGettingUser;
};

export const isSigningOut = (state: RootState) => {
  if (!state.auth) {
    return false;
  }
  return !!state.auth.isSigningOut;
};

export const router = (state: RootState) => {
  return state.router;
};