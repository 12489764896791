import { ProjectState } from './projectReducer';
import * as projectActions from '../projectActions';
import * as translationActions from '../../translation/translationsActions';
import * as authActions from '../../auth/authActions';
import update from 'immutability-helper';
import SortableTranslation from '../../../models/SortableTranslation';

export function updateCommentInput(state: ProjectState, action: projectActions.UpdateInputAction) {
  let indexToUpdate = state.selectedProject!.translations.findIndex(x => x.id === action.translation.id);
  return update(state, {
    selectedProject: {
      translations: {
        [indexToUpdate]: {
          [action.field]: { $set: action.value }
        }
      }
    }
  });
}

export function autoTranslate(state: ProjectState, action: translationActions.AutoTranslateSuccessAction) {
  let indexToUpdate = state.selectedProject!.translations.findIndex(x => x.id === action.translation.id);
  let translationIndexToUpdate =
    state.selectedProject!.translations[indexToUpdate].translations.findIndex(x => x.key === action.field);
  if (translationIndexToUpdate === -1) return state;
  return update(state, {
    isAutoTranslating: { $set: { [action.loadingIdentifier]: false } },
    selectedProject: {
      translations: {
        [indexToUpdate]: {
          translations: {
            [translationIndexToUpdate]: {
              value: { $set: action.value }
            }
          }
        }
      }
    }
  });
}

export function updateInput(state: ProjectState, action: projectActions.UpdateInputAction) {
  if (action.isComment) return updateCommentInput(state, action);
  let indexToUpdate = state.selectedProject!.translations.findIndex(x => x.id === action.translation.id);
  let translationIndexToUpdate =
    state.selectedProject!.translations[indexToUpdate].translations.findIndex(x => x.key === action.field);
  if (translationIndexToUpdate === -1) return state;
  return update(state, {
    selectedProject: {
      translations: {
        [indexToUpdate]: {
          translations: {
            [translationIndexToUpdate]: {
              value: { $set: action.value }
            }
          }
        }
      }
    }
  });
}

export function updatePuralInput(state: ProjectState, action: projectActions.UpdateInputAction) {
  if (action.isComment) return updateCommentInput(state, action);
  let indexToUpdate = state.selectedProject!.translations.findIndex(x => x.id === action.translation.id);
  let translationIndexToUpdate =
    state.selectedProject!.translations[indexToUpdate].pluralTranslations!.findIndex(x => x.key === action.field);
  if (translationIndexToUpdate === -1) return state;
  return update(state, {
    selectedProject: {
      translations: {
        [indexToUpdate]: {
          pluralTranslations: {
            [translationIndexToUpdate]: {
              value: { $set: action.value }
            }
          }
        }
      }
    }
  });
}

export function removeRow(state: ProjectState, action: projectActions.RemoveRowAction) {
  const items = state.selectedProject!.translations.filter(trans =>
    trans.id !== action.translation.id);
  const project = state.selectedProject;
  project!.translations = items;
  return {
    ...state,
    selectedProject: project
  };
}

export function addRowSuccess(
  state: ProjectState,
  action: projectActions.AddRowSuccessAction
) {
  const items = [action.translation, ...state.selectedProject!.translations];
  const project = state.selectedProject;
  project!.translations = items;
  return {
    ...state,
    selectedProject: project
  };
}

export function addRowPluralSuccess(
  state: ProjectState,
  action: projectActions.AddPluralRowSuccessAction
) {
  const index = state.selectedProject!.translations.findIndex(trans => trans.id === action.translation.id);
  const items = state.selectedProject!.translations;
  items[index] = action.translation;
  const project = state.selectedProject;
  project!.translations = items;
  return {
    ...state,
    selectedProject: project,
    isAddingPluralRow: false,
  };
}

export function removeTagSuccess(state: ProjectState, action: projectActions.RemoveTagAction) {
  let indexToUpdate3 = state.selectedProject!.translations.findIndex(x => x.id === action.translationId);
  return update(state, {
    selectedProject: {
      translations: {
        [indexToUpdate3]: {
          tag: { $set: '' }
        }
      }
    }
  });
}

export function addUserToProject(state: ProjectState, action: authActions.AddUserToProjectSuccessAction) {
  return update(state, {
    // selectedProject: {
    //   users: { $push: [{ [action.user.email]: action.user.role }] }
    // }
  });
}

export function addLanguageToProject(state: ProjectState, action: projectActions.AddLanguageToProjectSuccessAction) {
  const updatedTranslations = state.selectedProject!.translations && state.selectedProject!.translations.map(trans => {
    let hasPlural = trans.pluralTranslations.length > 0 ? true : false;
    if (hasPlural) {
      trans.pluralTranslations.push(new SortableTranslation(`plural${action.language}`, ''));
      trans.translations.push(new SortableTranslation(action.language, ''));
    } else {
      trans.translations.push(new SortableTranslation(action.language, ''));
    }
    return trans;
  });
  return update(state, {
    selectedProject: {
      languages: { $push: [action.language] },
      translations: { $set: updatedTranslations }
    },
    isAddingLanguage: { $set: false }
  });
}