import * as React from 'react';
import { connect } from 'react-redux';
import Logo from '../../assets/logo/icecream@2x.png';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { bindActionCreators, Dispatch } from 'redux';
import Checkbox from '@material-ui/core/Checkbox';

import './signup-styles.css';

// Redux methods
import * as authApi from '../../services/authApi';
import { LocaliceLogo } from '../common/navigation/NavbarStyles';

interface Props {
  signUp: (email: string, password: string, displayName: string) => void;
  userId: string | undefined;
}

interface State {
  email: string;
  password: string;
  displayName: string;
}

const SignUp = (props: Props) => {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    displayName: ''
  });

  const updateInput = (event: React.FormEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.currentTarget.name]: event.currentTarget.value });
  };

  if (props.userId) {
    return <Redirect to="/" />;
  }

  return (
    <div id="signup-page">
      <div style={{ padding: '0.5rem 1rem' }}>
        <div className="container-fluid px-0">
          <div className="navbar-brand localize-logo ml-5">
            <Link to={'/'} className="align-self-center">
              <LocaliceLogo src={Logo} alt="logo" className="mr-3" style={{ width: '39px', height: '72px' }} />
              Localice.io
          </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="row text-center">
          <div className="col-lg-4 mx-auto">
            <h1 className="mt-5 mb-5 display-4">Start using Localice.io</h1>
            <div className="form-group mt-3">
              <input
                autoComplete="off"
                type="email"
                name="email"
                placeholder="Email"
                className="form-control mb-3 input-field"
                onChange={updateInput}
              />
              <input
                autoComplete="off"
                type="text"
                name="displayName"
                placeholder="Username"
                className="form-control mb-3 input-field"
                onChange={updateInput}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0 }}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="form-control mb-3 input-field"
                onChange={updateInput}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0 }}
              />
            </div>
            <div>
              <Checkbox />
              <label className="form-check-label">
                I agree to Localice.io's <Link style={{ color: 'blue' }} to="/terms">Terms of service</Link>
              </label>
            </div>
            <button
              type="submit"
              name="signup-btn"
              className="general-button mt-4"
              onClick={(e) => props.signUp(values.email, values.password, values.displayName)}
              test-signup-btn="true"
            >
              Sign up for free!
            </button>
            <p className="mt-3">Already a user? <Link to={ROUTES.SIGN_IN}>Sign in</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    signUp: (email: string, password: string, displayName: string) =>
      authApi.signUp(email, password, displayName)
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(SignUp);